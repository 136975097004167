import { COMPARISON_PATH } from '@Components/ComparisonBar/ComparisonBar';
import ErrorBoundary from '@Components/ErrorBoundary/ErrorBoundary';
import TelemarketerTopBar from '@Components/TelemarketerTopBar/TelemarketerTopBar';
import { ANALYTICS, GTM_ID, MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import Account from '@Layouts/Account/Account';
import CartLayout from '@Layouts/Cart/Cart';
import CheckoutLayout from '@Layouts/Checkout/Checkout';
import Comparison from '@Layouts/Comparison/Comparison';
import Dashboard from '@Layouts/Dashboard/Dashboard';
import Guest from '@Layouts/Guest/Guest';
import NoBreadcrumbsLayout from '@Layouts/NoBreadcrumbsLayout/NoBreadcrumbsLayout';
import Payment from '@Layouts/Payment/Payment';
import { setLanguage } from '@Redux/modules/settings/language/actions';
import { dispatch } from '@Redux/store';
import { getLang } from '@Utils/language/language';
import PaymentFrame from '@Views/PaymentFrame/PaymentFrame';
import React, { FunctionComponent, useEffect } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProfileService } from '@Services/profile/profileService';
import { remoteConfig } from '@Services/remoteConfig/remoteConfig';
import { logOut, setLoginType, signIn, setUserId, setIsMasterProfile } from '@Redux/modules/api/authentication/actions';
import { deleteWCTokens, updateHeader } from '@Redux/modules/api/headers/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ConfigService } from '@Services/config/configService';
import { addBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import useTelesales from '@Utils/hooks/useTeleasales';
import CleverTap from 'clevertap-web-sdk/clevertap';
import { getHeaders } from '@Redux/modules/api/headers/selectors';
import { setConfig } from '@Redux/modules/settings/config/actions';
import { FORMS_CONFIG_CATEGORY_ID } from '@Config/app.config';
import { eraseCookie, NO_HEADER_FOOTER_COOKIE, setCookie, hasCookie } from '@Utils/cookie/cookie';
import { useCart } from '@Utils/context/CartContext';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { eshopIsLoadedInIframe } from '@Utils/iFrame/iFrame';
import { MakasibService } from '@Services/makasib/makasib';
import BuyNowPayLaterContextProvider from '@Utils/context/BuyNowPayLaterContext';
import { setRemoteConfig } from '@Redux/modules/settings/remoteConfig/actions';

export interface IWindow extends Window {
	dataLayer: Record<string, any>[];
	clevertap: CleverTap;
}

interface ITokens {
	WCTrustedToken: string;
	WCToken: string;
	personalizationID: string;
}

export const CONTACT_PATH = '/:lang/contact';
export const PLANS_PATH = '/:lang/plans';
export const PREPAID_MIGRATION_PATH = '/:lang/prepaidMigration';
export const GET_TELESALES_CART_BI = 'GET_TELESALES_CART_BI';
export const NO_HEADER_FOOTER_QUERY_PARAM = 'noHeaderFooter';

// auth routes should be added here
const App: FunctionComponent = () => {
	const history = useHistory();
	const wasTelesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const isLtpaToken2 = useSelector((state: types.redux.IState) => state.api.authentication.ltpaToken2);
	const { isTelesales, token, trustedToken, personalizationId } = useTelesales();
	const location = useLocation();
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const { cart } = useCart();

	const updateHeaders = (tokens: ITokens) => {
		const { WCTrustedToken, WCToken, personalizationID } = tokens;
		dispatch(
			updateHeader({
				name: 'WCTrustedToken',
				value: WCTrustedToken,
			})
		);
		dispatch(
			updateHeader({
				name: 'WCToken',
				value: WCToken,
			})
		);
		dispatch(
			updateHeader({
				name: 'WCPersonalization',
				value: personalizationID,
			})
		);
	};
	useEffect(() => {
		remoteConfig.getRemoteConfig().subscribe((response) => {
			dispatch(setRemoteConfig(response as any));
		});
	}, []);

	useEffect(() => {
		if (((window as unknown) as IWindow).dataLayer !== undefined) {
			((window as unknown) as IWindow).dataLayer.push({
				event: 'pageview',
				page: {
					url: location.pathname + location.search,
				},
			});
		}
	}, [location.pathname]);

	ReactGA.initialize(ANALYTICS);
	history.listen((location) => {
		ReactGA.set({ page: location.pathname + location.search });
		ReactGA.pageview(location.pathname + location.search);
		TagManager.dataLayer({
			dataLayer: { event: 'pageviewStore', page: location.pathname + location.search, userId: getUserId() },
		});
	});

	if (location.pathname.toLowerCase().startsWith('/en')) {
		if (getLang() !== 'en') dispatch(setLanguage('EN'));
	} else if (location.pathname.toLowerCase().startsWith('/ar')) {
		if (getLang() !== 'ar') dispatch(setLanguage('AR'));
	}

	const updateTelesales = (): void => {
		dispatch(addBusyIndicator(GET_TELESALES_CART_BI));
		updateHeaders({
			WCTrustedToken: trustedToken ?? '',
			WCToken: token ?? '',
			personalizationID: personalizationId ?? '',
		});
		dispatch(
			signIn({
				signedIn: false,
				loginType: 'guest',
				telesales: true,
			})
		);
		dispatch(updateCart(true));
	};

	useEffect(() => {
		const { WCTrustedToken } = getHeaders();
		if (isSignedIn && !telemarketer && (!WCTrustedToken || eshopIsLoadedInIframe())) {
			ProfileService.getOmantelIdentity().subscribe(
				(r) => {
					dispatch(setConfig({ accountCategory: r.supportedAccountCategories }));
					dispatch(invalidateAllCache());
					updateHeaders({
						WCTrustedToken: r.WCTrustedToken,
						WCToken: r.WCToken,
						personalizationID: r.personalizationID,
					});
					dispatch(
						signIn({
							telemarketer: r.telesales,
						})
					);
					dispatch(updateCart(true));
				},
				() => {}
			);
		}
	}, [isSignedIn]);

	const logOutUser = async (): Promise<string> => {
		return new Promise((resolve) => {
			AuthenticationService.logout().subscribe(
				() => {
					updateTelesales();
					resolve('');
				},
				() => {
					dispatch(addBusyIndicator(GET_TELESALES_CART_BI));
					resolve('');
				},
				() => {
					updateTelesales();
					resolve('');
				}
			);
		});
	};

	const getNoBreadcrumbsPaths = (): string[] => {
		return [CONTACT_PATH, PLANS_PATH, PREPAID_MIGRATION_PATH];
	};

	useEffect(() => {
		if (isTelesales || wasTelesales) {
			dispatch(addBusyIndicator(GET_TELESALES_CART_BI));
			if (isTelesales && (isSignedIn || isLtpaToken2)) {
				const returnStatement = logOutUser();
			} else {
				if (isTelesales) {
					dispatch(invalidateAllCache());
					dispatch(logOut());
					dispatch(clearCheckoutDetails());
					dispatch(
						updateHeader({
							name: 'WCTrustedToken',
							value: trustedToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCToken',
							value: token,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCPersonalization',
							value: personalizationId,
						})
					);
					dispatch(
						signIn({
							signedIn: false,
							loginType: 'guest',
							telesales: true,
						})
					);
					dispatch(updateCart(true));
				} else if (wasTelesales) {
					dispatch(setLoginType(undefined));
					dispatch(deleteWCTokens());
					dispatch(invalidateAllCache());
					dispatch(logOut());
					dispatch(clearCheckoutDetails());
					OrderFlowService.clearAllCartItems().subscribe(
						() => {},
						() => {}
					);
					dispatch(updateCart(true));
				}
			}
		} else {
			ProfileService.getUserProfile(true).subscribe(
				() => {},
				() => {}
			);
		}
		const tagManagerArgs = {
			gtmId: GTM_ID || '',
			dataLayer: {
				Logged_in: isSignedIn ? 'Yes' : 'No',
				userId: getUserId(),
			},
		};
		TagManager.initialize(tagManagerArgs);
		TagManager.dataLayer({
			dataLayer: { event: 'pageviewStore', page: 'login', userId: getUserId() },
		});

		const query = new URLSearchParams(window.location.search);
		if (query.get(NO_HEADER_FOOTER_QUERY_PARAM)) {
			if (!hasCookie(NO_HEADER_FOOTER_COOKIE)) {
				setCookie(NO_HEADER_FOOTER_COOKIE, String(true));
			}
		} else {
			eraseCookie(NO_HEADER_FOOTER_COOKIE);
		}
	}, []);

	useEffect(() => {
		if (
			(cart.items.some((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID) ||
				cart.items.some((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)) &&
			!window.location.pathname.toLocaleLowerCase().includes('cart') &&
			!window.location.pathname.toLocaleLowerCase().includes('checkout') &&
			!window.location.pathname.toLocaleLowerCase().includes('payment')
		) {
			const makasibItem = cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
			if (!!makasibItem) {
				OrderFlowService.removeSingleCartItem(makasibItem?.orderItemId).subscribe(
					() => {
						TagManager.dataLayer({
							dataLayer: {
								Product_Name: makasibItem?.name,
								event: 'Remove item from cart',
								Logged_in: isSignedIn ? 'Yes' : 'No',
								userId: getUserId(),
							},
						});
					},
					() => {}
				);
			}
			const makasibVoucher = cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
			if (!!makasibVoucher && makasibVoucher.length > 0) {
				makasibVoucher.forEach((makasibVoucher) =>
					OrderFlowService.removeSingleCartItem(makasibVoucher?.orderItemId).subscribe(
						() => {
							MakasibService.manageMakasibVoucherReservation(
								String(makasibVoucher?.voucherId),
								'U'
							).subscribe(
								() => {},
								() => {}
							);
							TagManager.dataLayer({
								dataLayer: {
									Product_Name: makasibVoucher?.name,
									event: 'Remove item from cart',
									Logged_in: isSignedIn ? 'Yes' : 'No',
									userId: getUserId(),
								},
							});
						},
						() => {}
					)
				);
			}
		}
	}, [cart.items]);

	useEffect(() => {
		if (isSignedIn && (!isTelesales || !wasTelesales)) {
			ProfileService.getUserProfile(true).subscribe(
				(response) => {
					dispatch(setUserId(response.userId));
					dispatch(setIsMasterProfile(response.isMaster));
					TagManager.dataLayer({
						dataLayer: { event: 'login', userId: response.userId },
					});
				},
				() => {}
			);
		} else {
			dispatch(setConfig({ forms: [] }));
		}
		if (isSignedIn) {
			ConfigService.getRemoteConfig(FORMS_CONFIG_CATEGORY_ID).subscribe(
				(response) => {},
				() => {}
			);
			TagManager.dataLayer({
				dataLayer: { event: 'pageviewStore', page: 'login', userId: getUserId() },
			});
		} else {
			dispatch(setConfig({ forms: [] }));
		}
	}, [isSignedIn]);

	return (
		<ErrorBoundary>
			<BuyNowPayLaterContextProvider>
				<TelemarketerTopBar />
				<Switch>
					<Route path={COMPARISON_PATH}>
						<Comparison />
					</Route>
					<Route
						path={[
							'/checkout/:step',
							'/:lang/checkout/:step',
							'/:lang/telesales/quest/',
							'/telesales/quest/',
						]}
					>
						<CheckoutLayout />
					</Route>
					<Route path="/create-payment">
						<PaymentFrame />
					</Route>
					<Route path={['/payment/:status', '/:lang/payment/:status', '/:lang/payment/:status/hbbwfbb']}>
						<Payment />
					</Route>
					<Route path={['/account/:info', '/:lang/account/:info']}>
						<Account />
					</Route>
					<Route path={['/guest/:info', '/:lang/guest/:info']}>
						<Guest />
					</Route>
					<Route exact path={['/:lang/cart', '/cart']}>
						<CartLayout />
					</Route>

					<Route path={getNoBreadcrumbsPaths()}>
						<NoBreadcrumbsLayout />
					</Route>
					<Route path="/">
						<Dashboard />
					</Route>
					<Route>
						<Redirect to={`/${getLang()}/store`} />
					</Route>
				</Switch>
			</BuyNowPayLaterContextProvider>
		</ErrorBoundary>
	);
};

export default App;
