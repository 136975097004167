import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch, useParams, useLocation, useHistory } from 'react-router-dom';
import { Col, Row, Hidden, Container } from 'react-grid-system';
import Carousel from '@Components/Carousel/Carousel';
import Configurator from './subcomponents/Configurator/Configurator';
import useTranslate from '@Utils/hooks/useTranslate';
import WhatsInTheBox from '@Components/WhatsInTheBox/WhatsInTheBox';
import RelatedProducts from '@Components/RelatedProducts/RelatedProducts';
import {
	ProductAdditionalInfoPanelWrapper,
	ProductAdditionalInfoPanelContainer,
	StickyWrapper,
	ProductBoxWrapper,
	AdditionalInfoWrapper,
	ContentWrapper,
	TopContentWrapper,
	WhiteSpaceWrapper,
} from './ProductPage.styled';
import ProductAdditionalInfoPanel from '@Components/ProductAdditionalInfoPanel/ProductAdditionalInfoPanel';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { InventoryService } from '@Services/inventory/inventoryService';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { IAvailableValueItem } from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import ExpandableBox from '@Components/ExpandableBox/ExpandableBox';
import { IProductById, IProductByIdItem } from '@ApiModels/productById';
import Card from '@Components/Card/Card';
import TitleSeparator from '@Components/TitleSeparator/TitleSeparator';
import { updateBreadcrumb } from '@Redux/modules/breadcrumbs/actions';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import useBreadcrumbs from '@Utils/hooks/useBreadcrumbs';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import { IInventory, IInventoryByProductId } from '@ApiModels/inventoryByProductId';
import {
	GET_ACCOUNT_FOR_LOGGED_USER,
	GET_CUSTOMER_PRODUCTS,
	GET_HIGHER_PLAN,
	VALIDATE_CHANGED_INSTALLMENT_SKUS,
	CHECK_IF_CAN_GET_NEW_PLAN,
	GET_PLANS,
	GET_RECOMMENDED_PLAN,
	PlanType,
	INFINITE_LOADING,
	NOT_CHOSEN_NUMBER,
} from '@Components/Card/cards/Plan/Plan';
import {
	DEF_ITEM_TYPE,
	ITEM_ACCESSORY_INSTALMENTS,
	ITEM_ACCESSORY_INSTALMENTS_HBB,
	ITEM_ACCESSORY_INSTALMENTS_WFBB,
	ITEM_HBB_INSTALMENTS,
	ITEM_TYPE,
	TABLETS_LAPTOPS_INSTALMENTS,
	TABLETS_LAPTOPS_INSTALMENTS_HBB,
	TABLETS_LAPTOPS_INSTALMENTS_WFBB,
} from '@ApiModels/utils/attributeGetters';
import { getLang } from '@Utils/language/language';
import { IItem } from '@ApiModels/productsByCategoryId';
import KeyFeatures from '@Components/KeyFeatures/KeyFeatures';
import { AccountsService } from '@Services/accounts/accountsService';
import { IAccounts } from '@ApiModels/accounts';
import { setOutletAvailability } from '@Redux/modules/configurator/actions';
import {
	DISABLE_PICKUP_FROM_OUTLET,
	DISABLE_DEVICE_INSTALLMENT_CONFIG,
	ENABLE_ESHOP_LITE,
	isDisabledBnplCr7985,
	isCalCulateLoader,
	isBNPLVariantFix,
	isSensiboProDevice,
} from '@Config/features.config';
import ExploreMore from '@Components/ExploreMore/ExploreMore';
import { EspotService } from '@Services/espot/espot';
import AppleRelatedProducts from '@Components/AppleRelatedProducts/AppleRelatedProducts';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import {
	checkIItemAvailability,
	getInstallmentValue,
	getOnlyAvailableItems,
} from '@Utils/converters/iItemInventoryAvailability';
import { GET_PRODUCTS_INSTALLMENT_BI } from '@Views/Store/Store';
import TagManager from 'react-gtm-module';
import AppleServices from '@Components/AppleServices/AppleServices';
import { Subscription } from 'rxjs';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { IMarketingBanners, ISingleMarketingBanner } from '@ApiModels/marketingBanners';
import MarketingBanner, { MarketingBannerType } from '@Components/MarketingBanner/MarketingBanner';
import {
	COMMITMENT_BAQATI_CONFIG,
	emfConfigId,
	B2B_CATEGORIES,
	ENABLE_CREDIT_RATING_LOGIC_GN_NGN,
} from '@Config/app.config';
import { setPersonalData } from '@Redux/modules/api/personalData/actions';
import { PackagesService } from '@Services/packages/packages';
import { IPackage } from '@ApiModels/packages';
import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';
import { DISABLE_EBU_SINGLE_PURCHASE, DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI } from '@Config/features.config';
import { setB2bUser } from '@Redux/modules/api/authentication/actions';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { useCart } from '@Utils/context/CartContext';
import { CreditRating } from '@Config/app.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
interface IProductPage {
	productIdFromProps?: string;
}

type ProductPageUrlParams = {
	productId?: string;
};
const OUTLET_AVAILABLE_INDICATOR = 'OUTLET_AVAILABLE_INDICATOR';
const SHOW_SUMMARY_INDICATOR = 'SHOW_SUMMARY_INDICATOR';
export const GET_PRODUCT_DETAILS_BI = 'GET_PRODUCT_DETAILS_BI';
export const GET_COMPONENTS_FROM_INVENTORY_BI = 'GET_COMPONENTS_FROM_INVENTORY_BI';
export const GET_RELATED_PRODUCTS_BI = 'GET_RELATED_PRODUCTS_BI';
export const PRODUCT_PAGE_QUERY_COLOR_PARAM = 'colorId';
export const PRODUCT_PAGE_ITEM_FLAG = 'itemDigitalType';
export const GET_PRICE_BY_CAT_ENTRY_BI = 'GET_PRICE_BY_CAT_ENTRY_BI';
export const GET_SEE_ALSO_BI = 'GET_SEE_ALSO_BI';
export const GET_ACCOUNT_INFO_BI = 'GET_ACCOUNT_INFO_BI';
export const GET_BNPL_PRICES = 'GET_BNPL_PRICES';
export const GET_CALCULATE_INFO = 'GET_CALCULATE_INFO';
export const APPLE_HERO_ITEMS = ['IPHONE', 'APPLE_WATCH', 'IPAD'];
const ELIGIBLE_DEVICE_GROUP = 'ELIGIBLE_DEVICE_GROUP';
const JAWWY_TV = 'JAWWY TV';
const MOBILE_ACCOUNT_TYPE = 'MOBILE';

const ProductPage: FunctionComponent<IProductPage> = ({ productIdFromProps }) => {
	const { cart } = useCart();
	const [productById, setProductById] = useState<IProductById>();
	const [productName, setProductName] = useState<string>();
	const [availableColors, setAvailableColors] = useState<IAvailableColorWithId[]>([]);
	const [availableValues, setAvailableValues] = useState<IAvailableValueItem[]>([]);
	const [isItemAvailable, setIsItemAvailable] = useState(false);
	const [selectedColor, setSelectedColor] = useState<string | null>(null);
	const [selectedValueId, setSelectedValue] = useState<string | null>(null);
	const [selectedImage, setSelectedImage] = useState<string | null>('');
	const [imageLoader, setImageLoader] = useState<boolean>(false);
	const [inventory, setInventory] = useState<IInventoryByProductId['inventory']>();
	const [productByIdIsLoading, setProductByIdIsLoading] = useState<boolean>(false);
	const [productIsSet, setProductIsSet] = useState<boolean>(false);
	const [pricesWithVat, setPricesWithVat] = useState<IPricesWithVat>({
		offerPrice: '0',
		listPrice: '0',
		taxablePrice: '0',
		vatPercent: '0',
		vatValue: '0',
	});
	const [tradeInBanners, setTradeInBanners] = useState<IMarketingBanners>({ banners: [] });
	const selectedSKU = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const [planType, setPlanType] = useState<PlanType>(PlanType.DEVICE_ONLY);
	const [responseSeoId, setResponseSeoId] = useState<string>('');
	const [accounts, setAccounts] = useState<IAccounts>();
	const { isBuyNowPayLaterFlow } = useBuyNowPayLater();
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const [installmentPeriod, setInstallmentPeriod] = useState<'12' | '24' | undefined>(
		isBuyNowPayLaterFlow || !isLogged ? undefined : selectedSKU?.installmentPeriod === '12' ? '12' : '24'
	);
	const [isUpfront, setIsUpfront] = useState<true | false | undefined>();
	const [isGreenRatingUser, setIsGreenRatingUser] = useState<boolean>(false);
	const [productDetails, setProductDetails] = useState<IItem | undefined>(productById?.item);
	const { getAppleItemCategory } = useAppleBrand();
	const [similarItems, setSimilarItems] = useState<IItem[]>([]);
	const [showAppleRelatedContent, setShowAppleRelatedContent] = useState<boolean>(false);
	const [isAppleHeroProduct, setIsAppleHeroProduct] = useState<boolean>(false);
	const { translate } = useTranslate();
	const { url } = useRouteMatch();
	const dispatch = useDispatch();
	const { getBreadcrumbsFromCategory, getProductCategory } = useBreadcrumbs();
	const { screen, screenClass } = useScreen();
	const isXsSmMd = screen(['xs', 'sm', 'md']);
	const isSmMd = screen(['sm', 'md']);
	const isXsSm = screen(['xs', 'sm']);
	const inStock = useSelector((state: types.redux.IState) => state.selectedItems.inStock);
	const selectedPlanToSubscribe = useSelector((state: types.redux.IState) => state.selectedItems?.plan);
	const configurator = useSelector((state: types.redux.IState) => state.configurator);
	const isHBB = useSelector((state: types.redux.IState) => state.selectedItems.isHBB);
	const isWFBB5G = useSelector((state: types.redux.IState) => state.selectedItems.isWFBB5G);
	const isWFBB4G = useSelector((state: types.redux.IState) => state.selectedItems.isWFBB4G);
	const currentPlan = useSelector((state: types.redux.IState) => state.selectedItems.currentPlan);
	const selectedAccount = useSelector((state: types.redux.IState) => state.selectedItems.selectedAccount);
	const userIsCorporate = useSelector((state: types.redux.IState) => state.selectedItems.isCorporateUser);
	const calculatedProduct = useSelector((state: types.redux.IState) => state.selectedItems?.calculatedProduct);
	const showVoucherWarning = !!useSelector((state: types.redux.IState) => state.selectedItems?.voucherWarning);
	const isBaqatiPlan = useSelector((state: types.redux.IState) => state.selectedItems.isBaqatiPlan);
	const [accessoryWithPlan, setAccessoryWithPlan] = useState<boolean>(false);
	const [mobileDeviceWithPlan, setMobileDeviceWithPlan] = useState<boolean>(false);
	const [isTabletWithHBBWFBBPlan, setIsTabletWithHBBWFBBPlan] = useState<boolean>(false);
	const [isAccessoryWithHBBWFBBPlan, setIsAccessoryWithHBBWFBBPlan] = useState<boolean>(false);
	const { productId } = useParams<ProductPageUrlParams>();
	const { search } = useLocation();
	const history = useHistory();
	const outletAvailability = useSelector((state: types.redux.IState) => state.configurator.outletAvailability);

	const isWFBB = isWFBB5G || isWFBB4G;
	const query = new URLSearchParams(search);
	const selectedProductId = productIdFromProps ?? productId;
	const selectedColorIndex = availableColors.findIndex((item) => item.color === selectedColor) ?? 0;
	const selectedColorId = availableColors[selectedColorIndex]?.id;
	const keyFeatures = productById?.keyFeatures || [];
	const whatsInTheBox = productById?.whatsInTheBox || [];
	const longDescription = productDetails?.longDescription || '';
	const technicalSpecification = productById?.technicalSpecification || [];
	const manufacturer = productById?.manufacturer;
	const deviceDimensions = productById?.deviceDimensions;
	const [relatedProducts, setRelatedProducts] = useState<IItem[]>([]);
	const [filteredRecommendedForYou, setFilteredRecommendedForYou] = useState<IItem[]>([]);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const profileHasMultiAccount = accounts && accounts.accounts.length > 1;
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const telesalesInformation = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.telesalesInformation
	);
	const [isAppleWatch, setIsAppleWatch] = useState(false);
	const [isExpApproved, setIsExpApproved] = useState(true);
	const [appleScroll, setAppleScroll] = useState<boolean>(false);
	const [commitmentBaqatiConfig, setCommitmentBaqatiConfig] = useState<IPackage>();
	const [selectedPlanId, setSelectePlanId] = useState<string>('');
	const [isBaqati, setIsBaqati] = useState<boolean>(false);
	const [installmentAmount, setInstallmentAmount] = useState<string>('');
	const [installmentAmountProductId, setInstallmentAmountProductId] = useState<string>('');
	const [installmentAmountId, setInstallmentAmountId] = useState<string>('');
	const [_isCommitmentBaqatiConfig, _setIsCommitmentBaqatiConfig] = useState<boolean>(false);
	const { isBuyNowPayLaterIframe, buyNowPayLaterSessionId, buyNowPayLaterIframeUrl } = useBuyNowPayLater();
	const [errorCodeForInstallmentAmount, setErrorCodeForInstallmentAmount] = useState<string>('');
	const [bnplGetInstallmentAmount, setBnplGetInstallmentAmount] = useState<string>('');
	const { creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);
	const [skuChange, setSkuChange] = useState<string>('');
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_BNPL_CR_ODF_7985 = isDisabledBnplCr7985(remoteFeatureConfig);
	const isCalCulateLoaders = isCalCulateLoader(remoteFeatureConfig);
	// isVariantfix is a feature flag for variant and installment price change issue fix.
	const isVariantfix = isBNPLVariantFix(remoteFeatureConfig);
	const [serviceCode, setserviceCode] = useState<string>('');
	const DISABLE_SENSIBO_PRO_DEVICE = isSensiboProDevice(remoteFeatureConfig);
	let flag1 = false;
	let getInstallmentFlag = false;
	if (
		selectedAccount?.accountType !== 'WFBB' &&
		selectedAccount?.accountType !== 'INTERNET' &&
		selectedAccount?.accountType !== 'FIX'
	) {
		getInstallmentFlag = true;
	} else {
		getInstallmentFlag = false;
	}
	const isHbbInstallmentsOffer = useMemo(() => {
		if (productById?.items?.length) {
			return !!productById?.items?.find((singleSku) => singleSku.defItemType === ITEM_HBB_INSTALMENTS);
		} else {
			return false;
		}
	}, [productById?.items]);

	useEffect(() => {
		if (userIsCorporate && planType === PlanType.WITH_PLAN) {
			if (!!calculatedProduct) {
				dispatch(removeBusyIndicator(SHOW_SUMMARY_INDICATOR));
				dispatch(removeBusyIndicator(INFINITE_LOADING));
				dispatch(removeBusyIndicator(NOT_CHOSEN_NUMBER));
			} else {
				if (showVoucherWarning) {
					dispatch(removeBusyIndicator(SHOW_SUMMARY_INDICATOR));
				}
			}
		}
	}, [userIsCorporate, calculatedProduct, showVoucherWarning]);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ACCOUNT_INFO_BI));
		AccountsService.getCustomerAccountOperation({}).subscribe(
			(res) => {
				// eslint-disable-next-line no-console
				console.log('-------------res?.creditRating', res?.creditRating);
				if (res?.creditRating === CreditRating.GREEN) {
					setIsGreenRatingUser(true);
				}
				dispatch(setPersonalData({ creditRating: res?.creditRating }));
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_ACCOUNT_INFO_BI));
			}
		);
	}, []);

	useEffect(() => {
		let subscription: Subscription | undefined;
		dispatch(addBusyIndicator(GET_SEE_ALSO_BI));
		if (!appleScroll) {
			setTimeout(() => {
				setAppleScroll(false);
			}, 200);
		} else {
			if (appleScroll && !ENABLE_ESHOP_LITE) {
				const appleItemCategory = getAppleItemCategory(productById || ({} as IProductById));
				if (appleItemCategory.name) setShowAppleRelatedContent(true);
				if (productById?.item?.parentCatalogGroupID?.indexOf(appleItemCategory.id) !== -1) {
					if (APPLE_HERO_ITEMS.includes(appleItemCategory.name)) setIsAppleHeroProduct(true);
					dispatch(addBusyIndicator(GET_SEE_ALSO_BI));
					subscription = EspotService.getSimilarItems(appleItemCategory.id).subscribe(
						(r) => {
							if (r) {
								setSimilarItems(r.filter((it) => it?.uniqueID));
								dispatch(addBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
								r.map((value, index) => {
									const fetchInstallment = async () => {
										value.productTextInstallmentValue = await getInstallmentValue(value.uniqueID);
										if (index === r.length - 1) {
											setSimilarItems(r.filter((it) => it.uniqueID));
											dispatch(removeBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
										}
									};
									fetchInstallment().catch(() => {
										dispatch(removeBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
									});
								});
							}
						},
						() => {
							dispatch(removeBusyIndicator(GET_SEE_ALSO_BI));
						},
						() => {
							dispatch(removeBusyIndicator(GET_SEE_ALSO_BI));
						}
					);
				}
			} else {
				dispatch(removeBusyIndicator(GET_SEE_ALSO_BI));
			}
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [productById, appleScroll]);

	useEffect(() => {
		if (planType === PlanType.DEVICE_ONLY) {
			setAccessoryWithPlan(false);
			setMobileDeviceWithPlan(false);
			setIsTabletWithHBBWFBBPlan(false);
			setIsAccessoryWithHBBWFBBPlan(false);
			setProductById(undefined);
			setProductIsSet(false);
		}
	}, [planType, language]);

	useEffect(() => {
		if (!profileHasMultiAccount) {
			setProductById(undefined);
			setProductIsSet(false);
		}
	}, [planType]);

	useEffect(() => {
		if (productById && productName && isAppleWatch) {
			const selectedBandColor = availableColors.find((selectedColor) => selectedColor.id === selectedColorId)
				?.value;
			const selectedCaseColor = availableValues.find((selectedColor) => selectedColor.id === selectedValueId)
				?.label;
			const appleWatchName = productName.split(' ');
			const changedAppleWatchName = appleWatchName.map((splitCell, index) => {
				if ((splitCell === 'Aluminium' || splitCell === 'Stainless') && selectedCaseColor) {
					return `${selectedCaseColor} ${splitCell}`;
				} else if (
					(splitCell === 'Sport' ||
						splitCell === 'Milanese' ||
						splitCell === 'Trail' ||
						splitCell === 'Ocean' ||
						splitCell === 'Alpine') &&
					selectedBandColor
				) {
					return `${selectedBandColor} ${splitCell}`;
				} else return splitCell;
			});
			productById.item.name = changedAppleWatchName.join(' ');
		}
		setProductDetails(productById?.item);
	}, [productById, isAppleWatch, selectedColorId, selectedValueId]);

	useEffect(() => {
		if (productName && productById) {
			productById.item.name = productName;
			setProductDetails(productById?.item);
		}
	}, [productName, productById]);

	useEffect(() => {
		setProductDetails(undefined);
		setSelectedColor(null);
		setSelectedValue(null);
		setAvailableColors([]);
		setAvailableValues([]);
		setProductById(undefined);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [productId]);

	useEffect(() => {
		dispatch(setSelectedItems({ isHbbInstallmentsOffer }));
	}, [isHbbInstallmentsOffer]);

	const getColorId = () => {
		return query.get(PRODUCT_PAGE_QUERY_COLOR_PARAM);
	};

	const isItemTypeFlag = query.get(PRODUCT_PAGE_ITEM_FLAG) === 'true';

	useEffect(() => {
		dispatch(setSelectedItems({ isExpApproved: true }));
		setTradeInBanners({} as IMarketingBanners);
		EspotService.getMarketingBanners('TradeInBanners', true).subscribe(
			(r) => {
				if (r.banners.length > 0) {
					setTradeInBanners(r);
				}
			},
			() => {}
		);
	}, []);

	const getBanner = (): ISingleMarketingBanner => {
		if (tradeInBanners.banners && tradeInBanners.banners.length > 1) {
			if (screenClass === 'xs') {
				return tradeInBanners?.banners?.find((item) => item?.img?.includes('/XS')) || {};
			} else if (screenClass === 'sm') {
				return tradeInBanners?.banners?.find((item) => item?.img?.includes('/SM')) || {};
			} else if (screenClass === 'md') {
				return tradeInBanners?.banners?.find((item) => item?.img?.includes('/MD')) || {};
			} else if (screenClass === 'lg') {
				return tradeInBanners?.banners?.find((item) => item?.img?.includes('/LG')) || {};
			} else if (screenClass === 'xl') {
				return tradeInBanners?.banners?.find((item) => item?.img?.includes('/XL')) || {};
			}
		}
		return {};
	};

	const clearProductPage = () => {
		setRelatedProducts([]);
		setSimilarItems([]);
		setFilteredRecommendedForYou([]);
		setIsAppleHeroProduct(false);
		setShowAppleRelatedContent(false);
		setProductDetails(undefined);
		setSelectedColor(null);
		setSelectedValue(null);
		setAvailableColors([]);
		setAvailableValues([]);
		setProductById(undefined);
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		if (productDetails?.parentCatalogGroupID) {
			const productPageBreadcrumbs = [
				...getBreadcrumbsFromCategory(productDetails?.parentCatalogGroupID),
				{
					link: `product/${productDetails.uniqueID}`,
					label: productDetails.name,
				},
			];
			dispatch(updateBreadcrumb(productPageBreadcrumbs));
		}
	}, [productById]);

	useEffect(() => {
		if (isLogged) {
			dispatch(addBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
			AccountsService.getAccountsForLoggedUser().subscribe(
				(response) => {
					if (response.accounts) {
						const accounts = response.accounts.filter(
							(account) => account.accountType === MOBILE_ACCOUNT_TYPE
						);
						response.accounts = accounts;
						setAccounts(response);
						const isB2bUser = B2B_CATEGORIES.includes(response.accounts[0]?.accountCategory || '');
						dispatch(
							setB2bUser(
								!!isB2bUser && !DISABLE_EBU_SINGLE_PURCHASE && !DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI
							)
						);
						dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
					}
				},
				() => {
					dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
				}
			);
		}
	}, [isLogged]);

	useEffect(() => {
		if (
			!outletAvailability?.some((item) => item.uniqueId === selectedSKU?.id) &&
			!!selectedSKU &&
			!DISABLE_PICKUP_FROM_OUTLET
		) {
			dispatch(addBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
			if (!!outletAvailability) {
				ProductsService.getProductOutletAvailability(selectedSKU.partNumber).subscribe((r) => {
					dispatch(
						setOutletAvailability({
							outletAvailability: [
								...outletAvailability,
								{ uniqueId: selectedSKU.partNumber, isAvailable: r.returnValue.available },
							],
						})
					);
					dispatch(removeBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
				});
			} else {
				ProductsService.getProductOutletAvailability(selectedSKU.partNumber).subscribe((r) => {
					dispatch(
						setOutletAvailability({
							outletAvailability: [
								{ uniqueId: selectedSKU.partNumber, isAvailable: r.returnValue.available },
							],
						})
					);
					dispatch(removeBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
				});
			}
		}
		dispatch(removeBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
	}, [selectedSKU?.partNumber]);

	useEffect(() => {
		if (availableColors.length) {
			const selectedColorProperty = availableColors.find((color) => color.color === selectedColor);
			setImageLoader(true);
			setSelectedImage(selectedColorProperty?.fullImage ?? '');
		} else {
			setSelectedImage(productDetails?.fullImage ?? '');
		}
		if (selectedColorId) {
			query.set(PRODUCT_PAGE_QUERY_COLOR_PARAM, selectedColorId);
			// window.history.replaceState({}, '', `${location.pathname}?${query}`);
		}
	}, [selectedColor, productDetails]);

	useEffect(() => {
		if (!isVariantfix) {
			const _product = productById?.items?.find((item) => findProperProduct({ item, skipInstallments: false }));
			let productInventory: IInventory | undefined;
			if ((productDetails?.isItem || productDetails?.isGiftCard) && !productDetails.colorAttributeId) {
				const inventoryId = productDetails.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
				if (inventoryId) {
					productInventory = inventory?.[inventoryId]?.find(
						(item) => item.sku === _product?.productPartNumber
					);
				}
			} else {
				productInventory = inventory?.[productById?.item.colorAttributeId ?? '']?.find(
					(item) => item.sku === _product?.productPartNumber
				);
			}
			if (_product && installmentPeriod && !isExpApproved) {
				dispatch(addBusyIndicator(GET_BNPL_PRICES));
				InventoryService.getBuyNowPayLaterInstallmentAmount({
					productId: _product.id,
					installmentDuration: installmentPeriod,
					bnplProductPrice: bnplGetInstallmentAmount,
					mobilePhone: selectedAccount?.accountNum ?? '',
					creditRating: creditRating ?? '',
					isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
					accountNo: selectedAccount?.accountId?.toString() ?? '',
					emfConfigId: emfConfigId.POSTPAID,
					serviceCode,
				}).subscribe(
					(response) => {
						getCatEntrySuccess(response, _product);
						dispatch(removeBusyIndicator(GET_BNPL_PRICES));
					},
					() => {
						getCatEntryError(_product, productInventory);
						dispatch(removeBusyIndicator(GET_BNPL_PRICES));
					}
				);
			}
		}
	}, [isBuyNowPayLaterFlow]);

	const getItemType = () => {
		const isPostpaid = !isHBB && !isWFBB;
		if (planType === PlanType.WITH_PLAN && userIsCorporate) {
			return ItemType.ITEM;
		} else if (isLogged && isPostpaid && planType === PlanType.WITH_PLAN && isCommitmentBaqatiConfig) {
			return ItemType.ITEM;
		} else if (
			isHBB &&
			(accessoryWithPlan || !!isAccessoryWithHBBWFBBPlan) &&
			isLogged &&
			planType === PlanType.WITH_PLAN
		) {
			return ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB;
		} else if (
			isWFBB &&
			(accessoryWithPlan || !!isAccessoryWithHBBWFBBPlan) &&
			isLogged &&
			planType === PlanType.WITH_PLAN
		) {
			return ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB;
		} else if (accessoryWithPlan && isLogged && planType === PlanType.WITH_PLAN) {
			return ItemType.ITEM_ACCESSORY_INSTALMENTS;
		} else if (isHBB && mobileDeviceWithPlan && isLogged && planType === PlanType.WITH_PLAN) {
			return ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB;
		} else if (isWFBB && mobileDeviceWithPlan && isLogged && planType === PlanType.WITH_PLAN) {
			return ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB;
		} else if (mobileDeviceWithPlan && isLogged && planType === PlanType.WITH_PLAN) {
			return ItemType.TABLETS_LAPTOPS_INSTALMENTS;
		} else if (!isLogged && !isItemTypeFlag) {
			return ItemType.ITEM;
		} else if (isLogged && profileHasMultiAccount && !currentPlan && planType === PlanType.DEVICE_ONLY) {
			return ItemType.ITEM;
		} else if (isItemTypeFlag) {
			return ItemType.EMPTY;
		} else if (isLogged && isPostpaid && planType === PlanType.DEVICE_ONLY) {
			return ItemType.ITEM;
		} else if (isLogged && isPostpaid && planType === PlanType.WITH_PLAN) {
			return ItemType.ITEM_MOBILE;
		} else if (isLogged && isHBB && planType === PlanType.DEVICE_ONLY) {
			return ItemType.ITEM;
		} else if (isLogged && isHBB && planType === PlanType.WITH_PLAN) {
			return ItemType.ITEM_HBB;
		} else if (isLogged && isWFBB && planType === PlanType.WITH_PLAN) {
			return ItemType.ITEM_WFBB;
		} else {
			return ItemType.EMPTY;
		}
	};

	const processProductData = (response: IProductById) => {
		let isEligibileDeviceGroup = false;
		if (!!installmentPeriod) {
			const getAccountRating = () => {
				if (isGreenRatingUser) {
					return 'GN';
				}
				return 'NGN';
			};
			let _installmentAmountId = getDeviceInstallmentCode(
				installmentPeriod,
				selectedPlanId ?? '',
				getAccountRating()
			);
			_installmentAmountId = _installmentAmountId.replace('_OM', '');

			const installmentAmountProductPackage = commitmentBaqatiConfig?.packages?.find(
				(packageItem) => packageItem.partNumber === _installmentAmountId
			);
			const attributes = installmentAmountProductPackage?.all?.attributes;
			const eligibileDeviceGroup = attributes
				? attributes?.find((attr) => attr.identifier === ELIGIBLE_DEVICE_GROUP)?.values[0]?.value ?? ''
				: '';
			const productCategory = getProductCategory(productDetails?.parentCatalogGroupID);

			isEligibileDeviceGroup = eligibileDeviceGroup === productCategory;
		}
		if (response.item) {
			const allItemtypes = response?.item?.allItemType;
			const isAccessoryInstallment =
				allItemtypes?.find((type) => type === ItemType.ITEM_ACCESSORY_INSTALMENTS) || false;
			const isMobileDeviceInstallment =
				allItemtypes?.find((type) => type === ItemType.TABLETS_LAPTOPS_INSTALMENTS) || false;
			const isTabletWithHBBWFBBInstallment =
				allItemtypes?.find(
					(type) =>
						type === (ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB || ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB)
				) || false;
			const isAccessoryWithHBBWFBBInstallment =
				allItemtypes?.find(
					(type) =>
						type === (ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB || ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB)
				) || false;
			setAccessoryWithPlan(isAccessoryInstallment === ItemType.ITEM_ACCESSORY_INSTALMENTS);
			setIsAccessoryWithHBBWFBBPlan(!!isAccessoryWithHBBWFBBInstallment);
			setMobileDeviceWithPlan(isMobileDeviceInstallment === ItemType.TABLETS_LAPTOPS_INSTALMENTS);
			setIsTabletWithHBBWFBBPlan(!!isTabletWithHBBWFBBInstallment);
			setProductById(response);
			if (isEligibileDeviceGroup) {
				dispatch(setSelectedItems({ productById: response?.items }));
			}
			setProductName(response.item.name);
			setProductIsSet(!!response);
		} else {
			history.push(`/${getLang()}/store`);
		}
		dispatch(removeBusyIndicator(GET_PRODUCT_DETAILS_BI));
		setProductByIdIsLoading(false);
	};

	const handleGetProductById = () => {
		setProductByIdIsLoading(false);
		dispatch(removeBusyIndicator(GET_PRODUCT_DETAILS_BI));
	};

	useEffect(() => {
		const isTelesales = telemarketer && !!telesalesInformation?.phoneNumber;

		const digitalItemType = isItemTypeFlag && selectedProductId && !productById;
		!isLogged && planType === PlanType.DEVICE_ONLY && !productById && !!selectedProductId;

		const notLoggedOnlyDevice =
			!isLogged && planType === PlanType.DEVICE_ONLY && !productById && !!selectedProductId;

		const loggedOnlyDevice = isLogged && planType === PlanType.DEVICE_ONLY && !productById && !!selectedProductId;

		const loggedWithPlanNotMultiAccount =
			isLogged &&
			planType === PlanType.WITH_PLAN &&
			(!productById || isTelesales) &&
			!profileHasMultiAccount &&
			!!selectedProductId;

		const loggedWithPlanHasMultiAccountHasSelectedAcc =
			isLogged &&
			planType === PlanType.WITH_PLAN &&
			profileHasMultiAccount &&
			!!selectedAccount &&
			!!productById &&
			!!currentPlan &&
			!!selectedProductId;

		const loggedDeviceOnlyHasMultiAccountNotProductById =
			isLogged &&
			planType === PlanType.DEVICE_ONLY &&
			profileHasMultiAccount &&
			!productById &&
			!!selectedProductId;

		if (
			digitalItemType ||
			notLoggedOnlyDevice ||
			loggedOnlyDevice ||
			loggedWithPlanNotMultiAccount ||
			loggedWithPlanHasMultiAccountHasSelectedAcc ||
			loggedDeviceOnlyHasMultiAccountNotProductById
		) {
			dispatch(addBusyIndicator(GET_PRODUCT_DETAILS_BI));
			setProductByIdIsLoading(true);
			const itemType = getItemType();

			if (selectedProductId && /^\d+$/.test(selectedProductId)) {
				ProductsService.getProductByIdSpecifiedSkus({
					productId: selectedProductId,
					itemType,
				}).subscribe(
					(response) => processProductData(response),
					() => handleGetProductById()
				);
			} else if (selectedProductId) {
				ProductsService.getProductIdBySeo(selectedProductId).subscribe(
					(responseSeo) => {
						setResponseSeoId(responseSeo.tokenValue);
						ProductsService.getProductByIdSpecifiedSkus({
							productId: responseSeo.tokenValue,
							itemType,
						}).subscribe(
							(response) => processProductData(response),
							() => handleGetProductById()
						);
					},
					() => handleGetProductById()
				);
			}
		}
	}, [
		productIsSet,
		selectedAccount,
		selectedProductId,
		isLogged,
		currentPlan,
		planType,
		language,
		isBuyNowPayLaterFlow,
	]);

	window.onscroll = function () {
		if (!appleScroll) {
			setAppleScroll(true);
		}
	};

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (selectedProductId) {
			if (!appleScroll) {
				setTimeout(() => {
					setAppleScroll(false);
				}, 200);
			} else {
				if (appleScroll && !ENABLE_ESHOP_LITE) {
					dispatch(addBusyIndicator(GET_RELATED_PRODUCTS_BI));
					setRelatedProducts([]);
					setFilteredRecommendedForYou([]);
					const productId = !isNaN(parseFloat(selectedProductId)) ? selectedProductId : responseSeoId;
					subscription = ProductsService.getRelatedProductsByIdWithInventory({
						productId,
						itemType: ItemType.UNKNOWN,
					}).subscribe(
						(r) => {
							setRelatedProducts(r.relatedProductsWithInventory);
							dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
						},
						() => {
							dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
						}
					);
				}
			}
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [productById, appleScroll]);

	const sortCapacity = (a: IAvailableValueItem, b: IAvailableValueItem) => {
		const x = +a.label.split(' ')[0];
		const y = +b.label.split(' ')[0];
		if (x < y) {
			return -1;
		}
		if (x > y) {
			return 1;
		}
		return 0;
	};

	const additionalSortCapacity = (a: IAvailableValueItem, b: IAvailableValueItem) => {
		if (a.label.includes('GB') && b.label.includes('TB')) {
			return -1;
		}
		if (a.label.includes('TB') && b.label.includes('GB')) {
			return 1;
		}
		return 0;
	};

	const getInventory = (changeSelectedCapacity?: boolean) => {
		if (productDetails?.uniqueID) {
			dispatch(addBusyIndicator(GET_COMPONENTS_FROM_INVENTORY_BI));
			InventoryService.getAvailableProductComponents({
				product: productDetails,
				items: productById?.items ?? [],
			}).subscribe(
				({ availableCapacity, availableColors, inventory }) => {
					if ((productDetails.isItem || productDetails.isGiftCard) && !productDetails.colorAttributeId) {
						const inventoryId = productDetails.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)
							?.id;
						if (inventoryId) {
							inventory[inventoryId].map(
								(inv) => !!Number(inv.value) && Number(inv.value) > 0 && setIsItemAvailable(true)
							);
						}
					}
					const labelType = productDetails.attributes?.find(
						(attribute) => attribute.identifier === 'DEVICE_LABEL_TYPE'
					);
					const isAppleWatch =
						labelType?.values[0].identifier === 'APPLE_WATCH' ||
						labelType?.values[0].value === 'APPLE_WATCH';
					setIsAppleWatch(isAppleWatch);
					if (productById?.items && isAppleWatch && availableCapacity.length > 1) {
						const findedItem = productById?.items.find(
							(item) => item.capacityId === selectedValueId ?? availableCapacity[0].id
						);
						const bandColor = availableColors.filter((color) => color.id === findedItem?.colorId);
						if (bandColor.length > 0) {
							setAvailableColors(bandColor);
							setSelectedColor(bandColor[0]?.color);
						} else setAvailableColors([]);
					} else setAvailableColors(availableColors);
					setInventory(inventory);

					const newAvailableCapacity = availableCapacity
						.map((capacity) => {
							const _color = availableColors.find(
								(color) => color.id === (selectedColorId ?? getColorId())
							);
							if (_color?.availableCapacity.includes(capacity.id) && !isAppleWatch) {
								capacity.available = true;
							} else {
								if (isAppleWatch) {
									capacity.available = true;
								} else capacity.available = false;
							}
							return capacity;
						})
						.filter((cap) => {
							if (!productDetails.isGiftCard) {
								return true;
							}
							return productById?.items
								?.filter((product) => product.countryId === (selectedColorId ?? getColorId()))
								.some((prod) => cap.id === prod.valueId);
						})
						.sort(sortCapacity)
						.sort(additionalSortCapacity);
					setAvailableValues(newAvailableCapacity);
					if (
						changeSelectedCapacity &&
						!newAvailableCapacity.find((cap) => cap.id === selectedValueId)?.available
					) {
						const index = newAvailableCapacity.findIndex((cap) => cap.available);
						if (index < 0) {
							setSelectedValue(newAvailableCapacity[0]?.id);
						} else {
							setSelectedValue(newAvailableCapacity[index].id);
						}
					}
					dispatch(removeBusyIndicator(GET_COMPONENTS_FROM_INVENTORY_BI));
					setImageLoader(false);
				},
				() => {
					dispatch(removeBusyIndicator(GET_COMPONENTS_FROM_INVENTORY_BI));
					setImageLoader(false);
				}
			);
		} else {
			setImageLoader(false);
		}
	};

	useEffect(() => {
		getInventory();
	}, [selectedValueId, productDetails]);

	useEffect(() => {
		const selectedCapacityMatchAvailableValues = availableValues.some(
			(availableValue) => availableValue.id === configurator.selectedCapacity
		);
		getInventory(!selectedCapacityMatchAvailableValues || productDetails?.isGiftCard);
	}, [selectedColor]);

	useEffect(() => {
		if (selectedPlanToSubscribe) {
			_setProductDetails();
		}
	}, [selectedPlanToSubscribe, isBuyNowPayLaterFlow]);

	useEffect(() => {
		let inStock = false;
		if (isAppleWatch) {
			if (!Array.isArray(availableColors)) inStock = false;
			else if (availableColors.length === 0) inStock = false;
			else inStock = !!availableColors.find((col) => col.id === selectedColorId)?.available;
		} else {
			if (isItemAvailable) {
				inStock = true;
			} else if (availableValues.length) {
				inStock = !!availableValues.find((cap) => cap.id === selectedValueId)?.available;
			} else {
				inStock = !!availableColors.find((col) => col.id === selectedColorId)?.available;
			}
		}
		dispatch(setSelectedItems({ inStock }));
	}, [availableValues, availableColors, selectedValueId]);

	useEffect(() => {
		setFilteredRecommendedForYou(getOnlyAvailableItems(relatedProducts, true));
	}, [relatedProducts]);

	useEffect(() => {
		if (!selectedColor && availableColors.length) {
			const index = availableColors.findIndex((item) => item.id === getColorId()) ?? 0;
			if (index < 0) {
				setSelectedColor(availableColors[0]?.color);
			} else {
				setSelectedColor(availableColors[index]?.color);
			}
		}
		if (!selectedValueId && availableValues.length) {
			const index = availableValues.findIndex((item) => item.available);
			if (index >= 0) {
				setSelectedValue(availableValues[index]?.id);
			} else {
				setSelectedValue(availableValues[0].id);
			}
		}
	}, [selectedColor, availableColors, selectedValueId, availableValues]);

	useEffect(() => {
		if (productById && !!pricesWithVat?.listPrice && pricesWithVat?.listPrice !== '0') {
			TagManager.dataLayer({
				dataLayer: {
					event: 'productViewProduct',
					productViewProduct: {
						productId: productById.item.uniqueID ?? '1',
						productSkuId: selectedSKU?.id,
						productPrice: pricesWithVat?.listPrice ?? '1',
					},
					userId: getUserId(),
				},
			});
		}
	}, [productById, pricesWithVat, selectedSKU]);

	const isCommitmentBaqatiConfig = useMemo(() => {
		if (!DISABLE_DEVICE_INSTALLMENT_CONFIG) {
			if (productById === undefined) {
				return _isCommitmentBaqatiConfig;
			} else {
				const attributes = productById?.item.attributes;
				if (attributes) {
					const commitmentBaqatiConfigState =
						attributes.find((attr) => attr.identifier === COMMITMENT_BAQATI_CONFIG)?.values[0].value ===
						'TRUE';
					_setIsCommitmentBaqatiConfig(commitmentBaqatiConfigState);
					return commitmentBaqatiConfigState;
				}
			}
		}
		return false;
	}, [productById, DISABLE_DEVICE_INSTALLMENT_CONFIG]);

	const isCreditRatingConfig = useMemo(() => {
		if (productById === undefined) {
			return _isCommitmentBaqatiConfig;
		} else {
			const attributes = productById?.item.attributes;
			if (attributes) {
				const commitmentGreenRatingState =
					attributes.find((attr) => attr.identifier === ENABLE_CREDIT_RATING_LOGIC_GN_NGN)?.values[0]
						.value === 'TRUE';
				return commitmentGreenRatingState;
			}
		}
		return false;
	}, [productById]);

	useEffect(() => {
		_setProductDetails();
	}, [inventory, productDetails, selectedPlanId, installmentPeriod, planType, selectedSKU?.partNumber]);

	useEffect(() => {
		if (isCommitmentBaqatiConfig && isBaqati && isLogged) {
			PackagesService.getPackagesByGroupAndType('COMMITMENT', 'POSTPAID').subscribe((response) => {
				setCommitmentBaqatiConfig(response);
			});
		}
	}, [isCommitmentBaqatiConfig, isBaqati, isLogged, productById]);

	const getDeviceInstallmentCode = (
		installmentDuration: '12' | '24',
		tariffId: string,
		userAccountRating: string = 'GN'
	) => {
		if (isBuyNowPayLaterFlow) {
			userAccountRating = 'BNPL';
			return installmentDuration ? userAccountRating + '_' + installmentDuration : '';
		} else {
			return installmentDuration ? userAccountRating + '_' + installmentDuration + '_' + tariffId : '';
		}
	};

	const findProperProduct = ({ item, skipInstallments }: { item: IProductByIdItem; skipInstallments?: boolean }) => {
		const withoutPlan = !item.installmentPeriod && !item.installmentValue && !commitmentBaqatiConfig;
		const matchSelectedColor =
			selectedColorId && (item.colorId === selectedColorId || item.countryId === selectedColorId);
		const matchSelectedValue = item.capacityId === selectedValueId || item.valueId === selectedValueId;
		const matchSelectedPlan =
			item.planDetails.planId === selectedPlanToSubscribe?.partNumberRaw || !!commitmentBaqatiConfig;
		const matchSelectedPeriod = item.installmentPeriod === installmentPeriod || !!commitmentBaqatiConfig;
		const installmentOfferDeviceOnly =
			!!isHbbInstallmentsOffer && item.defItemType === ITEM_TYPE && planType === PlanType.DEVICE_ONLY;
		const installmentOfferWithPlan =
			!!isHbbInstallmentsOffer &&
			(matchSelectedPeriod || skipInstallments) &&
			item.defItemType === ITEM_HBB_INSTALMENTS &&
			planType === PlanType.WITH_PLAN;
		const itemWithoutPlan =
			productDetails?.isItem &&
			item.defItemType === ITEM_TYPE &&
			!item.partNumber.includes('##') &&
			!productDetails.colorAttributeId &&
			!isHbbInstallmentsOffer;
		const isAccessoryWithPlan =
			matchSelectedPlan &&
			productDetails?.isItem &&
			item.defItemType === ITEM_ACCESSORY_INSTALMENTS &&
			item.partNumber.includes('##') &&
			!isHbbInstallmentsOffer;
		const isAccessoryWithHBBWFBBPlan =
			matchSelectedPlan &&
			productDetails?.isItem &&
			item.defItemType === (ITEM_ACCESSORY_INSTALMENTS_HBB || ITEM_ACCESSORY_INSTALMENTS_WFBB) &&
			item.partNumber.includes('##') &&
			!isHbbInstallmentsOffer;
		const isMobileDeviceWithPlan =
			matchSelectedPlan &&
			productDetails?.isItem &&
			item.defItemType === TABLETS_LAPTOPS_INSTALMENTS &&
			item.partNumber.includes('##') &&
			!isHbbInstallmentsOffer;
		const isMobileDeviceHBBWFBB =
			matchSelectedPlan &&
			productDetails?.isItem &&
			item.defItemType === (TABLETS_LAPTOPS_INSTALMENTS_HBB || TABLETS_LAPTOPS_INSTALMENTS_WFBB) &&
			item.partNumber.includes('##') &&
			!isHbbInstallmentsOffer;
		if (installmentOfferDeviceOnly || (productDetails?.isGiftCard && !productDetails.colorAttributeId)) {
			return true;
		} else if (installmentOfferWithPlan) {
			return true;
		} else if (itemWithoutPlan) {
			return true;
		} else if (
			(!!selectedColorId ? matchSelectedColor : true) &&
			(isAccessoryWithPlan || isAccessoryWithHBBWFBBPlan) &&
			matchSelectedPeriod
		) {
			return true;
		} else if (
			(!!selectedColorId ? matchSelectedColor : true) &&
			(!!selectedValueId ? matchSelectedValue : true) &&
			(isMobileDeviceWithPlan || isMobileDeviceHBBWFBB) &&
			matchSelectedPeriod
		) {
			return true;
		} else if (matchSelectedColor && matchSelectedValue && !selectedPlanToSubscribe && withoutPlan) {
			return true;
		} else if (
			matchSelectedColor &&
			matchSelectedValue &&
			matchSelectedPlan &&
			(matchSelectedPeriod || skipInstallments)
		) {
			return true;
		} else if (
			!availableValues.length &&
			matchSelectedColor &&
			item.defItemType !== ITEM_ACCESSORY_INSTALMENTS &&
			item.defItemType !== TABLETS_LAPTOPS_INSTALMENTS
		) {
			return !(
				!DISABLE_SENSIBO_PRO_DEVICE &&
				(item.defItemType === ITEM_ACCESSORY_INSTALMENTS_HBB ||
					item.defItemType === ITEM_ACCESSORY_INSTALMENTS_WFBB)
			);
		} else {
			return false;
		}
	};

	const getAvailableSku = useMemo((): IProductByIdItem[] | undefined => {
		return productById?.items?.filter((item) => {
			if (findProperProduct({ item, skipInstallments: false })) {
				return item;
			}
		});
	}, [
		productById,
		planType,
		installmentPeriod,
		selectedPlanToSubscribe,
		selectedColor,
		selectedValueId,
		productDetails,
	]);
	useEffect(() => {
		if (isVariantfix && isBuyNowPayLaterFlow && ((isUpfront && !isExpApproved) || !!skuChange)) {
			dispatch(setSelectedItems({ isExpApproved: true }));
		}
	}, [isUpfront, skuChange]);

	const getInstallmentAmount = (response: IPriceByCatEntry, _product: IProductByIdItem | undefined) => {
		const {
			offerPrice,
			vatValue,
			vatPercent,
			listPrice,
			taxablePrice,
			comParamInstallmentPeriod,
			comParamInstallmentValue,
			comParamInstallmentValueBz,
			returnCode,
			decisionReasonCode,
		} = response.price;
		if (!DISABLE_BNPL_CR_ODF_7985 && listPrice) {
			setBnplGetInstallmentAmount(String(listPrice));
		}
		if (isCalCulateLoaders) {
			dispatch(removeBusyIndicator(GET_CALCULATE_INFO));
		}
		flag1 = true;
		if (returnCode === '0000') {
			const vatPrices = { offerPrice, vatValue, vatPercent, listPrice, taxablePrice };
			const additionalPricesFromApi = {
				installmentPeriod: comParamInstallmentPeriod,
				installmentValue: comParamInstallmentValue,
				priceDisplay: Number(listPrice || '0'),
				priceOffer: Number(offerPrice || '0'),
			};
			if (isCommitmentBaqatiConfig) setInstallmentAmount(comParamInstallmentValueBz ?? comParamInstallmentValue);

			dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_BI));
			dispatch(removeBusyIndicator(INFINITE_LOADING));
			setPricesWithVat(vatPrices);
			if (_product && additionalPricesFromApi) {
				dispatch(
					setSelectedItems({
						details: { ..._product, ...additionalPricesFromApi, pricesWithVat: vatPrices ?? '' },
					})
				);
			}
		} else {
			setIsExpApproved(false);
			dispatch(setSelectedItems({ isExpApproved: false }));
			dispatch(removeBusyIndicator(INFINITE_LOADING));
			setErrorCodeForInstallmentAmount(decisionReasonCode || returnCode || '');
		}
	};

	const getCatEntrySuccess = (response: IPriceByCatEntry, _product: IProductByIdItem | undefined) => {
		const {
			offerPrice,
			vatValue,
			vatPercent,
			listPrice,
			taxablePrice,
			comParamInstallmentPeriod,
			comParamInstallmentValue,
			comParamInstallmentValueBz,
			comParamInstallmentValueFinal,
		} = response.price;
		const vatPrices = { offerPrice, vatValue, vatPercent, listPrice, taxablePrice };
		const additionalPricesFromApi = {
			installmentPeriod: comParamInstallmentPeriod,
			installmentValue: comParamInstallmentValue,
			installmentValueFinal: comParamInstallmentValueFinal,
			priceDisplay: isBuyNowPayLaterFlow ? Number(taxablePrice || '0') : Number(listPrice || '0'),
			priceOffer: Number(offerPrice || '0'),
			isBNPL: isBuyNowPayLaterFlow,
		};
		if (isCommitmentBaqatiConfig) setInstallmentAmount(comParamInstallmentValueBz ?? comParamInstallmentValue);

		dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_BI));
		dispatch(removeBusyIndicator(GET_RECOMMENDED_PLAN));
		setPricesWithVat(vatPrices);
		if (_product && additionalPricesFromApi) {
			dispatch(
				setSelectedItems({
					details: { ..._product, ...additionalPricesFromApi, pricesWithVat: vatPrices ?? '' },
				})
			);
		}
	};

	const getCatEntryError = (_product: IProductByIdItem | undefined, productInventory: IInventory | undefined) => {
		if (isCalCulateLoaders) {
			dispatch(removeBusyIndicator(GET_CALCULATE_INFO));
		}
		if (_product && productInventory && !isBuyNowPayLaterFlow) {
			const { offerPrice, vatValue, vatPercent, listPrice, taxablePrice } = productInventory;
			const vatPrices = { offerPrice, vatValue, vatPercent, listPrice, taxablePrice };
			setPricesWithVat(vatPrices);
			dispatch(
				setSelectedItems({
					details: { ..._product, pricesWithVat: vatPrices },
				})
			);
		}
		dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_BI));
	};

	const getAccountRating = () => {
		if (isGreenRatingUser) {
			return 'GN';
		}
		return 'NGN';
	};

	const _setProductDetails = () => {
		dispatch(addBusyIndicator(OUTLET_AVAILABLE_INDICATOR));

		let _product = productById?.items?.find((item) => findProperProduct({ item, skipInstallments: false }));
		if (!_product) {
			// EDGE CASE! -> If product not found, check if exist sku with different installment period
			_product = productById?.items?.find((item) => findProperProduct({ item, skipInstallments: true }));
			if (_product) {
				setInstallmentPeriod(
					_product.installmentPeriod === '12' ? '12' : isBuyNowPayLaterFlow ? undefined : '24'
				);
			}
		}

		let productInventory: IInventory | undefined;

		if ((productDetails?.isItem || productDetails?.isGiftCard) && !productDetails.colorAttributeId) {
			const inventoryId = productDetails.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
			if (inventoryId) {
				productInventory = inventory?.[inventoryId]?.find((item) => item.sku === _product?.productPartNumber);
			}
		} else {
			productInventory = inventory?.[productById?.item.colorAttributeId ?? '']?.find(
				(item) => item.sku === _product?.productPartNumber
			);
		}

		if (_product && productInventory) {
			if (_product.id) {
				dispatch(addBusyIndicator(GET_PRICE_BY_CAT_ENTRY_BI));
				let _installmentAmountId = '';
				let _installmentAmountProductId = '';
				let isEligibileDeviceGroup = false;
				// flag1 = false;
				if (
					isCommitmentBaqatiConfig &&
					commitmentBaqatiConfig?.packages &&
					commitmentBaqatiConfig.packages.length > 0 &&
					isLogged &&
					!!installmentPeriod &&
					!!selectedPlanId
				) {
					_installmentAmountId = getDeviceInstallmentCode(
						installmentPeriod,
						selectedPlanId ?? '',
						getAccountRating()
					);
					_installmentAmountId = _installmentAmountId.replace('_OM', '');
					setInstallmentAmountId(_installmentAmountId);
					const installmentAmountProductPackage = commitmentBaqatiConfig?.packages?.find(
						(packageItem) => packageItem.partNumber === _installmentAmountId
					);
					_installmentAmountProductId = installmentAmountProductPackage?.name ?? '';
					setInstallmentAmountProductId(_installmentAmountProductId);
					const eligibileDeviceGroup = installmentAmountProductPackage?.all?.attributes
						? installmentAmountProductPackage?.all?.attributes?.find(
								(attr) => attr.identifier === ELIGIBLE_DEVICE_GROUP
						  )?.values[0]?.value ?? ''
						: '';
					const productCategory = getProductCategory(productDetails?.parentCatalogGroupID);
					isEligibileDeviceGroup = eligibileDeviceGroup === productCategory;
				}
				setserviceCode(productInventory.sku);
				if (isVariantfix) {
					setSkuChange(productInventory?.sku ?? '');
				}
				if (
					(isLogged &&
						!!installmentPeriod &&
						!!_installmentAmountProductId &&
						isEligibileDeviceGroup &&
						isExpApproved &&
						getInstallmentFlag &&
						!isUpfront) ||
					(isBaqatiPlan &&
						installmentPeriod &&
						isGreenRatingUser &&
						!isUpfront &&
						!!_installmentAmountProductId &&
						isExpApproved &&
						getInstallmentFlag) ||
					(isBaqatiPlan &&
						installmentPeriod === '12' &&
						!isGreenRatingUser &&
						!isUpfront &&
						!!_installmentAmountProductId &&
						isExpApproved &&
						getInstallmentFlag)
				) {
					flag1 = true;
					dispatch(addBusyIndicator(INFINITE_LOADING));
					if (isCalCulateLoaders) {
						dispatch(addBusyIndicator(GET_CALCULATE_INFO));
					}
					InventoryService.getInstallmentAmount({
						mobilePhone: selectedAccount?.accountNum ?? '',
						creditRating: creditRating ?? '',
						accountNo: selectedAccount?.accountId?.toString() ?? '',
						emfConfigId: emfConfigId.POSTPAID,
						productId: _installmentAmountProductId,
						serviceCode: productInventory.sku,
						voucherAmount: '',
						installmentDuration: installmentPeriod,
						isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
					}).subscribe(
						(response) => {
							flag1 = true;
							setTimeout(() => {
								getInstallmentAmount(response, _product);
							}, 1000);

							dispatch(
								setSelectedItems({
									installmentAmount: response.price.comParamInstallmentValueBz,
									commitment: _installmentAmountProductId,
								})
							);
						},
						() => getCatEntryError(_product, productInventory)
					);
				} else if (
					isBuyNowPayLaterFlow &&
					!!installmentPeriod &&
					getInstallmentFlag &&
					((isVariantfix && !isExpApproved) || !isVariantfix)
				) {
					dispatch(addBusyIndicator(GET_BNPL_PRICES));
					InventoryService.getBuyNowPayLaterInstallmentAmount({
						mobilePhone: selectedAccount?.accountNum ?? '',
						creditRating: creditRating ?? '',
						productId: _product.id,
						installmentDuration: installmentPeriod,
						bnplProductPrice: bnplGetInstallmentAmount,
						isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
						accountNo: selectedAccount?.accountId?.toString() ?? '',
						emfConfigId: emfConfigId.POSTPAID,
						serviceCode: productInventory.sku,
					}).subscribe(
						(response) => {
							getCatEntrySuccess(response, _product);
							dispatch(removeBusyIndicator(GET_BNPL_PRICES));
						},
						() => {
							getCatEntryError(_product, productInventory);
							dispatch(removeBusyIndicator(GET_BNPL_PRICES));
						}
					);
				} else if (
					(isLogged &&
						!!installmentPeriod &&
						!!_installmentAmountProductId &&
						isEligibileDeviceGroup &&
						getInstallmentFlag &&
						!isUpfront) ||
					(isBaqatiPlan &&
						installmentPeriod &&
						isGreenRatingUser &&
						!isUpfront &&
						!!_installmentAmountProductId &&
						getInstallmentFlag) ||
					(isBaqatiPlan &&
						installmentPeriod === '12' &&
						!isGreenRatingUser &&
						!isUpfront &&
						!!_installmentAmountProductId &&
						getInstallmentFlag)
				) {
					if (isCalCulateLoaders) {
						dispatch(addBusyIndicator(GET_CALCULATE_INFO));
					}
					InventoryService.getInstallmentAmount({
						mobilePhone: selectedAccount?.accountNum ?? '',
						creditRating: creditRating ?? '',
						accountNo: selectedAccount?.accountId?.toString() ?? '',
						emfConfigId: emfConfigId.POSTPAID,
						productId: _installmentAmountProductId,
						serviceCode: productInventory.sku,
						voucherAmount: '',
						installmentDuration: installmentPeriod,
						isDisabledBnplCr7985: DISABLE_BNPL_CR_ODF_7985,
					}).subscribe(
						(response) => {
							flag1 = true;
							setTimeout(() => {
								getInstallmentAmount(response, _product);
							}, 1000);

							dispatch(
								setSelectedItems({
									installmentAmount: response.price.comParamInstallmentValueBz,
									commitment: _installmentAmountProductId,
								})
							);
						},
						() => getCatEntryError(_product, productInventory)
					);
				} else {
					InventoryService.getCatEntry({ catEntryId: _product.id }).subscribe(
						(response) => getCatEntrySuccess(response, _product),
						() => getCatEntryError(_product, productInventory)
					);
				}
			}
			// TODO: Change url to proper sku id ???
			// setSelectedSKUId(_product.id);
			// window.history.replaceState({}, '', `Omantel/product/${_product.id}?${query}`);
		} else {
			dispatch(setSelectedItems({ details: undefined }));
		}
	};

	const sort = (a: IItem, b: IItem) => {
		const aAvailable = checkIItemAvailability(a, true);
		const bAvailable = checkIItemAvailability(b, true);
		if (aAvailable && !bAvailable) {
			return -1;
		} else if (!aAvailable && bAvailable) {
			return 1;
		}
		return 0;
	};

	return (
		<div>
			<Container fluid={isXsSmMd}>
				<ContentWrapper paddingLeft={isSmMd ? 16 : 0} paddingRight={isSmMd ? 16 : 0}>
					<BreadcrumbsItem to={url}>{productDetails?.name}</BreadcrumbsItem>
					<TopContentWrapper marginLeft={screen('xs') ? -16 : 0} marginRight={screen('xs') ? -16 : 0}>
						<Row nogutter={screen('xs')}>
							<Col lg={4} md={6}>
								<StickyWrapper>
									{selectedImage && (
										<Carousel
											enableModalPreview={true}
											itemsOnSlide={isXsSm ? 3 : 2}
											itemsToSlide={2}
											isLoading={imageLoader}
											images={[selectedImage]}
											// images={images} // TODO: after images will be configured propery
											availableColors={availableColors}
											pickedColor={selectedColor}
											backgroundTitle={productDetails?.name}
											showBigImage={!isXsSm}
											sliderArrowsEnabled={!isXsSm}
											enableModalPreviewOnSliderPhotoClick={isXsSm}
										/>
									)}
								</StickyWrapper>
							</Col>

							<Col lg={5} md={6}>
								<Configurator
									productDetails={productDetails}
									inStock={!!inStock}
									colorProps={{ availableColors, setSelectedColor, selectedColor }}
									capacityProps={{
										availableValues,
										setSelectedValue,
										selectedValue: selectedValueId,
									}}
									isLogged={isLogged}
									manufacturer={manufacturer}
									pricesWithVat={pricesWithVat}
									productAllSKU={productById?.items}
									selectedFilters={{
										selectedColorId,
										selectedValueId,
										installmentPeriod,
									}}
									availableSKU={getAvailableSku}
									getPlanType={setPlanType}
									planType={planType}
									setInstallmentPeriod={(installmentPeriod) => {
										setInstallmentPeriod(installmentPeriod);
									}}
									setIsUpfront={(value) => {
										setIsUpfront(value);
									}}
									isUpfront={isUpfront}
									installmentPeriod={installmentPeriod}
									isHbbInstallmentsOffer={isHbbInstallmentsOffer}
									productByIdIsLoading={productByIdIsLoading}
									planAvailable={productById?.item?.planAvailable}
									accessoryWithPlan={accessoryWithPlan}
									mobileDeviceWithPlan={mobileDeviceWithPlan}
									reverseProductOptionsVisbility={isAppleWatch}
									productById={productById}
									isTabletWithHBBWFBBPlan={isTabletWithHBBWFBBPlan}
									isAccessoryWithHBBWFBBPlan={isAccessoryWithHBBWFBBPlan}
									setSelectePlanId={setSelectePlanId}
									setIsBaqati={setIsBaqati}
									isCommitmentBaqatiConfig={isCommitmentBaqatiConfig}
									isCreditRatingConfig={isCreditRatingConfig}
									errorCodeForInstallmentAmount={errorCodeForInstallmentAmount}
								/>
							</Col>

							<Hidden xs sm md>
								<Col lg={3}>
									<StickyWrapper>
										<BusyIndicator
											listener={[
												GET_PRODUCT_DETAILS_BI,
												GET_CUSTOMER_PRODUCTS,
												GET_ACCOUNT_FOR_LOGGED_USER,
												GET_RECOMMENDED_PLAN,
												GET_PLANS,
												GET_COMPONENTS_FROM_INVENTORY_BI,
												GET_HIGHER_PLAN,
												CHECK_IF_CAN_GET_NEW_PLAN,
												VALIDATE_CHANGED_INSTALLMENT_SKUS,
												INFINITE_LOADING,
												SHOW_SUMMARY_INDICATOR,
												GET_BNPL_PRICES,
												GET_CALCULATE_INFO,
											]}
											skeleton="productCart"
										>
											<>
												<Card<types.cards.IProductCartCardProps>
													card="productCart"
													params={{
														installmentPeriod,
														outOfStock: !inStock,
														preorder: productById?.item.preorder,
														selectedFilters:
															selectedColorId && selectedValueId
																? {
																		selectedColorId,
																		selectedValueId,
																		installmentPeriod: isBuyNowPayLaterFlow
																			? installmentPeriod
																			: installmentPeriod || '24',
																  }
																: undefined,
														commitment: installmentAmountProductId,
														installmentAmount,
														installmentAmountId,
														salesLead:
															productDetails?.itemType === ItemType.SALES_LEAD_DEVICE,
														errorCodeForInstallmentAmount,
														bnplGetInstallmentAmount,
													}}
												/>
											</>
										</BusyIndicator>
									</StickyWrapper>
								</Col>
							</Hidden>
						</Row>
					</TopContentWrapper>
					<ConditionalRender
						show={showAppleRelatedContent && !ENABLE_ESHOP_LITE}
						onTrue={() => (
							<BusyIndicator listener={[GET_RELATED_PRODUCTS_BI]} skeleton="productCarousel">
								<ConditionalRender
									show={filteredRecommendedForYou.length > 0}
									onTrue={() => (
										<>
											<ProductBoxWrapper>
												<TitleSeparator
													title={translate('product.recommended.for.you')}
													typography={screen('xs') ? 'titleBold' : 'displayMBold'}
												/>
												<AppleRelatedProducts
													products={filteredRecommendedForYou}
													screenClass={screenClass}
													showInstalment={false}
													clearProductPage={clearProductPage}
												/>
											</ProductBoxWrapper>
										</>
									)}
								/>
							</BusyIndicator>
						)}
					/>

					<ConditionalRender
						show={keyFeatures?.length > 0}
						onTrue={() => (
							<ExpandableBox title={translate('product.key.features')} expandAvailable={isXsSmMd}>
								<KeyFeatures
									features={keyFeatures}
									dimensions={screen('xl') ? deviceDimensions : undefined}
								/>
							</ExpandableBox>
						)}
					/>
					<ConditionalRender
						show={whatsInTheBox?.length > 0}
						onTrue={() => (
							<ExpandableBox title={translate('product.whats.in.the.box')} expandAvailable={isXsSmMd}>
								<WhatsInTheBox items={whatsInTheBox} />
							</ExpandableBox>
						)}
					/>
					<ConditionalRender
						show={showAppleRelatedContent && !ENABLE_ESHOP_LITE}
						onTrue={() => (
							<ExpandableBox title={translate('product.explore.more')} expandAvailable={false}>
								<ExploreMore />
							</ExpandableBox>
						)}
					/>
					<ConditionalRender
						show={showAppleRelatedContent && isAppleHeroProduct && !ENABLE_ESHOP_LITE}
						onTrue={() => (
							<BusyIndicator listener={[GET_SEE_ALSO_BI]} skeleton="productCarousel">
								<ConditionalRender
									show={similarItems.length > 0}
									onTrue={() => (
										<>
											<ProductBoxWrapper>
												<TitleSeparator
													title={translate('product.similar.items')}
													typography={screen('xs') ? 'titleBold' : 'displayMBold'}
												/>
												<AppleRelatedProducts
													showInstalment={isAppleHeroProduct}
													products={similarItems.sort(sort)}
													screenClass={screenClass}
													clearProductPage={clearProductPage}
												/>
											</ProductBoxWrapper>
										</>
									)}
								/>
							</BusyIndicator>
						)}
					/>
					<ConditionalRender
						show={
							!showAppleRelatedContent &&
							relatedProducts?.filter((item) => !!item?.isRelated)?.length > 0 &&
							!ENABLE_ESHOP_LITE
						}
						onTrue={() => (
							<ProductBoxWrapper onClick={clearProductPage}>
								<TitleSeparator
									title={translate('product.related.products')}
									typography={screen('xs') ? 'titleBold' : 'displayMBold'}
								/>
								<RelatedProducts products={relatedProducts} screenClass={screenClass} />
							</ProductBoxWrapper>
						)}
					/>
					<ConditionalRender
						show={!!tradeInBanners?.banners?.find((item) => item?.img?.includes('/XL'))}
						onTrue={() => (
							<MarketingBanner
								banner={getBanner()}
								isTradeIn
								type={MarketingBannerType.HORIZONTAL_FULL_WIDTH}
								marginTop={44}
								marginBottom={16}
							/>
						)}
					/>
					<WhiteSpaceWrapper isSMMD={screen(['sm', 'md'])} isXS={screen(['xs'])}>
						<ConditionalRender
							show={!!productDetails}
							onTrue={() => (
								<ProductAdditionalInfoPanelWrapper
									customMargin={showAppleRelatedContent ? '56px 0px 0px' : '56px 0px 64px'}
								>
									<Col xs={12}>
										<ProductAdditionalInfoPanelContainer fluid={isXsSmMd}>
											<AdditionalInfoWrapper
												paddingLeft={isSmMd ? 16 : 0}
												paddingRight={isSmMd ? 16 : 0}
											>
												<ProductAdditionalInfoPanel
													data={{
														longDescription,
														technicalSpecification,
														isJawwyTv: selectedSKU?.name.toUpperCase().includes(JAWWY_TV),
													}}
												/>
											</AdditionalInfoWrapper>
										</ProductAdditionalInfoPanelContainer>
									</Col>
								</ProductAdditionalInfoPanelWrapper>
							)}
						/>
						<ConditionalRender
							show={showAppleRelatedContent}
							onTrue={() => (
								<ProductAdditionalInfoPanelWrapper customMargin="0px 0px 64px">
									<Col xs={12}>
										<ProductAdditionalInfoPanelContainer fluid={isXsSmMd}>
											<AdditionalInfoWrapper
												paddingLeft={isSmMd ? 16 : 0}
												paddingRight={isSmMd ? 16 : 0}
											>
												<AppleServices titleSeparator={true} />
											</AdditionalInfoWrapper>
										</ProductAdditionalInfoPanelContainer>
									</Col>
								</ProductAdditionalInfoPanelWrapper>
							)}
						/>
					</WhiteSpaceWrapper>
				</ContentWrapper>
			</Container>
		</div>
	);
};

export default ProductPage;
