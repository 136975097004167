import React, { FunctionComponent } from 'react';
import { Icons } from '@Assets/icons';
import theme from '@Utils/theme/theme';
import { IconContainer } from './Icon.styled';

interface IIconProps {
	name?: types.iconNames;
	width?: number | string;
	height?: number | string;
	fill?: keyof typeof theme.colors;
	stroke?: types.theme.themeColors;
	testID?: string;
	onClick?: (e?: any) => any;
	customFill?: string;
	justifycontent?: string;
}

const Icon: FunctionComponent<IIconProps> = (props) => {
	const { name, width = 20, height = 20, fill, testID, onClick, customFill, stroke, justifycontent } = props;
	const IconAsset = name && Icons[name] ? Icons[name].default : undefined;
	const fillColor = fill ? theme.colors[fill] : 'none';
	const strokeColor = stroke ? theme.colors[stroke] : 'none';

	return IconAsset ? (
		<IconContainer
			onClick={onClick}
			src={IconAsset}
			fill={fillColor}
			width={width}
			height={height}
			strokecolor={strokeColor}
			justifycontent={justifycontent}
		/>
	) : null;
};

export default Icon;
