import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Row, Container } from 'react-grid-system';
import Card from '@Components/Card/Card';
import Divider from '@Components/Divider/Divider';
import { IItem } from '@ApiModels/productsByCategoryId';
import { GET_COMPONENTS_FROM_INVENTORY_BI, GET_PRODUCT_DETAILS_BI } from '@Views/ProductPage/ProductPage';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { IAvailableValueItem } from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IManufacturer, IProductById, IProductByIdItem } from '@ApiModels/productById';
import { DisplayNoneWrapper, PlanWrapper } from './Configurator.styled';
import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import { GET_ACCOUNT_FOR_LOGGED_USER, PlanType } from '@Components/Card/cards/Plan/Plan';
import { ItemType } from '@Services/productsService/productsService';
export interface ISelectedFilters {
	selectedColorId?: string;
	selectedValueId?: string | null;
	installmentPeriod?: '12' | '24' | undefined;
}
interface IConfiguratorProps {
	productDetails?: IItem;
	colorProps?: IColorProps;
	capacityProps?: ICapacityProps;
	inStock: boolean;
	isLogged: boolean;
	manufacturer?: IManufacturer;
	pricesWithVat: IPricesWithVat;
	productAllSKU?: IProductByIdItem[];
	productById?: IProductById;
	availableSKU?: IProductByIdItem[];
	selectedFilters: ISelectedFilters;
	getPlanType: (planType: PlanType) => void;
	planType: PlanType;
	setInstallmentPeriod: (installmentPeriod?: '12' | '24') => void;
	setIsUpfront?: (value?: true | undefined | false) => void | undefined;
	isUpfront?: boolean;
	installmentPeriod?: '12' | '24';
	isHbbInstallmentsOffer: boolean;
	productByIdIsLoading: boolean;
	planAvailable?: boolean;
	accessoryWithPlan: boolean;
	mobileDeviceWithPlan: boolean;
	reverseProductOptionsVisbility: boolean;
	isTabletWithHBBWFBBPlan: boolean;
	isAccessoryWithHBBWFBBPlan: boolean;
	setSelectePlanId: (selectedPlanId: string) => void;
	setIsBaqati: (isBaqati: boolean) => void;
	isCommitmentBaqatiConfig: boolean;
	isCreditRatingConfig: boolean;
	errorCodeForInstallmentAmount?: string | undefined;
}

export interface IColorProps {
	availableColors: IAvailableColorWithId[];
	setSelectedColor: (color: string | null) => void;
	selectedColor: string | null;
}

export interface ICapacityProps {
	availableValues: IAvailableValueItem[];
	setSelectedValue: (capacity: string | null) => void;
	selectedValue: string | null;
}

const Configurator: FunctionComponent<IConfiguratorProps> = ({
	productDetails,
	colorProps,
	capacityProps,
	inStock,
	isLogged,
	manufacturer,
	pricesWithVat,
	productAllSKU,
	availableSKU,
	selectedFilters,
	getPlanType,
	planType,
	setInstallmentPeriod,
	isUpfront,
	setIsUpfront,
	installmentPeriod,
	isHbbInstallmentsOffer,
	planAvailable,
	accessoryWithPlan,
	reverseProductOptionsVisbility,
	productById,
	mobileDeviceWithPlan,
	isTabletWithHBBWFBBPlan,
	isAccessoryWithHBBWFBBPlan,
	setSelectePlanId,
	setIsBaqati,
	isCommitmentBaqatiConfig,
	isCreditRatingConfig,
	errorCodeForInstallmentAmount,
}) => {
	const showWarning = useSelector((state: types.redux.IState) => state.selectedItems?.showWarning);
	const isCorporateUser = useSelector((state: types.redux.IState) => state.selectedItems?.isCorporateUser);
	const selectedCommitmentPeriod = useSelector(
		(state: types.redux.IState) => state.selectedItems?.selectedCommitmentPeriod
	);
	const isEbuDocumentType = useSelector((state: types.redux.IState) => state.selectedItems?.isEbuDocumentType);

	return (
		<Container fluid>
			<Row>
				<BusyIndicator
					listener={[GET_PRODUCT_DETAILS_BI, GET_COMPONENTS_FROM_INVENTORY_BI]}
					skeleton="productConfiguration"
				>
					<>
						<Card<types.cards.IProductConfiguratorCardProps>
							card="productConfigurator"
							params={{
								favourite: true,
								withPlan: installmentPeriod === '12' || installmentPeriod === '24',
								userReviews: 3,
								manufacturer,
								productDetails,
								onlyOnline: false,
								inStock,
								notifyMe: false, // Hidden on P1
								colorProps,
								capacityProps,
								reverseProductOptionsVisbility,
								productById,
							}}
						/>
						<ConditionalRender
							show={!!productDetails?.pickupOrderAvailable}
							onTrue={() => <Card card="pickupOrder" withoutContainerPadding marginTop={16} />}
						/>
					</>
				</BusyIndicator>
			</Row>

			<Row>
				<BusyIndicator listener={GET_PRODUCT_DETAILS_BI} skeleton="shortDescription">
					<>
						{!!productDetails && productDetails?.shortDescription?.length > 0 && (
							<Card<types.cards.IDescriptionCardProps>
								card="description"
								params={{
									description: productDetails.shortDescription,
								}}
								marginTop={16}
							/>
						)}
					</>
				</BusyIndicator>
			</Row>
			<Row>
				<ConditionalRender
					show={!isLogged}
					onTrue={() => (
						<BusyIndicator listener={[GET_PRODUCT_DETAILS_BI]} skeleton="loginPrompt">
							<Card<types.cards.ILoginPromptCardProps> card="loginPrompt" marginTop={16} />
						</BusyIndicator>
					)}
				/>
			</Row>
			<Row>
				<BusyIndicator listener={[GET_ACCOUNT_FOR_LOGGED_USER]} skeleton="segmentSelection">
					<>
						<ConditionalRender
							show={!productDetails?.isGiftCard && !productDetails?.isDigitalProduct && !!planAvailable}
							onTrue={() => (
								<>
									{selectedFilters && (
										<PlanWrapper>
											<Card<types.cards.IPlanCardProps>
												card="plan"
												params={{
													isLogged,
													productAllSKU,
													selectedFilters,
													getPlanType,
													setInstallmentPeriod,
													pricesWithVat,
													isUpfront,
													setIsUpfront,
													isHbbInstallmentsOffer,
													accessoryWithPlan,
													mobileDeviceWithPlan,
													isTabletWithHBBWFBBPlan,
													isAccessoryWithHBBWFBBPlan,
													isMobileDevice: !!(productDetails?.allItemType || []).includes(
														ItemType.ITEM_MOBILE
													),
													setSelectePlanId,
													setIsBaqati,
													isCommitmentBaqatiConfig,
													installmentPeriod,
													isCreditRatingConfig,
													errorCodeForInstallmentAmount,
												}}
												marginTop={16}
												withoutContainerPadding={!inStock}
												overflow="visible"
											/>
										</PlanWrapper>
									)}
								</>
							)}
						/>
						<ConditionalRender
							show={!!productDetails && productDetails.itemType === ItemType.SALES_LEAD_DEVICE}
							onTrue={() => (
								<PlanWrapper>
									<Card<types.cards.IPlanCardProps>
										card="salesLeadForm"
										params={{
											isLogged,
											productAllSKU,
											selectedFilters,
											getPlanType,
											setInstallmentPeriod,
											pricesWithVat,
											isHbbInstallmentsOffer,
											accessoryWithPlan,
											mobileDeviceWithPlan,
											isTabletWithHBBWFBBPlan,
											isAccessoryWithHBBWFBBPlan,
											isMobileDevice: !!(productDetails?.allItemType || []).includes(
												ItemType.ITEM_MOBILE
											),
											setSelectePlanId,
											setIsBaqati,
											isCommitmentBaqatiConfig,
											productDetails,
											isUpfront,
											setIsUpfront,
										}}
										marginTop={16}
										withoutContainerPadding={!inStock}
										overflow="visible"
									/>
								</PlanWrapper>
							)}
						/>
					</>
				</BusyIndicator>
			</Row>
			<DisplayNoneWrapper isEbu={isEbuDocumentType && !isCorporateUser}>
				{!!productDetails &&
					inStock &&
					!showWarning &&
					(!isCorporateUser || planType === PlanType.DEVICE_ONLY || isEbuDocumentType) && (
						<Row>
							<Card<types.cards.IPaymentSummaryCardProps>
								card="paymentSummary"
								params={{
									isDevice: !productDetails.isGiftCard && !productDetails.isDigitalProduct,
									regularPrice: productDetails.price,
									showAvailablePaymentMethods: !productDetails.isGiftCard && false, // payment method selector is disabled, see: MINT-6075
									discountWithMakasib: 110,
									link: '/product',
									isLogged,
									pricesWithVat,
									availableSKU,
									productAllSKU,
									planType,
									setInstallmentPeriod,
									setIsUpfront,
									installmentPeriod,
									isHbbInstallmentsOffer,
								}}
								marginTop={16}
							/>
						</Row>
					)}
			</DisplayNoneWrapper>
			{!!productDetails &&
				inStock &&
				planType === PlanType.WITH_PLAN &&
				isCorporateUser &&
				selectedCommitmentPeriod && (
					<Row>
						<Card<types.cards.IBusinessPaymentSummaryCardProps>
							card="businessPaymentSummary"
							params={{
								isDevice: !productDetails?.isGiftCard && !productDetails?.isDigitalProduct,
								planType,
							}}
							marginTop={16}
							overflow="visible"
						/>
					</Row>
				)}
			<Divider marginBottom={16} withoutLine />
		</Container>
	);
};
export default Configurator;
