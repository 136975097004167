import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import {
	PrimaryLargeButtonFixed,
	SecondaryLargeButtonFixed,
	SecondaryLargeButtonFlex,
} from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodyM, BodyMBold, Header, HeadlineBold, Title, TitleBold } from '@Components/Typography/Typography.styled';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import useTranslate from '@Utils/hooks/useTranslate';
import { useHistory, useLocation } from 'react-router-dom';
import {
	ButtonContent,
	CheckoutSummaryCardContainer,
	GiftCardContainer,
	HorizontalContainer,
	OrderLink,
	OrderNumberWrapper,
	MessageContainer,
	MicrosoftCodeContainer,
	CopyClipboardButton,
	EmailLabelWrapper,
	MicrosoftInfoWrapper,
	ButtonWrapper,
	SoftwareCodeWrapper,
	LoaderWrapper,
	ErrorLabelWrapper,
	ColumnLabelWrapper,
	CheckoutSummaryParagraph,
	MicrosoftAutomationInfoWrapper,
	TenantDasboardLink,
} from './CheckoutSummary.styled';
import { CLEAR_CHECKOUT_TIMEOUT, INSURANCE_REQUEST_TYPE } from '@Config/app.config';
import { dispatch } from '@Redux/store';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IGiftCard } from '@ApiModels/userGiftCards';
import { ProductsService } from '@Services/productsService/productsService';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import GiftCardCodeCopyToClipboard from '@Components/GiftCardCode/GiftCardCode';
import { ProfileService } from '@Services/profile/profileService';
import { setCheckoutTimer } from '@Redux/modules/settings/settingsCheckout/actions';
import { getLang } from '@Utils/language/language';
import { clearPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { SET_RETRY_ORDER_BI } from '@Layouts/Payment/Payment';
import { copyToClipboard } from '@Utils/copyToClipboard/copyToClipboard';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IMSKey } from '@ApiModels/msKeysList';
import useScreen from '@Utils/hooks/useScreen';
import { AccountsService } from '@Services/accounts/accountsService';
import { IAccount } from '@ApiModels/accounts';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { MakasibService } from '@Services/makasib/makasib';
import { setPersonalData } from '@Redux/modules/api/personalData/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_ORDER_HISTORY_DETAILS_BI } from '@Components/Card/cards/OrderHistory/OrderItem/OrderItem';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';

export enum CheckoutSummaryType {
	SUCCESS = 'SUCCESS',
	REJECTED = 'REJECTED',
}

export const MS_PRODUCT_QUERY_PARAM = 'digitalproducts';

const MICROSOFT_PAGE = 'https://www.office.com/setup365';

const MICROSOFT_ADMIN_PAGE = 'https://admin.microsoft.com/Adminportal/Home';
const GET_ACCOUNT_BI = 'getCurrentAccount';
const MAKASIB_BI = 'getMakasibAccount';

const CheckoutSummary: FunctionComponent<types.cards.ICheckoutSummaryCardProps> = ({
	emmitEvent,
	params: { type, orderNumber, tryAgain = () => {}, showTryAgainButton, order, hbbWfbbProcess, requestNumber },
}): JSX.Element => {
	const contactDetails = useSelector((state: types.redux.IState) => state.checkout.contactDetails);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);
	let msCspProductName = '';
	let msCspTenantEmail = '';
	let isMsCspInCart = false;
	if (order?.orderItem) {
		isMsCspInCart = order?.orderItem.some((item) => {
			const field2 = JSON.parse(item.xitem_field2 || '{}');
			msCspProductName = item.xitem_name ?? '';
			if (msCspProductName.includes('Microsoft 365') && msCspProductName.includes('Business')) {
				msCspTenantEmail = field2.msCspTenantEmail ?? contactDetails?.email;
				return !!msCspTenantEmail && !!msCspProductName;
			}
			return false;
		});
	}
	const [timeoutReached, setTimeoutReached] = useState<boolean>(false);
	const [pickupDelivery, setPickupDelivery] = useState(false);
	const checkoutTimer = useSelector((state: types.redux.IState) => state.settings.settingsCheckout.checkoutTimer);
	const isGuest = useSelector((state: types.redux.IState) => state.api.authentication.loginType === 'guest');
	const [msKeys, setMsKeys] = useState<IMSKey[]>([]);
	const [tierName, setTierName] = useState('');
	const [msLoader, setMsLoader] = useState<boolean>(true);
	const [msError, setMsError] = useState<boolean>(false);
	const [currentAccount, setCurrentAccount] = useState<IAccount | undefined>(undefined);
	const { translate } = useTranslate();
	const history = useHistory();
	const ICON_SIZE = 42;
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const preorder = query.get('preorder');
	const isGiftCard = query.get('giftcards') === 'true';
	const isMSProduct = query.get(MS_PRODUCT_QUERY_PARAM) === 'true';
	const [giftCards, setGiftCards] = useState<IGiftCard[]>([]);
	const [giftCardsLoading, setGiftCardsLoading] = useState<boolean>(false);
	const [launchDate, setLaunchDate] = useState('');
	const { screen } = useScreen();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ACCOUNT_BI));
		AccountsService.getAccountsForLoggedUser().subscribe(
			({ accounts }) => {
				setCurrentAccount(accounts.find((acc) => acc.isMain));
				dispatch(removeBusyIndicator(GET_ACCOUNT_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_ACCOUNT_BI));
			}
		);
	}, []);

	useEffect(() => {
		if (currentAccount) {
			if (currentAccount.accountNum) {
				dispatch(addBusyIndicator(MAKASIB_BI));
				setTimeout(
					() =>
						MakasibService.getLoyaltyAccountOperation(
							currentAccount.accountNum ? currentAccount.accountNum : ''
						).subscribe(
							({ rewardAmount, tier, pointsToExpire }) => {
								dispatch(removeBusyIndicator(MAKASIB_BI));
								dispatch(
									setPersonalData({
										makasibPoints: !!rewardAmount ? rewardAmount : '0',
										expiryMakasibPoints: !!pointsToExpire ? pointsToExpire : '0',
									})
								);

								if (tier) {
									setTierName(tier.name);
								}
							},
							() => {
								dispatch(removeBusyIndicator(MAKASIB_BI));
							}
						),
					3000
				);
			}
		}
	}, [currentAccount]);

	useEffect(() => {
		if (order?.orderItem?.[0]) {
			setPickupDelivery(!!order.orderItem.find((item) => item.shipModeCode === 'Pickup'));
		}
	}, [order]);

	const otherThanInsuranceItems = useMemo(() => {
		return !!order?.orderItem?.find((cartItem) => {
			const values = JSON.parse(cartItem.xitem_field2 || '');
			return !INSURANCE_REQUEST_TYPE.includes(values.requestType);
		});
	}, [order]);

	useEffect(() => {
		if (pickupDelivery) {
			const values = JSON.parse(
				order?.orderItem?.find((item) => item.shipModeCode === 'Pickup')?.xitem_field2 ?? ''
			);

			if (values?.launchDate) {
				setLaunchDate(moment(values.launchDate).format('DD MMM YYYY'));
			}
		}
	}, [pickupDelivery]);

	const error = () => {
		return query.get('error');
	};

	const goBackToStore = (): void => {
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
		history.push(`/${getLang()}/store`);
	};
	const tryAgainAction = (): void => {
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
		if (emmitEvent) {
			emmitEvent('setButtonVisibility');
		}
		tryAgain();
	};

	const seeOrderDetails = (): void => {
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
		if (orderNumber !== 'null') {
			if (isGuest) {
				history.push(`/${getLang()}/guest/orders?orderId=${orderNumber}`);
			} else {
				history.push(`/${getLang()}/account/orders/inprogress?orderId=${orderNumber}`);
			}
		} else {
			history.push(`/${getLang()}/account/orders/inprogress`);
		}
	};

	const seeOrderTracking = (): void => {
		window.open(order?.x_shipmentTrackUrl, '_blank');
	};

	const getUniqueGiftCardsProductsId = (gc: IGiftCard[]): string[] => {
		const uniqueIds: string[] = [];
		gc?.forEach((gc) => {
			if (uniqueIds.indexOf(gc?.productId as string) === -1) uniqueIds.push(gc?.productId as string);
		});
		return uniqueIds;
	};

	const getGiftCardDescription = (gc: IGiftCard) => {
		if (gc.productId) {
			ProductsService.getProductsByPartNumber(gc.productId).subscribe(
				(r) => {
					const name: string[] = [];
					name.push(r.name);
					name.push(' '.concat(r.giftCardValue ?? ''));
					name.push(' '.concat(r.country ?? ''));
					gc.name = name.join();
					setGiftCardsLoading(false);
				},
				() => {
					setGiftCardsLoading(false);
				}
			);
		} else {
			setGiftCardsLoading(false);
		}
	};

	useEffect(() => {
		if (isGiftCard) {
			setGiftCardsLoading(true);
			setGiftCards([]);
			ProfileService.getUserGiftCards().subscribe(
				(gcards) => {
					if (gcards && gcards.userGiftCards) {
						gcards.userGiftCards.forEach((gc) => {
							getGiftCardDescription(gc);
						});
						setGiftCards(gcards.userGiftCards);
					} else {
						setGiftCards([]);
						setGiftCardsLoading(false);
					}
				},
				() => {
					setGiftCards([]);
					setGiftCardsLoading(false);
				}
			);
		}
	}, []);

	const getErrorMessage = (field: 'description' | 'title') => {
		if (!!error()) {
			return `checkout-summary.${error()}.${field}`;
		}
		return `checkout-summary.something.went.wrong.${field}`;
	};

	useEffect(() => {
		if (type === CheckoutSummaryType.REJECTED && !!checkoutTimer) {
			const _interval = setInterval(() => {
				if (+checkoutTimer + +CLEAR_CHECKOUT_TIMEOUT / 1000 <= moment().unix()) {
					dispatch(clearCheckoutDetails());
					dispatch(clearPersistentCheckoutDetails());
					setTimeoutReached(true);
					clearInterval(_interval);
				}
			}, 1000);
		}
	}, []);

	useEffect(() => {
		if (
			checkoutTimer &&
			type === CheckoutSummaryType.REJECTED &&
			+checkoutTimer + +CLEAR_CHECKOUT_TIMEOUT / 1000 <= moment().unix()
		) {
			setTimeoutReached(true);
		}
	}, []);

	useEffect(() => {
		if (isMSProduct && type === CheckoutSummaryType.SUCCESS && !isMsCspInCart) {
			setTimeout(
				() =>
					OrderFlowService.getOrderMSKeys(orderNumber ?? '').subscribe(
						(r) => {
							setMsError(
								r.returnValue.some((item) => item.activateKey === 'ACTINATION_KEY_GENERATED_ERROR')
							);
							setMsKeys(r.returnValue);
							setMsLoader(false);
						},
						(r) => {
							setMsError(true);
							setMsLoader(false);
						}
					),
				80000
			);
		}
	}, [order?.orderItem]);

	return (
		<CheckoutSummaryCardContainer>
			<ConditionalRender
				show={type === CheckoutSummaryType.SUCCESS}
				onTrue={() => (
					<>
						<Icon height={ICON_SIZE} width={ICON_SIZE} name="success" />
						<Divider color="transparent" marginBottom={16} />
						<Title color="positive">{translate('checkout-summary.success.payment.confirmed')}</Title>

						<Divider color="transparent" marginBottom={16} />
						<HorizontalContainer>
							<ConditionalRender
								show={orderNumber !== 'null' && !requestNumber}
								onTrue={() => (
									<>
										<BodyM color="black87">
											{translate('checkout-summary.success.payment.completed-part-1')}
										</BodyM>
										<ConditionalRender
											show={isUserSignedIn() || isGuest}
											onTrue={() => (
												<OrderLink onClick={seeOrderDetails}>{orderNumber}</OrderLink>
											)}
											onFalse={() => <OrderNumberWrapper> {orderNumber} </OrderNumberWrapper>}
										/>
										<BodyM color="black87">
											{translate('checkout-summary.success.payment.completed-part-2')}
										</BodyM>
									</>
								)}
								onFalse={() => (
									<BodyM color="black87">
										{translate('checkout-summary.success.payment.completed')}
									</BodyM>
								)}
							/>
						</HorizontalContainer>
						<ConditionalRender
							show={isMsCspInCart && type === CheckoutSummaryType.SUCCESS}
							onTrue={() => (
								<>
									<MicrosoftAutomationInfoWrapper>
										<ConditionalRender
											show={!!msCspTenantEmail}
											onTrue={() => (
												<CheckoutSummaryParagraph>
													{translate(
														'microsoft-automation-proccess.checkout-summary-info.part-1',
														msCspTenantEmail
													)}
												</CheckoutSummaryParagraph>
											)}
										/>
										<CheckoutSummaryParagraph>
											{translate(
												'microsoft-automation-proccess.checkout-summary-info.part-2',
												msCspProductName,
												!!msCspTenantEmail
													? translate(
															'microsoft-automation-proccess.checkout-summary-info.part-3'
													  )
													: translate(
															'microsoft-automation-proccess.checkout-summary-info.part-4'
													  )
											)}
										</CheckoutSummaryParagraph>
										<CheckoutSummaryParagraph>
											{translate('microsoft-automation-proccess.checkout-summary-info.part-5')}
											<TenantDasboardLink href={MICROSOFT_ADMIN_PAGE} target="_blank">
												{translate('microsoft-automation-proccess.checkout-summary-info.here')}
											</TenantDasboardLink>
											.
										</CheckoutSummaryParagraph>
									</MicrosoftAutomationInfoWrapper>
								</>
							)}
						/>

						<ConditionalRender
							show={isMSProduct && type === CheckoutSummaryType.SUCCESS && !isMsCspInCart}
							onTrue={() => (
								<BusyIndicator
									listener={GET_ORDER_HISTORY_DETAILS_BI}
									skeletonProps={{ marginTop: 10 }}
									skeleton="circularLoader"
								>
									<>
										<Divider marginTop={40} marginBottom={40} />
										<ConditionalRender
											show={msLoader}
											onTrue={() => (
												<>
													<BodyM>{translate('microsoft.please-wait')}</BodyM>
													<LoaderWrapper>
														<PrimaryCircularLoader />
													</LoaderWrapper>
												</>
											)}
											onFalse={() => (
												<>
													{msKeys.map((item) => (
														<>
															<ConditionalRender
																show={
																	item.activateKey !==
																	'ACTINATION_KEY_GENERATED_ERROR'
																}
																onTrue={() => (
																	<>
																		<SoftwareCodeWrapper
																			isXs={screen('xs')}
																			isSm={screen('sm')}
																		>
																			<BodyMBold>{`\xa0${translate(
																				'checkout-summary.microsoft.software-code'
																			)}\xa0`}</BodyMBold>
																			<BodyM>{`${item.name}, ${translate(
																				'microsoft.modal.subscription',
																				item.msDesc.toLowerCase()
																			)}`}</BodyM>
																		</SoftwareCodeWrapper>
																		<MicrosoftCodeContainer
																			isXs={screen('xs')}
																			isSm={screen('sm')}
																		>
																			<ConditionalRender
																				show={screen('xs') || screen('sm')}
																				onTrue={() => (
																					<TitleBold>
																						{item.activateKey}
																					</TitleBold>
																				)}
																				onFalse={() => (
																					<HeadlineBold>
																						{item.activateKey}
																					</HeadlineBold>
																				)}
																			/>
																			<CopyClipboardButton
																				onClick={() =>
																					copyToClipboard(
																						item.activateKey.toString()
																					)
																				}
																			>
																				{translate('copy.to.clipboard')}
																			</CopyClipboardButton>
																		</MicrosoftCodeContainer>
																	</>
																)}
															/>
														</>
													))}
													<ConditionalRender
														show={msError}
														onTrue={() => (
															<ErrorLabelWrapper>
																<ColumnLabelWrapper>
																	<BodyMBold color="warning">
																		{translate('microsoft.error.1')}
																	</BodyMBold>
																</ColumnLabelWrapper>
																<ColumnLabelWrapper>
																	<BodyM>{translate('microsoft.error.2')}</BodyM>
																	<BodyMBold>
																		{order?.orderItem?.[0]?.email1 ?? ''}
																	</BodyMBold>
																</ColumnLabelWrapper>
																<ColumnLabelWrapper>
																	<BodyM>
																		{translate(
																			'microsoft.error.3',
																			isB2b2c || isB2b
																				? CUSTOMER_B2B2C_CARE_PHONE
																				: CUSTOMER_CARE_PHONE
																		)}
																	</BodyM>
																</ColumnLabelWrapper>
															</ErrorLabelWrapper>
														)}
													/>
												</>
											)}
										/>
										<EmailLabelWrapper>
											<ConditionalRender
												show={!msError}
												onTrue={() => <BodyM>{translate('microsoft.modal.email-info')}</BodyM>}
											/>
											<BodyMBold>{`\xa0${order?.orderItem?.[0]?.email1 ?? ''}\xa0`}</BodyMBold>
										</EmailLabelWrapper>
									</>
								</BusyIndicator>
							)}
						/>

						{preorder && !pickupDelivery && <Divider color="transparent" marginBottom={16} />}
						<ConditionalRender
							show={!isMSProduct}
							onTrue={() => (
								<BodyM color="black87">
									{preorder && !pickupDelivery
										? translate('checkout-summary.success.payment.completed.preorder', preorder)
										: translate('checkout-summary.success.payment.completed.thank.you')}
								</BodyM>
							)}
						/>
						<ConditionalRender
							show={!!orderNumber && !!requestNumber}
							onTrue={() => (
								<>
									<Divider withoutLine marginBottom={16} />
									<HorizontalContainer>
										<BodyM color="black87">
											{translate('checkout-summary.success.payment.track-order.part-1')}
										</BodyM>
										<ConditionalRender
											show={isUserSignedIn() || isGuest}
											onTrue={() => (
												<OrderLink onClick={seeOrderDetails}>{orderNumber}</OrderLink>
											)}
											onFalse={() => <OrderNumberWrapper> {orderNumber} </OrderNumberWrapper>}
										/>
									</HorizontalContainer>
								</>
							)}
						/>
						<ConditionalRender
							show={!!requestNumber}
							onTrue={() => (
								<>
									<Divider withoutLine marginBottom={16} />
									<HorizontalContainer>
										<BodyM color="black87">
											{translate(
												'checkout-summary.success.payment.internet-request',
												requestNumber || ''
											)}
										</BodyM>
									</HorizontalContainer>
								</>
							)}
						/>
						<ConditionalRender
							show={pickupDelivery}
							onTrue={() => (
								<MessageContainer>
									<span>
										<BodyM color="black87">{translate('payment-success.pickup')}</BodyM>
										<OrderNumberWrapper>{launchDate}</OrderNumberWrapper>
									</span>
									<BodyM color="black87">{translate('payment-success.pickup-2')}</BodyM>
									<BodyM color="black87">{translate('payment-success.pickup-3')}</BodyM>
								</MessageContainer>
							)}
						/>
						<ConditionalRender
							show={giftCardsLoading}
							onTrue={() => (
								<>
									<Divider marginTop={40} withoutLine />
									<PrimaryCircularLoader />
								</>
							)}
						/>
						<ConditionalRender
							show={!giftCardsLoading && giftCards.length > 0}
							onTrue={() => (
								<GiftCardContainer>
									<Divider color="black12" marginBottom={40} marginTop={40} />
									{getUniqueGiftCardsProductsId(giftCards).map((giftCardId) =>
										giftCards
											.filter((filteredGiftCard) => filteredGiftCard.productId === giftCardId)
											.map((mappedGiftCard, index) => (
												<>
													<HorizontalContainer>
														<Header color="black87">{translate('gift.card.code')}</Header>
														<BodyM>
															{giftCards.find((o) => o.productId === giftCardId)?.name}
														</BodyM>
													</HorizontalContainer>
													<Divider marginBottom={16} withoutLine />
													<GiftCardCodeCopyToClipboard
														giftCard={mappedGiftCard}
														key={index}
													/>
													<Divider marginBottom={24} withoutLine />
												</>
											))
									)}
									<Divider marginBottom={16} withoutLine />
									<ConditionalRender
										show={!!order}
										onTrue={() => (
											<>
												<BodyM>{translate('gift.card.code.mail')}</BodyM>
												<BodyMBold>
													{!!order?.orderItem && order?.orderItem?.length > 0
														? order?.orderItem[0].email1
														: '-'}
												</BodyMBold>
											</>
										)}
									/>
									<Divider marginBottom={24} withoutLine />
								</GiftCardContainer>
							)}
						/>
						<Divider color="transparent" marginBottom={16} />
						<ConditionalRender
							show={isUserSignedIn() || isGuest}
							onTrue={() => (
								<>
									<HorizontalContainer>
										<ConditionalRender
											show={
												!!order?.x_shipmentNumber &&
												!pickupDelivery &&
												otherThanInsuranceItems &&
												!isMSProduct
											}
											onTrue={() => (
												<>
													<BodyM color="black87">
														{translate('checkout-summary.success.payment.track.order')}
													</BodyM>
													<OrderLink onClick={seeOrderTracking}>
														{order?.x_shipmentNumber ?? '-'}
													</OrderLink>
												</>
											)}
										/>
									</HorizontalContainer>
									<Divider color="transparent" marginBottom={40} />
									<ButtonContent>
										<PrimaryLargeButtonFixed
											uniqueId="success.payment.order.details"
											onClick={seeOrderDetails}
											listener={[SET_RETRY_ORDER_BI]}
										>
											{translate('checkout-summary.success.payment.order.details')}
										</PrimaryLargeButtonFixed>
									</ButtonContent>
								</>
							)}
						/>
						<Divider color="transparent" marginBottom={16} />
						<ButtonContent>
							<SecondaryLargeButtonFixed uniqueId="success.payment.back.to.store" onClick={goBackToStore}>
								{translate('checkout-summary.success.payment.back.to.store')}
							</SecondaryLargeButtonFixed>
						</ButtonContent>
					</>
				)}
			/>
			<ConditionalRender
				show={isMSProduct && type === CheckoutSummaryType.SUCCESS && !isMsCspInCart}
				onTrue={() => (
					<BusyIndicator
						listener={GET_ORDER_HISTORY_DETAILS_BI}
						skeletonProps={{ marginTop: 10 }}
						skeleton="circularLoader"
					>
						<>
							<Divider marginTop={40} marginBottom={40} />
							<MicrosoftInfoWrapper>
								<BodyM>{translate('microsoft.visit-page.info.first')}</BodyM>
								<BodyM>{translate('microsoft.visit-page.info.second')}</BodyM>
								<ButtonWrapper>
									<SecondaryLargeButtonFlex
										uniqueId="microsoftCardDetailsVisitPage"
										onClick={() => (window.location.href = MICROSOFT_PAGE)}
									>
										{translate('microsoft.visit-page.button')}
									</SecondaryLargeButtonFlex>
								</ButtonWrapper>
							</MicrosoftInfoWrapper>
						</>
					</BusyIndicator>
				)}
			/>

			<ConditionalRender
				show={type === CheckoutSummaryType.REJECTED}
				onTrue={() => (
					<>
						<Icon height={ICON_SIZE} width={ICON_SIZE} fill="warning" name="error" />
						<Divider color="transparent" marginBottom={16} />
						<Title color="warning">
							{hbbWfbbProcess
								? translate('checkout.summary.something.went.wrong')
								: translate(
										timeoutReached
											? 'checkout-summary.timeout.reached'
											: 'checkout-summary.rejected'
								  )}
						</Title>
						<Divider color="transparent" marginBottom={16} />
						<BodyM color="black87">
							{hbbWfbbProcess
								? translate('checkout.summary.problem.with.request')
								: translate(
										timeoutReached
											? 'checkout-summary.timeout.reached.title'
											: getErrorMessage('title')
								  )}
						</BodyM>
						{timeoutReached && <Divider withoutLine marginTop={16} />}
						<BodyM color="black87">
							{translate(
								timeoutReached
									? 'checkout-summary.timeout.reached.description'
									: getErrorMessage('description')
							)}
						</BodyM>
						{showTryAgainButton ? (
							<>
								<Divider color="transparent" marginBottom={40} />
								<ButtonContent>
									<ConditionalRender
										show={timeoutReached}
										onTrue={() => (
											<SecondaryLargeButtonFixed
												uniqueId="checkout-summary.go.back.to.store"
												onClick={goBackToStore}
											>
												{translate('checkout-summary.go.back.to.store')}
											</SecondaryLargeButtonFixed>
										)}
										onFalse={() => (
											<PrimaryLargeButtonFixed
												uniqueId="checkout-summary.try.again"
												listener={[SET_RETRY_ORDER_BI]}
												onClick={tryAgainAction}
											>
												{translate('checkout-summary.try.again')}
											</PrimaryLargeButtonFixed>
										)}
									/>
								</ButtonContent>
							</>
						) : (
							''
						)}
					</>
				)}
			/>
		</CheckoutSummaryCardContainer>
	);
};

export default CheckoutSummary;
