import React from 'react';
import { RadioButtonWrapper, Label, Input, Mark, LabelSelected } from './RadioButton.styled';

export interface IRadioButtonProps<T> {
	label: string;
	selected: T;
	name?: string;
	disabled?: boolean;
	onClick: (val: T) => void;
	value: T;
	marginRight?: number;
	marginBottom?: number;
	fontColor?: types.theme.themeColors;
	color?: types.theme.themeColors;
}

function RadioButton<T = string | number>({
	label,
	selected,
	disabled = false,
	onClick,
	name,
	value,
	marginRight = 0,
	marginBottom = 0,
	fontColor,
	color,
}: IRadioButtonProps<T>): JSX.Element {
	const handleOnClick = () => {
		if (!disabled && selected !== value) {
			onClick(value);
		}
	};

	const LabelWrapper = selected === value ? LabelSelected : Label;

	return (
		<RadioButtonWrapper onClick={handleOnClick} marginRight={marginRight} marginBottom={marginBottom}>
			<LabelWrapper fontColor={fontColor}>
				<Input
					onChange={handleOnClick}
					name={name}
					type="radio"
					checked={selected === value}
					value={String(value)}
				/>
				<Mark selected={selected === value} disabled={disabled} color={color} />
				{label}
			</LabelWrapper>
		</RadioButtonWrapper>
	);
}

export default RadioButton;
