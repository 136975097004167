import { combineReducers } from 'redux';
import language from './modules/settings/language/reducer';
import layout from './modules/settings/layout/reducer';
import apiCache from './modules/api/cache/reducer';
import apiRequestsInProgress from './modules/api/requestsInProgress/reducer';
import toast from './modules/toast/reducer';
import headers from './modules/api/headers/reducer';
import authentication from './modules/api/authentication/reducer';
import personalData from './modules/api/personalData/reducer';
import busyIndicator from './modules/busyIndicator/reducer';
import sortingMethod from './modules/sortingMethod/reducer';
import viewType from './modules/settings/viewType/reducer';
import cart from './modules/cart/reducer';
import filters from './modules/filters/reducer';
import comparisonList from './modules/comparison/reducer';
import modal from './modules/modal/reducer';
import priceFilter from './modules/priceFilter/reducer';
import breadcrumbs from './modules/breadcrumbs/reducer';
import selectedItems from './modules/selectedItems/reducer';
import configurator from './modules/configurator/reducer';
import checkout from './modules/checkout/reducer';
import persistentCheckout from './modules/persistentCheckoutData/reducer';
import settingsCheckout from './modules/settings/settingsCheckout/reducer';
import paymentTokens from './modules/paymentTokens/reducer';
import addressBook from './modules/addressBook/reducer';
import config from './modules/settings/config/reducer';
import timer from './modules/timer/reducer';
import telesales from './modules/telesales/reducer';
import orderHistory from './modules/orderHistory/reducer';
import makasibPoints from './modules/makasibPoints/reducer';
import buyNowPayLater from './modules/buyNowPayLater/reducer';

import remoteConfig from './modules/settings/remoteConfig/reducer';

export const RootReducer: any = combineReducers({
	api: combineReducers({
		authentication,
		cache: apiCache,
		headers,
		requestsInProgress: apiRequestsInProgress,
		personalData,
	}),
	cart,
	toast,
	modal,
	settings: combineReducers({
		language,
		layout,
		viewType,
		settingsCheckout,
		config,
		remoteConfig,
	}),
	breadcrumbs,
	busyIndicator,
	sortingMethod,
	filters,
	comparisonList,
	priceFilter,
	selectedItems,
	checkout,
	orderHistory,
	persistentCheckout,
	paymentTokens,
	configurator,
	addressBook,
	timer,
	telesales,
	makasibPoints,
	buyNowPayLater,
});

export type Store = typeof RootReducer;

export default RootReducer;
