import { BillingType } from '@ApiModels/accounts';
import { IChoosePaymentMethod } from '@ApiModels/choosePaymentMethod';
import { IChooseShipment } from '@ApiModels/chooseShipment';
import { ICreateNotificationResponse } from '@ApiModels/createNotificationRequest';
import { IGuestLogIn } from '@ApiModels/guestLogIn';
import { IOrderDetailsResponse } from '@ApiModels/orderDetails';
import { IOrderHistory } from '@ApiModels/orderHistory';
import { IHbbWfbbOrderResponse } from '@ApiModels/setHbbWfbbOrder';
import { IGetOrderTracking, TrackingKeys } from '@ApiModels/tracking';
import { IViewCart } from '@ApiModels/viewCart';
import { setLoginType } from '@Redux/modules/api/authentication/actions';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { deleteWCTokens, updateHeader } from '@Redux/modules/api/headers/actions';
import { isUserLoggedIn } from '@Redux/modules/api/headers/selectors';
import { updateCart } from '@Redux/modules/cart/actions';
import { IHbbWfbbProcessCheckout } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import { ProfileService } from '@Services/profile/profileService';
import api from '@Utils/api';
import { IManageOrderItemESim } from '@Utils/api/payloads/omantelShop';
import moment from 'moment';
import { Observable } from 'rxjs';
import { concatMap, finalize, map, share, tap } from 'rxjs/operators';
import { IValidateTelesalesDocumentId } from '@ApiModels/validateOtpDocumentId';
import { IValidateTelesalesSmsPassword } from '@ApiModels/validateOtpSmsPassword';
import { IFileParams } from '@Components/DragAndDrop/DragAndDrop';
import { IValidateResourcePayload, IValidateResource } from '@ApiModels/validateResource';
import TagManager from 'react-gtm-module';
import { IValidateOffer } from '@ApiModels/validateOffer';
import { IMSKeysResponse } from '@ApiModels/msKeysList';
import { IPointsConversionElement } from '@ApiModels/getMakasibPointsCalculation';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { IMakasibOtpPayload, IMakasibOtpResponse } from '@ApiModels/validateMakasibOtp';

export type IPlanAction =
	| 'MOBILE_THE_SAME_PLAN'
	| 'MOBILE_PLAN_UPGRADE'
	| 'HBB_THE_SAME_PLAN'
	| 'HBB_PLAN_UPGRADE'
	| 'WFBB_PLAN_UPGRADE'
	| 'WFBB_THE_SAME_PLAN'
	| 'BUY_HBB'
	| 'BUY_WFBB'
	| 'BUY_SIM'
	| 'BUY_CAR_INSURANCE_BIMA'
	| 'BUY_CAR_INSURANCE_NLG'
	| 'ITEM_ACCESSORY_INSTALMENTS'
	| 'ITEM_ACCESSORY_INSTALMENTS_HBB'
	| 'ITEM_ACCESSORY_INSTALMENTS_WFBB'
	| 'TABLETS_LAPTOPS_INSTALMENTS'
	| 'TABLETS_LAPTOPS_INSTALMENTS_HBB'
	| 'TABLETS_LAPTOPS_INSTALMENTS_WFBB'
	| 'B2B_WITH_VOUCHER_UPFRONT'
	| 'B2B_WITH_VOUCHER_INSTALL'
	| 'B2B_WITHOUT_VOUCHER_UPFRONT'
	| 'B2B_WITHOUT_VOUCHER_INSTALL'
	| 'B2B_MOBILE_THE_SAME_PLAN_BAQATI'
	| 'B2B_MOBILE_PLAN_UPGRADE_BAQATI'
	| 'B2B_TABLETS_LAPTOPS_BAQATI'
	| 'B2B_ITEM_ACCESSORY_BAQATI'
	| 'B2B2C_WITH_VOUCHER_UPFRONT'
	| 'B2B2C_WITH_VOUCHER_INSTALL'
	| 'B2B2C_WITHOUT_VOUCHER_UPFRONT'
	| 'B2B2C_WITHOUT_VOUCHER_INSTALL'
	| 'B2B2C_MOBILE_THE_SAME_PLAN_BAQATI'
	| 'B2B2C_MOBILE_PLAN_UPGRADE_BAQATI'
	| 'B2B2C_TABLETS_LAPTOPS_BAQATI'
	| 'B2B2C_ITEM_ACCESSORY_BAQATI'
	| 'MAKASIB_REDEEM'
	| 'MAKASIB_PAYMENT_VOUCHER'
	| 'DEVICE_INSURANCE_INSTALMENT'
	| 'BNPL_BAQATI'
	| 'BNPL_HBB'
	| 'BNPL_WFBB';
export interface IValidatePlan {
	mobilePhone: string;
	commitment: string;
	skus: string;
	planAction: IPlanAction;
	tariff?: string;
	provisioning?: string;
	planCommitment?: string;
	installmentAmount?: string;
	oldTariff?: string;
	oldProvisioning?: string;
	oldPlanCommitment?: string;
	oldDeviceCommitment?: string;
}

export interface IValidateEbuOffer {
	mobilePhone: string;
	commitment?: string;
	smartVoucherCommitmentId?: string;
	planAction: string;
	installmentAmount?: string;
	hasAdditionalInfo: boolean;
}

interface IAddItemsToCartWithoutPlanPayload {
	quantity: string;
	productId: string;
}
export interface IAddItemsToCartPayload extends IAddItemsToCartWithoutPlanPayload {
	xitem_mobilePhoneType?: BillingType;
	xitem_mobilePhone?: string;
	xitem_planAction?: IPlanAction;
	xitem_makasibPointsQuantity?: string;
	xitem_makasibPointsValueInOMR?: string;
	xitem_voucherId?: string;
	xitem_totalVoucherValueInOMR?: string;
	xitem_usedVoucherValueInOMR?: string;
	xitem_expirationDate?: string;
	xitem_planCommitment?: string;
	xitem_provisioning?: string;
	xitem_oldTariff?: string;
	xitem_oldPlanCommitment?: string;
	xitem_oldProvisioning?: string;
	xitem_login?: string;
	xitem_productId?: string;
	xitem_oldDeviceCommitment?: string;
	installmentPeriod?: string;
	payment_plan?: string;
	productName?: string;
	xitem_installmentDuration?: string;
	xitem_commitmentDuration?: string;
	xitem_corporateNo?: string;
	xitem_accountNo?: string;
	xitem_hasAdditionalInfo?: string;
	xitem_commitmentId?: string;
	xitem_IAI?: string;
	xitem_emfConfigId?: string;
	xitem_BNPLMP?: string;
	xitem_BNPLPP?: string;
	xitem_BNPLCR?: string;
}

export interface IAddSimToCartPayload extends IAddItemsToCartPayload {
	quantity: string;
	xitem_planAction: IPlanAction;
	xitem_documentId: string;
	xitem_documentType: string;
	xitem_productId: string;
	productId: string;
}

export interface IAddInsuranceToCartPayload extends IAddItemsToCartPayload {
	xitem_referenceNo: string;
	xitem_amount: string;
	xitem_name: string | undefined;
	xitem_plateCode: string | undefined;
	xitem_licenseNo: string;
	xitem_ins: string;
	xitem_value: string | undefined;
	xitem_type: string;
	xitem_lang: string;
	xitem_vehicleNo: string | undefined;
	xitem_trackId: string;
	xitem_model: string;
	xitem_features: string;
	xitem_manufacturer: string;
	xitem_requestMsisdn: string;
	xitem_login: string;
	xitem_logo: string | undefined;
}

export interface ICreateNotificationRequest {
	email?: string | null;
	phoneNumber?: string | null;
	partNumber: string;
	fullName: string;
}

interface ITracking {
	status: TrackingKeys;
	statusTime: string;
	sequence: string;
}
export interface IGetOrderTrackingResponse {
	trackingNumber: string;
	orderItems: string[];
	tracking: ITracking[];
	trackingUrl: string;
}

export interface IMakePaymentRefundPayload {
	orderId: string;
}
export interface IRefundPaymentLogPayload {
	success: boolean;
	refundResponse: string;
	refundPayload: string;
}
export interface IContactPayload {
	offer: string;
	phone: string;
	language: string;
	name?: string;
	email?: string;
	message: string;
	returnReference?: boolean;
	sendEmailToSender?: boolean;
	sendSms?: boolean;
}
export interface IMoveOrderPayload {
	phoneNumber?: string;
	contactNumber?: string;
	email?: string;
	orderId?: string;
}
export interface IHbbOrderPayload extends IHbbWfbbProcessCheckout {
	orderId?: string;
}
export interface IWfbbOrderPayload {
	orderId: string;
	operationContext: string;
	productIds: string[];
	gender: string;
	dateOfBirth: string;
	language: string;
	firstName: string;
	middleName: string;
	lastName: string;
	surname: string;
	fatherName: string;
	grandfatherName: string;
	regionId: string;
	email: string;
	contactNumber: string;
	countryCode: string;
	willayatId: string;
	wfbbType: string;
	longitude: string;
	latitude: string;
	documentId: string;
	documentType: string;
	expiryDate: string;
	documentScan?: { base64frontSide: IFileParams | null; base64backSide: IFileParams | null };
}
export interface IPrepaidOrderPayload {
	documentType?: string;
	documentId?: string;
	orderItemId?: string;
}

export interface IPickupOrderPayload {
	orderItemId: string;
	branchId: string;
	bookingPickupAppointment: string;
	ticketNumber: string;
}

export interface IValidateTelesalesDocumentIdPayload {
	msisdn: string;
	orderId: string;
	document: string;
}

export interface IValidateTelesalesSmsPasswordPayload {
	msisdn: string;
	orderId: string;
	token: string;
}
export interface ITagManagerAddToCart {
	Logged_in: string;
	Product_Name: string;
	Payment_Period?: string;
	Payment_plan?: string;
	Product_Amount?: string;
	event?: string;
	userId?: string;
}

const TAG_MANAGER_ADD_TO_CART = 'Add to cart';
export class OrderFlowService {
	public static guestLogIn(): Observable<IGuestLogIn> {
		return api.omantelShop
			.guestLogIn()
			.call()
			.pipe(
				tap((r) => {
					dispatch(
						updateHeader({
							name: 'WCTrustedToken',
							value: r.WCTrustedToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCToken',
							value: r.WCToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCPersonalization',
							value: r.personalizationID,
						})
					);
				})
			);
	}

	public static guestLogOut(): Observable<null> {
		return api.omantelShop
			.guestLogOut()
			.call()
			.pipe(
				tap(() => {
					dispatch(setLoginType(undefined));
					dispatch(deleteWCTokens());
				})
			);
	}

	public static viewCart(): Observable<IViewCart> {
		return api.omantelShop
			.viewCart()
			.call()
			.pipe(
				finalize(() => {
					dispatch(updateCart(false));
				}),
				share()
			);
	}

	public static addItemsToCart(
		items: IAddItemsToCartPayload[]
	): Observable<{ response: { orderId: string; orderItem: { orderItemId: string }[] } }> {
		const addTagManagerActions = (Logged_in: boolean) => {
			let payload: ITagManagerAddToCart = {
				Logged_in: Logged_in ? 'Yes' : 'No',
				Product_Name: '',
				event: TAG_MANAGER_ADD_TO_CART,
				userId: getUserId(),
			};
			items.forEach((item) => {
				item?.installmentPeriod
					? (payload = { ...payload, Payment_Period: item?.installmentPeriod })
					: (payload.Payment_Period = '');
				if (item?.payment_plan) payload = { ...payload, Payment_plan: item?.payment_plan };
				if (item?.quantity) payload = { ...payload, Product_Amount: item?.quantity };
				if (item?.productName) payload = { ...payload, Product_Name: item?.productName };
				delete item.payment_plan;
				delete item.installmentPeriod;
				delete item.productName;

				TagManager.dataLayer({ dataLayer: payload });
			});
		};

		if (!isUserLoggedIn() && !isUserSignedIn()) {
			return this.guestLogIn().pipe(
				concatMap(() => {
					return api.omantelShop
						.addItemsToCart(items)
						.call()
						.pipe(
							finalize(() => {
								addTagManagerActions(false);
								dispatch(updateCart(true));
							})
						);
				})
			);
		} else {
			return api.omantelShop
				.addItemsToCart(items)
				.call()
				.pipe(
					finalize(() => {
						addTagManagerActions(true);
						dispatch(updateCart(true));
					})
				);
		}
	}

	public static clearAllCartItems(): Observable<null> {
		return api.omantelShop
			.clearAllCartItems()
			.call()
			.pipe(
				finalize(() => {
					dispatch(updateCart(true));
				})
			);
	}

	public static removeSingleCartItem(orderItemId: string, cartUpdate: boolean = true): Observable<null> {
		return api.omantelShop
			.removeSingleCartItem(orderItemId)
			.call()
			.pipe(
				finalize(() => {
					if (cartUpdate) {
						dispatch(updateCart(true));
					}
				})
			);
	}

	public static changeCartItemQuantity(orderItemId: string, quantity: number): Observable<any> {
		return api.omantelShop
			.changeCartItemQuantity(orderItemId, quantity)
			.call()
			.pipe(
				finalize(() => {
					dispatch(updateCart(true));
				})
			);
	}

	public static changeCartItemData(data: IPrepaidOrderPayload | IPickupOrderPayload): Observable<any> {
		return api.omantelShop
			.changeCartItemData(data)
			.call()
			.pipe(
				finalize(() => {
					dispatch(updateCart(true));
				})
			);
	}

	public static manageOrderItemESim({ esimNo, esimAction, orderItemId }: IManageOrderItemESim): Observable<any> {
		if (esimAction) {
			return api.omantelShop
				.manageOrderItemESim({ esimNo, esimAction, orderItemId })
				.call()
				.pipe(
					finalize(() => {
						dispatch(updateCart(true));
					})
				);
		}
		return ProfileService.getSimDetails(esimNo).pipe(
			concatMap((details) => {
				return api.omantelShop
					.manageOrderItemESim({
						esimNo,
						orderItemId,
						esimAction: !!details.eSIM ? 'TRANSFER' : 'NEW',
					})
					.call()
					.pipe(
						map(() => {
							dispatch(updateCart(true));
						})
					);
			})
		);
	}

	public static userLogIn(login: string, password: string): Observable<any> {
		return api.omantelShop
			.userLogIn(login, password)
			.call()
			.pipe(
				tap((r) => {
					dispatch(
						updateHeader({
							name: 'WCTrustedToken',
							value: r.WCTrustedToken,
						})
					);
					dispatch(
						updateHeader({
							name: 'WCToken',
							value: r.WCToken,
						})
					);
					dispatch(setLoginType('user'));
				})
			);
	}

	public static userLogOut(): Observable<any> {
		return api.omantelShop
			.userLogOut()
			.call()
			.pipe(
				tap(() => {
					const tagManagerArgs = {
						dataLayer: {
							Logged_in: 'No',
						},
					};
					TagManager.dataLayer(tagManagerArgs);
					dispatch(setLoginType(undefined));
					dispatch(deleteWCTokens());
				})
			);
	}

	public static getOrderHistory(pageNumber: number, pageSize: number): Observable<IOrderHistory> {
		return api.omantelShop.getOrderHistory(pageNumber, pageSize).disableCache().call();
	}

	public static getOrderDetails(orderId: string): Observable<IOrderDetailsResponse> {
		return api.omantelShop.getOrderDetails(orderId).disableCache().call();
	}

	public static validateMakasibOtp(orderId: string, payload: IMakasibOtpPayload): Observable<IMakasibOtpResponse> {
		return api.omantelShop.validateMakasibOtp(orderId, payload).disableCache().call();
	}

	public static getOrderMSKeys(orderId: string): Observable<IMSKeysResponse> {
		return api.omantelShop.getOrderMSKeys(orderId).disableCache().call();
	}

	public static getItemMSKey(itemId: string): Observable<IMSKeysResponse> {
		return api.omantelShop.getItemMSKey(itemId).disableCache().call();
	}

	public static validateOffer(payload: IValidatePlan): Observable<IValidateOffer> {
		return api.omantelShop.validateOffer(payload).disableCache().call();
	}

	public static validateEbuOffer(payload: IValidateEbuOffer): Observable<IValidateOffer> {
		return api.omantelShop.validateEbuOffer(payload).disableCache().call();
	}

	public static chooseARAMEXShipment({
		addressId,
		pickupDelivery,
	}: {
		addressId?: string;
		pickupDelivery?: boolean;
	}): Observable<IChooseShipment> {
		return api.omantelShop.chooseARAMEXShipment({ addressId, pickupDelivery }).call();
	}

	public static chooseCybersourcePayment(): Observable<IChoosePaymentMethod> {
		return this.viewCart().pipe(
			concatMap(({ totalProductPrice }) => {
				return this.deleteAllPaymentInstructions().pipe(
					concatMap(() => {
						return api.omantelShop.chooseCybersourcePayment(totalProductPrice).call();
					})
				);
			})
		);
	}

	public static createNotificationRequest(
		payload: ICreateNotificationRequest
	): Observable<ICreateNotificationResponse> {
		return api.omantelShop.createNotificationRequest(payload).call();
	}

	public static getOrderTracking({
		orderId,
	}: types.payloads.IGetOrderTracking): Observable<IGetOrderTrackingResponse[] | null> {
		return api.omantelShop
			.getOrderTracking({ orderId })
			.call()
			.pipe(
				map<{ orderTracking: IGetOrderTracking }, IGetOrderTrackingResponse[]>(({ orderTracking }) => {
					const response: IGetOrderTrackingResponse[] | null = [];
					for (const key in orderTracking) {
						if (orderTracking[key] && key !== 'GIFTCARD' && key !== 'DIGITAL_PRODUCT') {
							const { orderItems, trackingNumber, tracking, trackingUrl } = orderTracking[key];
							response.push({
								orderItems,
								trackingNumber,
								trackingUrl,
								tracking: (() => {
									const _tracking: ITracking[] = [];
									for (const trackingKey in tracking) {
										if (trackingKey) {
											const { statusTime, sequence } = tracking[trackingKey as TrackingKeys];
											const time = moment(statusTime, 'DD.MM.YYYY');
											_tracking.push({
												status: trackingKey as TrackingKeys,
												statusTime: time.isValid() ? time.format('DD MMM YYYY') : '',
												sequence,
											});
										}
									}
									_tracking.sort((a, b) => {
										if (Number(a.sequence) < Number(b.sequence)) return -1;
										return 1;
									});
									return _tracking;
								})(),
							});
						}
					}
					return response;
				})
			);
	}

	private static deleteAllPaymentInstructions() {
		return api.omantelShop.deleteAllPaymentInstructions().call();
	}

	public static setHbbWfbbOrder(
		params: IHbbOrderPayload | IWfbbOrderPayload,
		isWfbb: boolean
	): Observable<IHbbWfbbOrderResponse> {
		return api.omantelShop.setHbbWfbbOrder(params, isWfbb).call();
	}

	public static directContact(params: IContactPayload): Observable<{ response?: any }> {
		return api.omantelShop.directContact(params).call();
	}

	public static makePaymentRefund(params: IMakePaymentRefundPayload): Observable<{ response?: string }> {
		return api.omantelShop.makePaymentRefund(params).call();
	}

	public static cancelOrder(params: IMakePaymentRefundPayload): Observable<{ response?: string }> {
		return api.omantelShop.cancelOrder(params).call();
	}

	public static refundPaymentLog(
		params: IRefundPaymentLogPayload,
		orderId: types.payloads.IRefundPaymentLog
	): Observable<{ response?: string }> {
		return api.omantelShop.refundPaymentLog(params, orderId).call();
	}

	public static otpValidateDocumentId(
		params: IValidateTelesalesDocumentIdPayload
	): Observable<IValidateTelesalesDocumentId> {
		return api.omantelShop.otpValidateDocumentId(params).call();
	}

	public static otpValidateSmsPassword(
		params: IValidateTelesalesSmsPasswordPayload
	): Observable<IValidateTelesalesSmsPassword> {
		return api.omantelShop.otpValidateSmsPassword(params).call();
	}

	public static moveOrder(params: IMoveOrderPayload): Observable<{ result?: null }> {
		return api.omantelShop.moveOrder(params).call();
	}

	public static getMakasibPointsCalculation(): Observable<{
		nontelecomPointsConversion: IPointsConversionElement[];
	}> {
		return api.omantelShop.getMakasibPointsCalculation().call();
	}

	public static validateResource({
		customerCode = null,
		customerType = null,
		requestType,
		serviceOrderType,
	}: IValidateResourcePayload): Observable<IValidateResource> {
		return api.omantelApi.validateResource(customerCode, customerType, requestType, serviceOrderType).call();
	}
}
