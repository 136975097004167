import {
	Minus,
	QuantityButton,
	QuantityContainer,
	QuantityText,
	FlyoutQuantityText,
	FlyoutQuantityButton,
} from '@Components/QuantityComponent/QuantityComponent.styled';
import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent, useMemo } from 'react';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { errors } from '@Utils/api/errors/errors';
import { dispatch } from '@Redux/store';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import { useCart } from '@Utils/context/CartContext';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { getUserId } from '@Redux/modules/api/authentication/selectors';

interface IQuantityComponent {
	productId?: string;
	orderItemId: string;
	numberOfItems: number;
	cartFlyout?: boolean;
}

const QUANTITY_ICON_SIZE = 11;

const QuantityComponent: FunctionComponent<IQuantityComponent> = ({
	productId,
	orderItemId,
	numberOfItems,
	cartFlyout = false,
}) => {
	const { translate } = useTranslate();
	const { cart } = useCart();
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);

	const activeInsuranceInCart = useMemo(() => {
		return cart.items.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cart]);

	const cartItem = useMemo(() => {
		return cart.items.find((item) => item.orderItemId === orderItemId);
	}, [cart]);

	const updateItem = () => {
		OrderFlowService.changeCartItemQuantity(orderItemId, numberOfItems - 1).subscribe(
			() => {
				TagManager.dataLayer({
					dataLayer: {
						Product_Name: cartItem?.name,
						event: 'Remove item from cart',
						Logged_in: isSignedIn ? 'Yes' : 'No',
						userId: getUserId(),
					},
				});
			},
			() => {}
		);
	};

	const addItem = () => {
		if (!!activeInsuranceInCart) {
			dispatch(
				setModal({
					type: modalTypes.GENERIC_ERROR,
					data: {
						icon: 'warning',
						iconFill: 'primary',
						title: translate('get.insurance.warning.title'),
						description: translate('get.insurance.warning.subtitle'),
					},
				})
			);
		} else {
			if (productId) {
				OrderFlowService.addItemsToCart([
					{
						productId,
						quantity: '1',
						productName: cartItem?.name,
						payment_plan: translate('product.details.plan.device.only'),
						installmentPeriod: '',
					},
				]).subscribe(
					() => {},
					(e) => {
						if (e.response?.errors[0]?.errorKey === errors.ITEM_OUT_OF_STOCK) {
							dispatch(
								setModal({
									type: modalTypes.GENERIC_ERROR,
									data: {
										icon: 'warning',
										iconFill: 'primary',
										title: translate('product.cart-card.modal.out-of-stock.title'),
										description: translate('product.cart-card.modal.out-of-stock.description'),
									},
								})
							);
						}
					}
				);
			}
		}
	};
	return (
		<ConditionalRender
			show={cartFlyout}
			onTrue={() => (
				<QuantityContainer>
					<FlyoutQuantityButton
						disabled={numberOfItems <= 1}
						onClick={() => {
							if (numberOfItems > 1) {
								updateItem();
							}
						}}
					>
						<Icon name="minus" width={QUANTITY_ICON_SIZE} height={QUANTITY_ICON_SIZE} fill="primary" />
					</FlyoutQuantityButton>
					<FlyoutQuantityText>{numberOfItems}</FlyoutQuantityText>
					<FlyoutQuantityButton onClick={addItem}>
						<Icon name="plus" width={QUANTITY_ICON_SIZE} height={QUANTITY_ICON_SIZE} fill="primary" />
					</FlyoutQuantityButton>
				</QuantityContainer>
			)}
			onFalse={() => (
				<QuantityContainer>
					<QuantityButton
						disabled={numberOfItems <= 1}
						onClick={() => {
							if (numberOfItems > 1) {
								updateItem();
							}
						}}
					>
						<Minus disabled={numberOfItems <= 1} />
					</QuantityButton>
					<QuantityText>{numberOfItems}</QuantityText>
					<QuantityButton onClick={addItem}>
						<Icon name="plus" width={QUANTITY_ICON_SIZE} height={QUANTITY_ICON_SIZE} fill="white" />
					</QuantityButton>
				</QuantityContainer>
			)}
		/>
	);
};

export default QuantityComponent;
