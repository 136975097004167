import api from '@Utils/api';
import { Observable, of } from 'rxjs';
import { IAccount, IAccounts } from '@ApiModels/accounts';
import { concatMap, map } from 'rxjs/operators';
import { ICustomerAccountOperation } from '@ApiModels/relatedParties';
import { ProfileService } from '@Services/profile/profileService';
import { IUserProfile } from '@ApiModels/userProfile';

export class AccountsService {
	public static getAccountsForLoggedUser(): Observable<IAccounts> {
		return api.omantelApi.getAccountsForLoggedUser().call();
	}

	public static getMainAccount(): Observable<IAccount> {
		return this.getAccountsForLoggedUser().pipe(
			map(({ accounts }) => {
				return accounts.find((acc) => acc.isMain) ?? accounts[0];
			})
		);
	}

	public static getCustomerAccountOperation({
		doNotGetPinPukDetails = 'true',
		forceNumber,
	}: {
		doNotGetPinPukDetails?: 'true' | 'false';
		forceNumber?: string;
	}): Observable<ICustomerAccountOperation | undefined | null> {
		if (forceNumber !== undefined) {
			if (forceNumber === '') {
				return of(null);
			}
			const number = forceNumber.includes('OTP_') ? forceNumber.replace('OTP_', '') : forceNumber;
			return api.omantelApi.getCustomerAccountOperation(number, doNotGetPinPukDetails).call();
		}
		return ProfileService.getUserProfile().pipe(
			concatMap((userProfile: IUserProfile | undefined) => {
				if (userProfile?.uid) {
					const number = userProfile?.uid.includes('OTP_')
						? userProfile?.uid.replace('OTP_', '')
						: userProfile?.uid;
					return api.omantelApi.getCustomerAccountOperation(number, doNotGetPinPukDetails).call();
				} else {
					return of(null);
				}
			})
		);
	}
}
