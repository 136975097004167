import React, { FunctionComponent } from 'react';
import { OrderSummaryCardContainer } from '@Components/Card/cards/OrderSummary/OrderSummary.styled';
import Card from '@Components/Card/Card';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { VIEW_CART_ITEMS } from '@Views/Cart/Cart';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';

const OrderSummary: FunctionComponent<types.cards.IOrderSummaryCardProps> = ({ params: { cart } }) => {
	const isMakasibItemInCart = !!cart.items.filter(
		(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID || item.productId === MAKASIB_VOUCHER_PRODUCT_ID
	).length;

	return (
		<OrderSummaryCardContainer>
			<BusyIndicator listener={VIEW_CART_ITEMS} skeleton="checkout4">
				<>
					<Card<types.cards.ICartItemsCardProps>
						card="cartItems"
						params={{
							cartItems: cart.items,
							withoutQuantityButtons: true,
							tableMode: true,
							addToCart: false,
							summaryView: true,
						}}
						marginBottom={16}
					/>
					<Card<types.cards.IShippingDetailsCardProps> card="shippingDetails" marginBottom={16} />
					<ConditionalRender
						show={cart.totalProductPrice !== '0' || isMakasibItemInCart}
						onTrue={() => (
							<Card<types.cards.IPaymentDetailsCardProps> card="paymentDetails" marginBottom={16} />
						)}
					/>
					<Card<types.cards.ICartPaymentSummaryCardProps>
						card="cartPaymentSummary"
						params={{
							items: cart.items,
						}}
						marginBottom={16}
					/>
				</>
			</BusyIndicator>
		</OrderSummaryCardContainer>
	);
};

export default OrderSummary;
