export const DISABLE_CAR_INSURANCE = false;
export const DISABLE_HBB_WFBB_PROCESS = false;
export const DISABLE_WFBB = false;
export const DISABLE_PREPAID_TO_POSTPAID = false;
export const DISABLE_PICKUP_FROM_OUTLET = false;
export const DISABLE_PICKUP_BOOKING_APPOINTMENT = true;
export const DISABLE_ORDER_CANCELLATION = true;
export const DISABLE_EBU_SINGLE_PURCHASE = false;
export const DISABLE_EBU_SINGLE_PURCHASE_B2B2C_BAQATI = false;
export const DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI = false;
export const DISABLE_MAKASIB_VOUCHER = true;
export const DISABLE_MAKASIB_POINTS = true;
export const DISABLE_NEW_ADDRESS_DROPDOWN = false;
export const DISABLE_DEVICE_INSTALLMENT_CONFIG = false;
export const DISABLE_MS_CSP = false;
export const ENABLE_ESHOP_LITE = false;
export const ENABLE_4G5G = true;

/**
 * This kill switch flag use to enable and disable BNPL code
 */
export const isDisableBnpl = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_BUY_NOW_PAY_LATER ?? true;

/**
 * This kill switch flag use to enable and disable BNPL CR changes ODF 7985
 */
export const isDisabledBnplCr7985 = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_BNPL_CR_ODF_7985 ?? true;

/**
 * This kill switch flag use to enable and disable empty skeleton between getInstallment fail response to call calculate api
 */
export const isCalCulateLoader = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.CALCULATE_LOADER_ODF_8685 ?? true;

/**
 * This kill switch flag use to enable and disable add to cart warning when pay-later product is already in cart
 */
export const isBnplCartWarning = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_CART_ERROR_ODF_8539 ?? true;

/**
 * This kill switch flag use to enable and disable BNPL price change on Variant change and when user back to installment from upfront
 */
export const isBNPLVariantFix = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_VARIANT_FIX_ODF_8968 ?? true;

/**
 * This kill switch flag use to enable and disable Sensibo Pro device
 */
export const isSensiboProDevice = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_SENSIBO_PRO_DEVICE ?? true;
