import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	SecureCheckoutWrapper,
	HeaderWrapper,
	HeaderDescriptionWrapper,
	HeaderTitle,
	HeaderSubtitle,
	ContentWrapper,
} from './PaymentData.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import AddCardForm from '@Components/Card/cards/PaymentData/subcomponents/AddCardForm/AddCardForm';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PaymentService } from '@Services/payment/payment';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import SavedPaymentTokens from '@Components/Card/cards/PaymentData/subcomponents/SavedPaymentTokens/SavedPaymentTokens';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { ICheckout as ICheckoutAction } from '@Redux/modules/checkout/actions';
import { ProfileService } from '@Services/profile/profileService';
import { useScreenClass } from 'react-grid-system';

const GET_PAYMENT_TOKENS_BI = 'GET_PAYMENT_TOKENS_BI';

const PaymentData: FunctionComponent<types.cards.IPaymentDataCardProps> = ({ emmitEvent }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [paymentTokens, setPaymentTokens] = useState<IPaymentToken[]>([]);
	const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(false);
	const [selectedPaymentData, setSelectedPaymentData] = useState<ICheckoutAction['payload']['paymentData']>({
		card: { cardNumber: '', name: '', expire: '', cvv: '', savePaymentToken: false },
	});
	const telesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const screenClass = useScreenClass();

	useEffect(() => {
		if (userIsLoggedIn) {
			dispatch(addBusyIndicator(GET_PAYMENT_TOKENS_BI));
			PaymentService.getPaymentTokens().subscribe(
				(r) => {
					setPaymentTokens(r.tokens);
					if (r.tokens.length) {
						setSelectedPaymentData({ token: r.tokens[0] });
					}
					dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
				}
			);
		}
	}, [userIsLoggedIn]);

	useEffect(() => {
		emmitEvent && emmitEvent('updatePaymentData', selectedPaymentData);
	}, [selectedPaymentData]);

	const handleSetPaymentData = (data: any) => {
		setSelectedPaymentData(data);
	};

	useEffect(() => {
		ProfileService.getUserProfile(false, telesales).subscribe(
			() => {
				setUserIsLoggedIn(true);
			},
			() => {
				setUserIsLoggedIn(false);
			}
		);
	}, []);

	return (
		<SecureCheckoutWrapper>
			<HeaderWrapper>
				<Icon name="cardInformation" fill="black38" width={40} height={40} />
				<HeaderDescriptionWrapper>
					<HeaderTitle>{translate('secure-checkout.title')}</HeaderTitle>
					<HeaderSubtitle>{translate('secure-checkout.subtitle')}</HeaderSubtitle>
				</HeaderDescriptionWrapper>
			</HeaderWrapper>
			<ContentWrapper isSmallScreen={screenClass.includes('xs') || screenClass.includes('sm')}>
				<BusyIndicator listener={[GET_PAYMENT_TOKENS_BI]} skeleton="paymentTokens">
					<ConditionalRender
						show={!!paymentTokens.length}
						onTrue={() => (
							<SavedPaymentTokens
								paymentTokens={paymentTokens}
								selectedPaymentData={selectedPaymentData}
								setPaymentData={handleSetPaymentData}
							/>
						)}
						onFalse={() => (
							<AddCardForm
								selectedPaymentData={selectedPaymentData}
								setPaymentData={handleSetPaymentData}
								displaySaveCard={userIsLoggedIn}
							/>
						)}
					/>
				</BusyIndicator>
			</ContentWrapper>
		</SecureCheckoutWrapper>
	);
};

export default PaymentData;
