import { CartItemType } from '@Components/Card/cards/CartItems/subcomponents/CartItem';
import { IPlanAction } from '@Services/orderFlow/orderFlow';
import { getTariffIncludedFromAttributes, ITariffIncluded } from './utils/attributeGetters';

interface IContent {
	totalShippingCharge: string;
	resourceId: string;
	orderId: string;
	lastUpdateDate: string;
	channel: {
		channelIdentifer: {
			channelName: string;
			uniqueID: string;
		};
		userData: null;
		description: {
			language: string;
			value: string;
		};
	};
	orderStatus: string;
	x_isPurchaseOrderNumberRequired: string;
	totalShippingChargeCurrency: string;
	grandTotalCurrency: string;
	buyerId: string;
	recordSetCount: string;
	x_isPersonalAddressesAllowedForShipping: string;
	storeNameIdentifier: string;
	totalProductPriceCurrency: string;
	totalProductPrice: string;
	locked: string;
	recordSetComplete: string;
	totalAdjustmentCurrency: string;
	totalSalesTaxCurrency: string;
	totalSalesTax: string;
	grandTotal: string;
	orderItem: IOrderItem[];
	storeUniqueID: string;
	recordSetStartNumber: string;
	resourceName: string;
	recordSetTotal: string;
	shipAsComplete: string;
	x_trackingIds: string;
	totalShippingTax: string;
	totalShippingTaxCurrency: string;
	prepareIndicator: string;
	totalAdjustment: string;
	xitem_defItemType?: string;
}

interface IOrderItem {
	unitUom: string;
	shippingChargeCurrency: string;
	lastUpdateDate: string;
	expectedShipDate: string;
	description: string;
	language: string;
	salesTax: string;
	xitem_isPersonalAddressesAllowedForShipping: string;
	correlationGroup: string;
	shippingTax: string;
	orderItemStatus: string;
	offerID: string;
	currency: string;
	shippingCharge: string;
	orderItemPrice: string;
	shipModeLanguage: string;
	createDate: string;
	unitPrice: string;
	salesTaxCurrency: string;
	quantity: string;
	shipModeCode: string;
	productId: string;
	shipModeDescription: string;
	orderItemId: string;
	fulfillmentCenterId: string;
	shipModeId: string;
	isExpedited: string;
	orderItemFulfillmentStatus: string;
	shippingTaxCurrency: string;
	carrier: string;
	UOM: string;
	freeGift: string;
	unitQuantity: string;
	contractId: string;
	partNumber: string;
	totalAdjustment: {
		currency: string;
		value: string;
	};
	orderItemInventoryStatus: string;
	xitem_field2: string;
	xitem_name?: string;
	xitem_planLongDescription?: string;
	xitem_planMonthy?: string;
	xitem_planName?: string;
	xitem_planShortDescription?: string;
	xitem_thumbnail?: string;
	xitem_planTaxablePrice: string;
	xitem_planVatValue: string;
	xitem_planVatPercent: string;
	xitem_planContractDuration: string;
	xitem_planDurationLabel?: string;
	xitem_defItemType?: string;
	xitem_productParentId?: string;
	IP: string;
	IV: string;
	PA: string;
}

interface IModel {
	_contents: IContent;
}

// Output Model
export interface IViewCart {
	lastUpdateDate?: string;
	items: (ICartItem | IInsuranceCartItem)[];
	totalProductPrice: string;
	orderId: string;
	BNPL_UPFRONT_MINUS_VAT?: string;
}

export interface ICartItem {
	durationLabel?: string;
	simPriceVatValue?: string | number;
	duration?: number;
	lock?: boolean;
	BNPLFEP?: string;
	BNPLMP?: string;
	BNPL_UPFRONT_AMOUNT?: string;
	name?: string;
	telesalesPhone?: string;
	contactNumber?: string;
	price: string;
	unitPrice: string;
	currency: string;
	salesTax: string;
	partNumber: string;
	productId: string;
	orderItemId: string;
	quantity: number;
	freeGift: boolean;
	withPlan?: boolean;
	makasibPointsValueInOMR?: string;
	plan?: ICartItemPlan;
	type?: CartItemType;
	vat?: string | number;
	vatPercent?: string | number;
	esimNo?: string;
	esimAction?: string;
	isDigital: boolean;
	thumbnail?: string;
	requestType?: string;
	amount?: string;
	referenceNo?: string;
	trackId?: string;
	taxablePrice?: string;
	vatValue?: string;
	voucherId?: string;
	totalVoucherValueInOMR?: string;
	usedVoucherValueInOMR?: string;
	expirationDate?: string;
	mobilePhoneType?: string | null;
	plateCode?: string;
	ins?: string;
	vehicleNo?: string;
	PA?: string;
	lang?: string;
	value?: string;
	logo?: string;
	model?: string;
	manufacturer?: string;
	features?: string;
	defType?: string;
	productParentId?: string;
	mobilePhone?: string;
	standardPrice?: string;
	installmentDuration?: string;
	IV?: string;
	IP?: string;
	totalAmount?: string;
	BNPL_UPFRONT_MINUS_VAT?: string;
}

export interface IInsuranceCartItem extends ICartItem {
	amount: string;
	referenceNo: string;
	trackId: string;
	taxablePrice: string;
	vatValue: string;
	mobilePhoneType: string | null;
	plateCode: string;
	ins: string;
	vehicleNo: string;
	PA: string;
	mobilePhone: string;
	name: string;
	lang: string;
	value: string;
	logo: string;
	model: string;
	manufacturer: string;
	features: string;
}

type IBillingType = 'POSTPAID' | 'PREPAID';

export interface ICartItemPlan {
	durationLabel?: string;
	name?: string;
	BNPL_UPFRONT_MINUS_VAT?: string;
	BNPL_UPFRONT_AMOUNT?: string;
	price?: string;
	productId?: string;
	requestType?: string;
	addShipMode?: string;
	launchDate?: string;
	mobilePhone?: string;
	commitmentId?: string;
	mobilePhoneType?: IBillingType;
	planAction?: IPlanAction;
	longDesc?: string;
	priceMonthly?: string;
	planName?: string;
	shortDesc?: string;
	thumbnail?: string;
	icon?: types.iconNames;
	planDetails?: ITariffIncluded;
	vatValue?: string;
	taxablePrice?: string;
	installmentDuration?: string;
	vatPercent?: string;
	planMonthy?: string;
	installmentValue?: string;
	installmentPeriod?: string;
	planTaxablePrice?: string;
	planVatValue?: string;
	planVatPercent?: string;
	IP?: string;
	IV?: string;
	BNPLFEP?: string;
	PA?: string;
	preorderDate?: string;
	planContractDuration?: string;
	esimNo?: string;
	esimAction?: string;
	voucherId?: string;
}

const model: types.models.IInputModel = {
	_contents: 'response',
	totalProductPrice: 'response.totalProductPrice',
	orderId: 'response.orderId',
	lastUpdateDate: 'response.lastUpdateDate',
	items: (model: IModel) => {
		return model._contents.orderItem.map((item) => {
			const parsedFiled = JSON.parse(item.xitem_field2);
			const plan = {
				...parsedFiled,
				longDesc: item.xitem_planLongDescription,
				priceMonthly: item.xitem_planMonthy,
				planName: item.xitem_planName,
				shortDesc: item.xitem_planShortDescription,
				thumbnail: item.xitem_thumbnail,
				planDetails: getTariffIncludedFromAttributes(parsedFiled.productId),
				planMonthy: item.xitem_planMonthy,
				planTaxablePrice: item.xitem_planTaxablePrice,
				planVatValue: item.xitem_planVatValue,
				planVatPercent: item.xitem_planVatPercent,
				planContractDuration: item.xitem_planContractDuration,
				durationLabel: item.xitem_planDurationLabel,
			};
			return {
				...parsedFiled,
				name: item.xitem_name,
				productParentId: item.xitem_productParentId,
				price: item.orderItemPrice,
				unitPrice: item.unitPrice,
				currency: item.currency,
				salesTax: item.salesTax,
				partNumber: item.partNumber,
				productId: item.productId,
				orderItemId: item.orderItemId,
				quantity: Number(item.quantity),
				freeGift: item.freeGift === 'true',
				withPlan: !!item.xitem_planName,
				plan,
				defType: item.xitem_defItemType,
			};
		});
	},
};
export default model;
