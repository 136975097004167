import { Action, ActionFunction1, createAction } from 'redux-actions';
import { IProductByIdItem } from '@ApiModels/productById';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { IProductOutput } from '@ApiModels/customerProducts';
import { IAccount } from '@ApiModels/accounts';
import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import { PaymentMethod } from '@Components/Card/cards/PaymentSummary/PaymentSummary';
import { PlanType } from '@Components/Card/cards/Plan/Plan';
import { IInstallment, ISmartVoucher } from '@ApiModels/getSmartVoucher';
import { ICalculatedProduct } from '@ApiModels/calculateInstallment';

export interface IDetails extends IProductByIdItem {
	pricesWithVat: IPricesWithVat;
	isBNPL?: boolean;
}
export interface ISelectedItemsPayload {
	details?: IDetails;
	productById?: IProductByIdItem[];
	plan?: ISinglePackage;
	currentPlan?: IProductOutput;
	activeProvisioning?: IProductOutput;
	activePlanCommitment?: IProductOutput;
	selectedAccount?: IAccount;
	inStock?: boolean;
	newPlanIsHigher?: boolean;
	productPaymentType?: PaymentMethod;
	showWarning?: boolean;
	planType?: PlanType;
	profileHasMultiAccount?: boolean;
	isHBB?: boolean;
	isWFBB5G?: boolean;
	isWFBB4G?: boolean;
	wrongDeviceChoosen?: boolean;
	isHbbInstallmentsOffer?: boolean;
	userHaveLegacyPlan?: boolean;
	cantBeUpgradedPlanId?: string;
	oldDeviceCommitmentId?: string;
	oldDeviceCommitmentCanBeExtended?: boolean;
	sendDeviceCommitment?: boolean;
	showTwoMonthWarning?: boolean;
	activeDeviceAccessoriesInstallmentId?: string;
	activeMobileDeviceInstallmentId?: string;
	selectedPaymentMethod?: string;
	selectedInstallment?: IInstallment;
	selectedCommitmentPeriod?: ISmartVoucher;
	calculatedProduct?: ICalculatedProduct;
	isCorporateUser?: boolean;
	isBaqatiPlan?: boolean;
	isEbuDocumentType?: boolean;
	isMasterUser?: boolean;
	voucherWarning?: string | undefined;
	installmentAmount?: string | undefined;
	commitment?: string | undefined;
	isExpApproved?: boolean;
}
export interface ISelectedItems {
	payload: ISelectedItemsPayload;
}

export const setSelectedItems: ActionFunction1<
	ISelectedItems['payload'],
	Action<ISelectedItems['payload']>
> = createAction('SET_SELECTED_PRODUCT', (action: ISelectedItems['payload']) => action);
export const clearSelectedItems: ActionFunction1<
	ISelectedItems['payload'],
	Action<ISelectedItems['payload']>
> = createAction('CLEAR_SELECTED_PRODUCT', (action: ISelectedItems['payload']) => action);
