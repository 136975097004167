import { IItem } from '@ApiModels/productsByCategoryId';
import { ICartItem } from '@ApiModels/viewCart';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import Icon from '@Components/Icon/Icon';
import QuantityComponent from '@Components/QuantityComponent/QuantityComponent';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { useCart } from '@Utils/context/CartContext';
import usePlans from '@Utils/hooks/useCartPlans';
import useTranslate from '@Utils/hooks/useTranslate';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { roundMoney } from '@Utils/converters/roundMoney';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import {
	IconContainer,
	ItemDescription,
	ItemDescriptionWrapper,
	ItemImage,
	ItemTitle,
	ItemWrapper,
	QuantityWrapper,
	SIMIconContainer,
	TitleContainer,
	RemoveItemWrapper,
	RowWrapper,
	ItemActionsWrapper,
	PlanDetailsWrapper,
	PlanDetailsClickableWrapper,
	VatInformationRowWrapper,
	WarningIconWrapper,
	VatInformationWrapper,
	PriceDescriptionWrapper,
} from './CartFlyoutItem.styled';
import { getLang } from '@Utils/language/language';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import {
	BodyL,
	BodySHigher,
	CaptionBigger,
	CaptionBiggerBold,
	DigitXsSm,
	ParagraphS,
} from '@Components/Typography/Typography.styled';
import useScreen from '@Utils/hooks/useScreen';
import { isRTL } from '@Utils/layout/layoutSupport';
import { dispatch } from '@Redux/store';
import { CostTitle, Vat, VatWrapper } from '@Components/CartFlyout/CartFlyout.styled';
import theme from '@Utils/theme/theme';
import { isVoucherPlanAction } from '@Utils/plans/planAction';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { MakasibService } from '@Services/makasib/makasib';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';

interface ICartFlyoutItem {
	item: ICartItem;
}

const ARROW_ICON_SIZE = 16;

export const BUY_CAR_INSURANCE_BIMA = 'BUY_CAR_INSURANCE_BIMA';

const CartFlyoutItem: FunctionComponent<ICartFlyoutItem> = ({ item }) => {
	const [product, setProduct] = useState<IItem | null>(null);
	const { translate } = useTranslate();
	const { planOfSpecificAccount } = usePlans();
	const { cart } = useCart();
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { screen } = useScreen();
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();

	const { buyHbbWfbbItemInCart, itemHbbWfbb } = useMemo(() => {
		const item = cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
		return {
			buyHbbWfbbItemInCart: !!item,
			itemHbbWfbb: item,
		};
	}, [cart]);

	const isWFBB = useMemo(() => (item?.plan?.PA ? [BUY_WFBB].includes(item?.plan?.PA) : false), [item]);

	const isHBB = useMemo(() => (item?.plan?.PA ? [BUY_HBB].includes(item?.plan?.PA) : false), [item]);

	const isPrepaid = item.plan?.PA?.includes(POSTPAID_PLAN);

	const activeInsuranceInCart = useMemo(() => {
		return cart.items.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cart]);

	const handleRemoveItem = () => {
		if (
			cart.items.filter(
				(item) => item.productId !== MAKASIB_POINTS_PRODUCT_ID || item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
			).length === 1 &&
			cart.items.some(
				(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID || item.productId === MAKASIB_VOUCHER_PRODUCT_ID
			)
		) {
			const makasibVouchers = cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
			OrderFlowService.clearAllCartItems().subscribe(
				() => {
					if (makasibVouchers.length > 0) {
						makasibVouchers.forEach((item) => {
							MakasibService.manageMakasibVoucherReservation(String(item?.voucherId), 'U').subscribe(
								() => {},
								() => {}
							);
						});
					}
				},
				() => {}
			);
		} else {
			if (cart.items.some((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)) {
				const makasibItem = cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
				if (!!makasibItem) {
					OrderFlowService.removeSingleCartItem(makasibItem?.orderItemId).subscribe(
						() => {
							TagManager.dataLayer({
								dataLayer: {
									Product_Name: makasibItem?.name,
									event: 'Remove item from cart',
									Logged_in: isSignedIn ? 'Yes' : 'No',
									userId: getUserId(),
								},
							});
						},
						() => {}
					);
				}
			}
			if (cart.items.some((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)) {
				const makasibVoucher = cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
				if (makasibVoucher.length > 0) {
					makasibVoucher.forEach((item) => {
						OrderFlowService.removeSingleCartItem(item?.orderItemId ?? '').subscribe(
							() => {
								MakasibService.manageMakasibVoucherReservation(String(item?.voucherId), 'U').subscribe(
									() => {},
									() => {}
								);

								TagManager.dataLayer({
									dataLayer: {
										Product_Name: item?.name ?? '',
										event: 'Remove item from cart',
										Logged_in: isSignedIn ? 'Yes' : 'No',
										userId: getUserId(),
									},
								});
							},
							() => {}
						);
					});
				}
			}
			if (activeInsuranceInCart) {
				OrderFlowService.removeSingleCartItem(activeInsuranceInCart.orderItemId).subscribe(
					() => {
						TagManager.dataLayer({
							dataLayer: {
								Product_Name: activeInsuranceInCart?.name,
								event: 'Remove item from cart',
								Logged_in: isSignedIn ? 'Yes' : 'No',
								userId: getUserId(),
							},
						});
					},
					() => {}
				);
			}
			OrderFlowService.removeSingleCartItem(item.orderItemId).subscribe(
				() => {
					TagManager.dataLayer({
						dataLayer: {
							Product_Name: item?.name,
							event: 'Remove item from cart',
							Logged_in: isSignedIn ? 'Yes' : 'No',
							userId: getUserId(),
						},
					});
				},
				() => {}
			);
		}
	};

	useEffect(() => {
		ProductsService.getProductById({ productId: item.productId, itemType: ItemType.UNKNOWN }).subscribe(
			({ item }) => {
				setProduct(item);
			},
			(e) => {}
		);
	}, [item]);

	const openPlanDetails = () => {
		if (item?.plan?.planDetails?.planId) {
			dispatch(
				setModal({
					type: modalTypes.PLAN_DETAILS,
					data: {
						planId: item?.plan?.planDetails?.planId,
						isHBB: item?.plan?.PA?.includes('HBB'),
						isWFBB: item?.plan?.PA?.includes('WFBB'),
						isPrepaid,
					},
					withoutContainer: false,
				})
			);
		}
	};

	const additionalInfo = useMemo(() => {
		if (product?.availableCapacity.length && product.availableCapacity.length) {
			return `${product.colors[0].value}, ${product.availableCapacity[0].value}`;
		}
		if (product?.isGiftCard && product.availableValues.length && product.availableCountries.length) {
			return `${product.availableValues[0].value}, ${product.availableCountries[0].value}`;
		}
		if (product?.isGiftCard && product.availableCountries.length) {
			return product.availableCountries[0].value;
		}
		if (product?.isDigitalProduct && product.availableValues.length) {
			return product.availableValues[0].value.toLowerCase();
		}
		return '';
	}, [product]);

	if (!product && !buyHbbWfbbItemInCart && item.PA !== BUY_CAR_INSURANCE_BIMA && item.PA !== POSTPAID_PLAN) {
		return null;
	}

	return (
		<>
			<ConditionalRender
				show={!isHBB && !isWFBB && item.PA !== BUY_CAR_INSURANCE_BIMA && item.PA !== POSTPAID_PLAN}
				onTrue={() => (
					<>
						<ItemWrapper>
							{product?.thumbnail && (
								<ItemImage isSmallSize={screen(['xs', 'sm'])} src={product?.thumbnail} />
							)}
							<ItemDescriptionWrapper>
								<ItemTitle>{product?.name || itemHbbWfbb?.plan?.planName}</ItemTitle>
								<ConditionalRender
									show={screen(['xs', 'sm']) && !item.withPlan}
									onTrue={() => (
										<>
											<RowWrapper>
												<ItemDescription>
													{additionalInfo.substring(0, additionalInfo.indexOf(','))}
												</ItemDescription>
												<BodySHigher color="primary">
													{translate('omr')}
													{`\xa0${roundMoney(product?.price)}`}
												</BodySHigher>
											</RowWrapper>
											<RowWrapper>
												<ItemDescription>
													{additionalInfo.substring(additionalInfo.indexOf(',') + 1)}
												</ItemDescription>
												<ConditionalRender
													show={!!product?.instalmentValue}
													onTrue={() => (
														<ItemActionsWrapper>
															<BodySHigher>
																{`(${item.plan?.installmentPeriod ?? 12}x)`}
															</BodySHigher>
															<BodySHigher color="primary">
																{translate('omr')}
																{`\xa0${roundMoney(product?.instalmentValue)}`}
															</BodySHigher>
														</ItemActionsWrapper>
													)}
												/>
											</RowWrapper>
										</>
									)}
									onFalse={() => (
										<ItemDescription>{additionalInfo.replace(',', '\xa0|\xa0')}</ItemDescription>
									)}
								/>
							</ItemDescriptionWrapper>
						</ItemWrapper>
						<ConditionalRender
							show={!!item.withPlan && !Boolean(item?.plan?.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper>
									<CostTitle>{translate('navbar.cart.upfront')}</CostTitle>
									<VatWrapper>
										<BodyL color="primary">
											{translate('omr')}
											{`\xa0${roundMoney(item?.price)}`}
										</BodyL>
										<ConditionalRender
											show={!!item.vatPercent}
											onTrue={() => (
												<Vat>{translate('navbar.cart.vat', item.vatPercent ?? '')}</Vat>
											)}
										/>
									</VatWrapper>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={!!item.withPlan && !Boolean(item?.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper>
									<PriceDescriptionWrapper>
										<CostTitle>{translate('payment.summary.description.installments')}</CostTitle>
										<CaptionBigger color="black54">
											{translate(
												'payment.summary.installments.period',
												item.plan?.installmentPeriod ?? '12'
											)}
										</CaptionBigger>
									</PriceDescriptionWrapper>
									<VatWrapper>
										<BodyL color="primary">
											{translate('omr')}
											{`\xa0${roundMoney(item.plan?.IV)}`}
										</BodyL>
										<ConditionalRender
											show={!!item.vatPercent}
											onTrue={() => (
												<Vat>{translate('navbar.cart.vat', item.vatPercent ?? '')}</Vat>
											)}
										/>
									</VatWrapper>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={!screen(['xs', 'sm']) && !item.withPlan && !Boolean(item.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper margin="0px 0px 16px">
									<CaptionBiggerBold color="black54">
										{translate('payment.summary.price')}
									</CaptionBiggerBold>
									<BodySHigher color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(product?.price)}`}
									</BodySHigher>
								</RowWrapper>
							)}
							onFalse={() => <RowWrapper margin="0px 0px 16px" />}
						/>
						<ConditionalRender
							show={isBuyNowPayLaterInCart && Boolean(item.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper>
									<CaptionBiggerBold color="black54">
										{translate('payment.summary.upfront')}
									</CaptionBiggerBold>
									<BodySHigher color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(cart?.items[0]?.BNPL_UPFRONT_AMOUNT)}`}
									</BodySHigher>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={isBuyNowPayLaterInCart && Boolean(item.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper>
									<CaptionBiggerBold color="black54">
										{translate(
											cart?.items[0]?.IP === '12' ? 'cart.items.11-month' : 'cart.items.23-month'
										)}
									</CaptionBiggerBold>
									<BodySHigher color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(cart?.items[0]?.IV ?? '0')}`}
									</BodySHigher>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={isBuyNowPayLaterInCart && Boolean(item.BNPL_UPFRONT_MINUS_VAT)}
							onTrue={() => (
								<RowWrapper margin="0px 0px 16px">
									<CaptionBiggerBold color="black54">
										{translate('product.cart-card.last')}
									</CaptionBiggerBold>
									<BodySHigher color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(cart?.items[0]?.BNPLFEP ?? '0')}`}
									</BodySHigher>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={!!item.withPlan}
							onTrue={() => (
								<VatInformationRowWrapper>
									<WarningIconWrapper>
										<Icon height={24} width={24} name="warningTwo" />
									</WarningIconWrapper>

									<VatInformationWrapper>
										<DigitXsSm color="black54">
											{translate('message.box.vat', item.vatPercent ?? '')}
											<span style={{ color: theme.colors.primary }}>
												{`\xa0${translate('omr')}`}
												{`\xa0${roundMoney(item?.taxablePrice)}`}
											</span>
										</DigitXsSm>
									</VatInformationWrapper>
								</VatInformationRowWrapper>
							)}
						/>
						<RowWrapper>
							<ConditionalRender
								show={!item.withPlan}
								onTrue={() => (
									<RemoveItemWrapper onClick={handleRemoveItem}>
										{translate('cart.item.remove')}
									</RemoveItemWrapper>
								)}
							/>
							<ConditionalRender
								show={!item.withPlan && !isVoucherPlanAction(item) && !isBuyNowPayLaterInCart}
								onTrue={() => (
									<QuantityWrapper>
										<QuantityComponent
											productId={product?.uniqueID}
											numberOfItems={item.quantity}
											orderItemId={item.orderItemId}
											cartFlyout
										/>
									</QuantityWrapper>
								)}
							/>
						</RowWrapper>
					</>
				)}
			/>
			<ConditionalRender
				show={
					item?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
					item?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
					item?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
					item?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
					item?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
					item?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
					item?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
					item?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
					item?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
					item?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
					item?.plan?.PA !== POSTPAID_PLAN &&
					(!!item.withPlan || isHBB || isWFBB)
				}
				onTrue={() => (
					<>
						<ItemWrapper>
							<SIMIconContainer>
								<Icon name={isHBB || isWFBB ? 'buyHbbWfbb' : 'eSim'} height={48} width={48} />
							</SIMIconContainer>
							<ItemDescriptionWrapper>
								<TitleContainer>
									<ConditionalRender
										show={!!planOfSpecificAccount?.name}
										onTrue={() => (
											<>
												<ItemTitle>{planOfSpecificAccount?.name}</ItemTitle>
												<IconContainer>
													<Icon name="arrowSwipe" width={16} height={16} />
												</IconContainer>
											</>
										)}
									/>
									<RowWrapper>
										<ItemTitle>{item.plan?.planName}</ItemTitle>
										<ConditionalRender
											show={screen(['xs', 'sm']) && (isHBB || isWFBB)}
											onTrue={() => (
												<BodySHigher color="primary">
													{translate('omr')}
													{`\xa0${roundMoney(product?.price ?? 0)}`}
												</BodySHigher>
											)}
										/>
									</RowWrapper>
								</TitleContainer>
								<ConditionalRender
									show={!screen(['xs', 'sm'])}
									onTrue={() => (
										<>
											<ItemDescription>{item.plan?.mobilePhone}</ItemDescription>
											<ItemDescription>
												{!(isHBB || isWFBB)
													? translate(
															'plan.contract.duration',
															item.plan?.planContractDuration ?? 12
													  )
													: item.plan?.planContractDuration
													? translate(
															'plan.contract.duration',
															item.plan?.planContractDuration
													  )
													: ''}
											</ItemDescription>
										</>
									)}
									onFalse={() => (
										<ConditionalRender
											show={isHBB || isWFBB}
											onTrue={() => (
												<RowWrapper>
													<BodySHigher color="black54">
														{translate('payment.summary.subscription')}
													</BodySHigher>
													<ItemActionsWrapper>
														<BodySHigher>
															{`(${item.plan?.planContractDuration ?? 12}x)`}
														</BodySHigher>
														<BodySHigher color="primary">
															{translate('omr')}
															{`\xa0 ${roundMoney(
																item.plan?.installmentValue || item.plan?.priceMonthly
															)}`}
														</BodySHigher>
													</ItemActionsWrapper>
												</RowWrapper>
											)}
										/>
									)}
								/>
							</ItemDescriptionWrapper>
						</ItemWrapper>
						<ConditionalRender
							show={!screen(['xs', 'sm']) && (isHBB || isWFBB)}
							onTrue={() => (
								<RowWrapper margin="0px 0px 16px">
									<ParagraphS color="black54">{translate('payment.summary.subscription')}</ParagraphS>
									<ParagraphS color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(item.plan?.installmentValue || item.plan?.priceMonthly)}`}
									</ParagraphS>
								</RowWrapper>
							)}
						/>
						<ConditionalRender
							show={!(isHBB || isWFBB)}
							onTrue={() => (
								<RowWrapper margin="0px 0px 24px 0px">
									<PriceDescriptionWrapper>
										<CostTitle>{translate('payment.summary.subscription')}</CostTitle>
										<CaptionBigger color="black54">
											{translate(
												'payment.summary.installments.period',
												item.plan?.planContractDuration ?? '12'
											)}
										</CaptionBigger>
									</PriceDescriptionWrapper>
									<VatWrapper>
										<BodyL color="primary">
											{translate('omr')}
											{`\xa0${roundMoney(item.plan?.priceMonthly)}`}
										</BodyL>
										<ConditionalRender
											show={!!item.vatPercent}
											onTrue={() => (
												<Vat>{translate('navbar.cart.vat', item.vatPercent ?? '')}</Vat>
											)}
										/>
									</VatWrapper>
								</RowWrapper>
							)}
						/>
						<RowWrapper>
							<RemoveItemWrapper onClick={handleRemoveItem}>
								{translate('cart.item.remove')}
							</RemoveItemWrapper>
							<PlanDetailsClickableWrapper onClick={openPlanDetails}>
								<PlanDetailsWrapper>{translate('plan-comparison.details.title')}</PlanDetailsWrapper>
								<Icon
									name={isRTL() ? 'arrowLeftBox' : 'arrowRightBox'}
									fill="primary"
									width={ARROW_ICON_SIZE}
									height={ARROW_ICON_SIZE}
								/>
							</PlanDetailsClickableWrapper>
						</RowWrapper>
					</>
				)}
			/>
			<ConditionalRender
				show={item.PA === BUY_CAR_INSURANCE_BIMA}
				onTrue={() => (
					<>
						<ItemWrapper>
							{item?.logo && <ItemImage src={item.logo} />}
							<ItemDescriptionWrapper>
								<ItemTitle>{item.plan?.planName}</ItemTitle>
								<ItemDescription>
									{'manufacturer' in item && item.manufacturer}
									{'\xa0|\xa0'}
									{'model' in item && item.model}
								</ItemDescription>
								<ItemDescription>
									{(item.type as string) === INSURANCE_TYPE.FULL_COMPREHENSIVE
										? translate('insurance.type.choice1')
										: translate('insurance.type.choice2')}
									, {'features' in item && item.features}
								</ItemDescription>
							</ItemDescriptionWrapper>
						</ItemWrapper>
						<RowWrapper>
							<RemoveItemWrapper onClick={handleRemoveItem}>
								{translate('cart.item.remove')}
							</RemoveItemWrapper>
						</RowWrapper>
					</>
				)}
			/>
			<ConditionalRender
				show={item.PA === POSTPAID_PLAN}
				onTrue={() => (
					<>
						<ItemWrapper>
							<SIMIconContainer>
								<Icon name="eSim" height={48} width={48} />
							</SIMIconContainer>
							<ItemDescriptionWrapper>
								<ItemTitle>{item.plan?.planName}</ItemTitle>
								<RowWrapper>
									<ItemDescription>
										{translate('product.cart-card.buySim.SimIncluded')}
										{',\xa0'}
										{getLang() === 'ar'
											? `${item.plan?.durationLabel}\xa0${translate(
													'product.cart-card.buySim.plan-valid'
											  )}`
											: `${translate('product.cart-card.buySim.plan-valid')}\xa0${
													item.plan?.durationLabel
											  }`}
										{',\xa0'}
										{translate('product.cart-card.upfront-payment')}
									</ItemDescription>
									<ConditionalRender
										show={screen(['xs', 'sm'])}
										onTrue={() => (
											<BodySHigher color="primary">
												{translate('omr')}
												{`\xa0${roundMoney(item.amount)}`}
											</BodySHigher>
										)}
									/>
								</RowWrapper>
							</ItemDescriptionWrapper>
						</ItemWrapper>
						<ConditionalRender
							show={screen(['xs', 'sm'])}
							onTrue={() => <RowWrapper margin="0px 0px 16px"></RowWrapper>}
							onFalse={() => (
								<RowWrapper margin="0px 0px 16px">
									<CaptionBiggerBold color="black54">
										{translate('payment.summary.price')}
									</CaptionBiggerBold>
									<BodySHigher color="primary">
										{translate('omr')}
										{`\xa0${roundMoney(item.amount)}`}
									</BodySHigher>
								</RowWrapper>
							)}
						/>
						<RowWrapper>
							<RemoveItemWrapper onClick={handleRemoveItem}>
								{translate('cart.item.remove')}
							</RemoveItemWrapper>
							<PlanDetailsClickableWrapper onClick={openPlanDetails}>
								<PlanDetailsWrapper>{translate('plan-comparison.details.title')}</PlanDetailsWrapper>
								<Icon
									name={isRTL() ? 'arrowLeftBox' : 'arrowRightBox'}
									fill="primary"
									width={ARROW_ICON_SIZE}
									height={ARROW_ICON_SIZE}
								/>
							</PlanDetailsClickableWrapper>
						</RowWrapper>
					</>
				)}
			/>
		</>
	);
};

export default CartFlyoutItem;
