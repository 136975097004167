import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setRemoteConfig, ISetRemoteConfigAction } from './actions';

const init = {
	sidebar: {
		DISABLE_BUY_NOW_PAY_LATER: true,
		DISABLE_BNPL_CR_ODF_7985: true,
		CALCULATE_LOADER_ODF_8685: true,
		BNPL_CART_ERROR_ODF_8539: true,
	},
};

const reducer = {
	[setRemoteConfig.toString()](state: any, action: ISetRemoteConfigAction) {
		return { ...state, ...action.payload };
	},
};

export default immerHandleActions(reducer, init);
