import {
	signIn,
	IAuthentication,
	setLoginType,
	ILoginType,
	logOut,
	setUserId,
	IUserId,
	setIsMasterProfile,
	IIsMasterProfile,
	setB2bUser,
	IB2bUserType,
	setB2b2cUser,
	IB2b2cUserType,
} from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';
import { DESTINATION } from '@Config/app.config';

const init = {
	signedIn: false,
	ltpaToken2: '',
	loginType: undefined,
	telemarketer: false,
	userId: '',
	isMasterProfile: false,
	isB2b: false,
	isB2b2c: false,
};

export interface IAuthenticationDraft extends IAuthentication {
	loginType: ILoginType['payload'];
}

export interface IUserIdDraft extends IAuthentication {
	userId: IUserId['payload'];
}

const reducers = {
	[signIn.toString()](draft: IAuthenticationDraft, action: { payload: IAuthentication }) {
		const { ltpaToken2 } = action.payload;
		draft = { ...draft, ...action.payload };
		if (ltpaToken2 !== undefined) {
			draft.ltpaToken2 = ltpaToken2;
			// Set cookie in JS only on DEV localhost machine.
			// On other enviroments should be auto setted by server (same domain).
			if (DESTINATION === 'DEV') {
				document.cookie = `LtpaToken2=${ltpaToken2}; path=/;`;
			}
		}
		return draft;
	},
	[logOut.toString()]() {
		// Delete cookie on localhost
		if (DESTINATION === 'DEV') {
			document.cookie = 'LtpaToken2=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
			document.cookie = 'JSESSIONID=; Path=/;';
		}
		return init;
	},
	[setLoginType.toString()](draft: IAuthenticationDraft, action: ILoginType) {
		draft.loginType = action.payload;
		return draft;
	},
	[setUserId.toString()](draft: IAuthenticationDraft, action: IUserId) {
		draft.userId = action.payload;
		return draft;
	},
	[setIsMasterProfile.toString()](draft: IAuthenticationDraft, action: IIsMasterProfile) {
		draft.isMasterProfile = action.payload;
		return draft;
	},
	[setB2bUser.toString()](draft: IAuthenticationDraft, action: IB2bUserType) {
		draft.isB2b = action.payload;
		return draft;
	},
	[setB2b2cUser.toString()](draft: IAuthenticationDraft, action: IB2b2cUserType) {
		draft.isB2b2c = action.payload;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
