import { IAvailableValueItem } from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import attributeIcons from '@Utils/constants/attributeIcons';
import { IItem, IColorWithId, IValueWithId } from './productsByCategoryId';
import { IPrice, ISeo, IAttribute } from './productsByPartNumber';
import {
	CAPACITY_ATTRIBUTE,
	COLOR_ATTRIBUTE,
	COUNTRY_ATTRIBUTE,
	getAttributeValueAsString,
	getAllValues,
	getAvailableCountries,
	getAvailableValues,
	getPrice,
	getTariffIncludedFromAttributes,
	GIFT_CARD_VALUES_ATTRIBUTE,
	ITariffIncluded,
	ITEM_TYPE_ATTRIBUTE,
	COM_PARAM_ID,
	VAT_PERCENT,
	PREORDER,
	PREORDER_DATE,
	DEF_ITEM_TYPE,
	GIFT_CARD_ITEM_TYPE,
	DIGITAL_PRODUCT_ITEM_TYPE,
	ITEM_TYPE,
	ITEM_HBB_INSTALMENTS,
	ITEM_MOBILE,
	ITEM_HBB,
	ADDITIONAL_SHIMENT_MODE,
	ADDITIONAL_SHIMENT_MODE_PICKUP,
	LAUNCH_DATE_ATTRIBUTE,
	DURATION,
	ITEM_ACCESSORY_INSTALMENTS,
	ITEM_ACCESSORY_INSTALMENTS_HBB,
	ITEM_ACCESSORY_INSTALMENTS_WFBB,
	DEVICE_PROTECTION_PROMO,
	ESHOP_ADDITIONAL_SERVICE,
	TABLETS_LAPTOPS_INSTALMENTS,
	TABLETS_LAPTOPS_INSTALMENTS_HBB,
	TABLETS_LAPTOPS_INSTALMENTS_WFBB,
} from './utils/attributeGetters';
import changeReplacementCharacter from '@Utils/converters/changeReplacementCharacter';

const MANUFACTURER = 'MANUFACTURER';
const MANUFACTURER_PART_NUMBER = 'MANUFACTURER_PART_NUMBER';
const DIMENSION_DETAILS = 'DIMENSION_DETAILS';
const COM_PARAM_INSTALLMENT_PERIOD = 'COM_PARAM_INSTALLMENT_PERIOD';
const COM_PARAM_INSTALLMENT_VALUE = 'COM_PARAM_INSTALLMENT_VALUE';
const COM_DISCOUNT_VALUE = 'COM_DISCOUNT_VALUE';

export interface IValueWithoutArray {
	id: string;
	identifier: string;
	sequence: string;
	value: string;
	image1: string;
	image1path?: string;
}

export interface IReview {
	rating: number;
	reviews?: number;
}
export interface IProductByIdItem {
	attributes: IAttribute<IValueWithoutArray>[];
	additionalService?: string;
	insurancePromotion?: boolean;
	planDetails: ITariffIncluded;
	buyable: string;
	fullImage: string;
	hasSingleSKU: boolean;
	id: string;
	name: string;
	parent: string;
	parentCatalogEntryID: string;
	parentCatalogGroupID: string;
	partNumber: string;
	productPartNumber: string;
	priceDisplay: number;
	priceOffer: number;
	priceDiscount?: string;
	seo: ISeo;
	shortDescription: string;
	storeID: string;
	thumbnail: string;
	type: string;
	uniqueID: string;
	additionalInfo?: string;
	colorId?: string;
	capacityId?: string;
	countryId?: string;
	availableValue?: IAvailableValueItem;
	valueId?: string;
	comParamId?: string;
	installmentPeriod?: string;
	installmentValue?: string;
	installmentValueFinal?: string;
	productTextInstallmentValue?: string;
	vatPercent?: string;
	preorder: boolean;
	preorderDate?: string;
	defItemType: string;
	duration: '12' | '24';
	parents: string[];
}
export interface IContents {
	attributes?: IAttribute<IValueWithoutArray>[];
	buyable: string;
	fullImage: string;
	fullImageRaw: string;
	hasSingleSKU: boolean;
	items: IContents[];
	longDescription: string;
	id: string;
	merchandisingAssociations: IContents[];
	name: string;
	parent: string;
	parentCatalogEntryID: string;
	parentCatalogGroupID: string;
	partNumber: string;
	price: IPrice[];
	seo: ISeo;
	shortDescription: string;
	storeID: string;
	thumbnail: string;
	type: string;
	uniqueID: string;
	associationType?: 'REPLACEMENT' | 'UPSELL';
}

export interface IWhatsInTheBoxItem {
	name: string;
	img?: string;
	visible?: boolean;
}

export interface IKeyFeaturesItem {
	title: string;
	value?: string;
	img?: string;
	id?: string;
}

export interface ITechnicalSpecification {
	name: string;
	description?: string;
}

export interface IReview {
	creator: string;
	time: string;
	rating: number;
	content: string;
}

export interface IDeviceDimension {
	height?: string;
	width?: string;
	depth?: string;
}

export interface IManufacturer {
	manufacturer?: string;
	partNumber?: {
		name?: string;
		code?: string;
	};
}

export interface IProductById {
	item: IItem;
	items?: IProductByIdItem[];
	whatsInTheBox: IWhatsInTheBoxItem[];
	keyFeatures: IKeyFeaturesItem[];
	technicalSpecification: ITechnicalSpecification[];
	manufacturer: IManufacturer;
	deviceDimensions: IDeviceDimension;
	relatedProducts: IItem[];
}

interface IModel extends IProductById {
	_contents: IContents[];
}

interface IRelatedItem extends IItem {
	isRelated: boolean;
	isUpsell: boolean;
}

const getAvailableColors = (attributes: IAttribute<IValueWithoutArray>[]): IColorWithId[] => {
	const colorsValues = attributes.find((attribute) => attribute.identifier === COLOR_ATTRIBUTE)?.values;
	const colors = colorsValues
		?.filter((value) => !!value?.image1)
		.map((value) => {
			return { color: value.image1, id: value.id, identifier: value.identifier, value: value.value };
		});
	return !!colors ? colors : [];
};

const getAvailableCapacity = (attributtes: IAttribute<IValueWithoutArray>[]): IValueWithId[] => {
	const capacityValues = attributtes.find((attributte) => attributte.identifier === CAPACITY_ATTRIBUTE)?.values;
	const capacity = capacityValues
		?.filter((value) => !!value?.value)
		.map((value) => {
			return { value: value.value, id: value.id, parsedIdentifier: value.identifier.replace('GB', '').trim() };
		});
	return !!capacity ? capacity : [];
};

const getItem = (catalogEntryView: IContents[]): IItem => {
	const {
		name,
		thumbnail,
		id,
		price,
		shortDescription,
		longDescription,
		parentCatalogGroupID,
		fullImage,
		seo,
	} = catalogEntryView[0];
	const attributes = catalogEntryView[0].attributes ?? [];
	const colors = getAvailableColors(attributes);
	const itemType = getAttributeValueAsString(catalogEntryView[0], ITEM_TYPE_ATTRIBUTE);
	const allItemType = getAllValues(catalogEntryView[0], ITEM_TYPE_ATTRIBUTE);
	const isGiftCard = itemType === GIFT_CARD_ITEM_TYPE;
	const isDigitalProduct = itemType === DIGITAL_PRODUCT_ITEM_TYPE;
	const isItem = itemType === ITEM_TYPE || allItemType?.includes(ITEM_TYPE);
	const planAvailable =
		allItemType?.includes(ITEM_HBB_INSTALMENTS) ||
		allItemType?.includes(ITEM_MOBILE) ||
		allItemType?.includes(ITEM_HBB) ||
		allItemType?.includes(ITEM_ACCESSORY_INSTALMENTS) ||
		allItemType?.includes(ITEM_ACCESSORY_INSTALMENTS_HBB) ||
		allItemType?.includes(ITEM_ACCESSORY_INSTALMENTS_WFBB) ||
		allItemType?.includes(TABLETS_LAPTOPS_INSTALMENTS) ||
		allItemType?.includes(TABLETS_LAPTOPS_INSTALMENTS_HBB) ||
		allItemType?.includes(TABLETS_LAPTOPS_INSTALMENTS_WFBB);
	const isItemHBBInstallments = getAllValues(catalogEntryView[0], ITEM_TYPE_ATTRIBUTE)?.includes(
		ITEM_HBB_INSTALMENTS
	);
	const pickupOrderAvailable =
		getAttributeValueAsString(catalogEntryView[0], ADDITIONAL_SHIMENT_MODE) === ADDITIONAL_SHIMENT_MODE_PICKUP;
	const launchDate = getAttributeValueAsString(catalogEntryView[0], LAUNCH_DATE_ATTRIBUTE);

	return {
		attributes,
		name: changeReplacementCharacter(name),
		parentCatalogGroupID,
		price: getPrice(price, 'Offer'),
		colors,
		availableCountries: getAvailableCountries(attributes),
		availableValues: getAvailableValues(attributes, catalogEntryView),
		availableCapacity: getAvailableCapacity(attributes),
		colorAttributeId: attributes.find((attribute) => {
			if (isGiftCard || isDigitalProduct) {
				return attribute.identifier === COUNTRY_ATTRIBUTE;
			}
			return attribute.identifier === COLOR_ATTRIBUTE;
		})?.id,
		capacityAttributeId: attributes.find((attributte) => {
			if (isGiftCard) {
				return attributte.identifier === GIFT_CARD_VALUES_ATTRIBUTE;
			}
			return attributte.identifier === CAPACITY_ATTRIBUTE;
		})?.id,
		thumbnail,
		uniqueID: id,
		shortDescription,
		longDescription,
		itemType,
		isGiftCard,
		preorder:
			attributes.find((attributte) => {
				return attributte.identifier === PREORDER;
			})?.values[0].value === 'TRUE',
		preorderDate: attributes.find((attributte) => {
			return attributte.identifier === PREORDER_DATE;
		})?.values[0].value,
		isDigitalProduct,
		isItem,
		isItemHBBInstallments,
		planAvailable,
		fullImage,
		pickupOrderAvailable,
		launchDate,
		seo: seo?.href,
		manufacturer: getManufacturer(catalogEntryView).manufacturer,
		allItemType,
		parents: (() => {
			if (parentCatalogGroupID.includes('_')) {
				return parentCatalogGroupID.split('_');
			} else {
				return [parentCatalogGroupID];
			}
		})(),
	};
};

const getItems = (catalogEntryView: IContents[]): IProductByIdItem[] => {
	return catalogEntryView[0].items.map((item) => {
		const attributes = item.attributes ?? [];
		return {
			attributes,
			insurancePromotion:
				attributes.find((attr) => attr.identifier === DEVICE_PROTECTION_PROMO)?.values[0].value === 'TRUE',
			additionalService: attributes.find((attr) => attr.identifier === ESHOP_ADDITIONAL_SERVICE)?.values[0].value,
			planDetails: getTariffIncludedFromAttributes(item),
			colorId: attributes.find((attr) => attr.identifier === COLOR_ATTRIBUTE)?.values[0].id,
			capacityId: attributes.find((attributte) => attributte.identifier === CAPACITY_ATTRIBUTE)?.values[0].id,
			valueId: attributes.find((attr) => attr.identifier === GIFT_CARD_VALUES_ATTRIBUTE)?.values[0].id,
			countryId: attributes.find((attr) => attr.identifier === COUNTRY_ATTRIBUTE)?.values[0].id,
			vatPercent: attributes.find((attr) => attr.identifier === VAT_PERCENT)?.values[0].value,
			buyable: item.buyable,
			fullImage: item.fullImage,
			hasSingleSKU: item.hasSingleSKU,
			id: item.id,
			name: item.name,
			parent: item.parent,
			parentCatalogEntryID: item.parentCatalogEntryID,
			parentCatalogGroupID: item.parentCatalogGroupID,
			partNumber: item.partNumber,
			productPartNumber: item.partNumber.split('##')[0],
			priceDisplay: getPrice(item.price, 'Display'),
			priceOffer: getPrice(item.price, 'Offer'),
			priceDiscount: getAttributeValueAsString(item, COM_DISCOUNT_VALUE),
			seo: item.seo,
			shortDescription: item.shortDescription,
			storeID: item.storeID,
			thumbnail: item.thumbnail,
			type: item.type,
			uniqueID: item.uniqueID,
			comParamId: getAttributeValueAsString(item, COM_PARAM_ID),
			installmentPeriod: getAttributeValueAsString(item, COM_PARAM_INSTALLMENT_PERIOD),
			installmentValue: getAttributeValueAsString(item, COM_PARAM_INSTALLMENT_VALUE),
			preorder: getAttributeValueAsString(item, PREORDER) === 'TRUE',
			preorderDate: getAttributeValueAsString(item, PREORDER_DATE),
			defItemType: getAttributeValueAsString(item, DEF_ITEM_TYPE) || 'ITEM_MOBILE',
			duration: getAttributeValueAsString(item, DURATION) === '12' ? '12' : '24',
			parents: (() => {
				if (item.parentCatalogGroupID.includes('_')) {
					return item.parentCatalogGroupID.split('_');
				} else {
					return [item.parentCatalogGroupID];
				}
			})(),
		};
	});
};

const getWhatsInTheBox = (catalogEntryView: IContents[]): IWhatsInTheBoxItem[] => {
	if (catalogEntryView[0].items) {
		const { attributes } = catalogEntryView[0].items?.[0] || {};
		const prefix = 'BOX_';
		const whatsInTheBox = attributes
			?.filter((attr) => attr.identifier.startsWith(prefix))
			.map((attr) => {
				return {
					name: attr.name,
					img: attr.values[0].image1path,
					visible: attr.values[0].value === 'TRUE' ? true : false,
				};
			});
		return !!whatsInTheBox ? whatsInTheBox : [];
	}
	return [];
};

const getKeyFeatures = (catalogEntryView: IContents[]): IKeyFeaturesItem[] => {
	const getImagePath = (attr: IAttribute<IValueWithoutArray>) => {
		return attr.values[0].image1path || attributeIcons.get(attr.identifier)?.(attr).path;
	};

	if (catalogEntryView[0].items) {
		const attributes = catalogEntryView[0].attributes ?? [];
		const prefix = 'KEY_';
		const keyFeatures = attributes
			.filter((attr) => attr.identifier.startsWith(prefix))
			.map((attr) => {
				return {
					title: attr.name,
					value: attr.values[0].value,
					img: getImagePath(attr),
					id: attr.identifier,
				};
			});
		return !!keyFeatures ? keyFeatures : [];
	}
	return [];
};

const getTechnicalSpecification = (catalogEntryView: IContents[]): ITechnicalSpecification[] => {
	const attributes = catalogEntryView[0].attributes ?? [];
	const technicalSpecification = attributes
		.filter((attr) => attr.displayable === 'true' && attr.usage === 'Descriptive' && attr.name !== 'VENDOR')
		.map((attr) => {
			return {
				name: attr.name,
				description: attr.values[0]?.value,
			};
		});
	return !!technicalSpecification ? technicalSpecification : [];
};

const getManufacturer = (catalogEntryView: IContents[]): IManufacturer => {
	const attributes = catalogEntryView[0].attributes ?? [];
	const manufacturer = attributes.find((attr) => attr.identifier === MANUFACTURER)?.values[0].value;
	const partNumber = attributes.find((attr) => attr.identifier === MANUFACTURER_PART_NUMBER);
	return {
		manufacturer,
		partNumber: {
			name: partNumber?.name,
			code: partNumber?.values[0]?.value,
		},
	};
};

const getDeviceDimensions = (catalogEntryView: IContents[]): IDeviceDimension | undefined => {
	const attributes = catalogEntryView[0].attributes ?? [];
	const dimensions = attributes.find((attr) => attr.identifier === DIMENSION_DETAILS)?.values[0].value;
	if (dimensions) {
		const d = dimensions.toString().replace(/ /g, '').split('||');
		if (d?.length === 3) {
			return {
				height: d[0],
				width: d[1],
				depth: d[2],
			};
		}
	}
};

const getRelatedProducts = (catalogEntryView: IContents[]): IRelatedItem[] | undefined => {
	const { merchandisingAssociations, attributes } = catalogEntryView[0];
	const relatedProducts = merchandisingAssociations?.map((association) => {
		let price = 0;
		association.price.map((p) => {
			if (p.usage === 'Offer') {
				price = +p.value;
			}
		});
		return {
			price,
			colors: getAvailableColors(attributes ?? []),
			availableCountries: [],
			availableValues: [],
			availableCapacity: getAvailableCapacity(attributes ?? []),
			thumbnail: association.thumbnail,
			name: association.name,
			uniqueID: association.uniqueID,
			shortDescription: '',
			longDescription: '',
			manufacturer: '',
			preorder:
				attributes?.find((attributte) => {
					return attributte.identifier === PREORDER;
				})?.values[0].value === 'TRUE',
			preorderDate: attributes?.find((attributte) => {
				return attributte.identifier === PREORDER_DATE;
			})?.values[0].value,
			isRelated: association.associationType === 'REPLACEMENT',
			isUpsell: association.associationType === 'UPSELL',
		};
	});
	return !!relatedProducts ? relatedProducts : [];
};

const model: types.models.IInputModel = {
	_contents: 'response.contents',
	item: (model: IModel) => {
		return getItem(model._contents);
	},
	items: (model: IModel) => {
		if (model._contents[0].items) {
			return getItems(model._contents);
		}
	},
	whatsInTheBox: (model: IModel) => {
		return getWhatsInTheBox(model._contents);
	},
	keyFeatures: (model: IModel) => {
		return getKeyFeatures(model._contents);
	},
	technicalSpecification: (model: IModel) => {
		return getTechnicalSpecification(model._contents);
	},
	manufacturer: (model: IModel) => {
		return getManufacturer(model._contents);
	},
	deviceDimensions: (model: IModel) => {
		return getDeviceDimensions(model._contents);
	},
	relatedProducts: (model: IModel) => {
		return getRelatedProducts(model._contents);
	},
};

export default model;
