import styled from 'styled-components';
import { ValueBold } from '@Components/Typography/Typography';

export const RadioWrapper = styled.div`
	display: flex;
	flex: 1;
	padding: 16px 0;
`;

export const AddCardFormWrapper = styled.div`
	padding: 8px 16px 24px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	padding: 24px 0 8px;
`;

export const HeaderDescriptionWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 0 32px;
`;
export const ContentWrapper = styled.div<{ isSmallScreen: boolean }>`
	${({ isSmallScreen }) => ({
		padding: isSmallScreen ? '0' : '0 48px',
	})}
`;

export const HeaderTitle = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
