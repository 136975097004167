import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import {
	RadioWrapper,
	AddCardFormWrapper,
	HeaderDescriptionWrapper,
	HeaderTitle,
	HeaderWrapper,
	ContentWrapper,
} from './SavedPaymentTokens.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import { Col, Row, useScreenClass } from 'react-grid-system';
import AddCardForm from '@Components/Card/cards/PaymentData/subcomponents/AddCardForm/AddCardForm';
import PaymentTokenItem from '@Components/PaymentTokenItem/PaymentTokenItem';
import { ICheckout as ICheckoutAction } from '@Redux/modules/checkout/actions';
import { ProfileService } from '@Services/profile/profileService';
import { useSelector } from 'react-redux';

interface ISavedPaymentTokens {
	paymentTokens: IPaymentToken[];
	setPaymentData: (data: ICheckoutAction['payload']['paymentData'] | undefined) => void;
	selectedPaymentData: ICheckoutAction['payload']['paymentData'];
}

const SavedPaymentTokens: FunctionComponent<ISavedPaymentTokens> = ({
	paymentTokens,
	setPaymentData,
	selectedPaymentData,
}) => {
	const { translate } = useTranslate();
	const [activeRadio, setActiveRadio] = useState(1);
	const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(false);
	const telesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const screenClass = useScreenClass();

	const prepaidProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.prepaidProcessCheckout
	);

	useEffect(() => {
		ProfileService.getUserProfile(false, telesales).subscribe(
			() => {
				setUserIsLoggedIn(true);
			},
			() => {
				setUserIsLoggedIn(false);
			}
		);
	}, []);

	const handleOnChange = (token: IPaymentToken) => {
		setPaymentData({ token });
		setActiveRadio(1);
	};

	const handleRadioButtonClick = (val: number) => {
		if (val === 1) {
			setPaymentData({ token: paymentTokens[0] });
		} else {
			setPaymentData({ card: { cardNumber: '', name: '', expire: '', cvv: '', savePaymentToken: false } });
		}
		setActiveRadio(val);
	};

	return (
		<Col lg={12}>
			<RadioWrapper>
				<RadioButton
					label={translate('secure-checkout.payment-tokens.select-from-saved-cards')}
					selected={activeRadio}
					name="payment-method"
					onClick={() => handleRadioButtonClick(1)}
					value={1}
				/>
			</RadioWrapper>
			<ConditionalRender
				show={activeRadio === 1}
				onTrue={() => (
					<>
						{paymentTokens.map((token) => (
							<PaymentTokenItem
								key={token.id}
								token={token}
								active={selectedPaymentData?.token && selectedPaymentData.token.id === token.id}
								showDelete={paymentTokens.length > 1}
								showSelect={paymentTokens.length > 1}
								handleOnChange={() => handleOnChange(token)}
								prepaidProcessCheckout={!!prepaidProcessCheckout}
							/>
						))}
					</>
				)}
			/>

			<RadioWrapper>
				<RadioButton
					label={translate('secure-checkout.payment-tokens.use-different-card')}
					selected={activeRadio}
					name="payment-method"
					onClick={() => handleRadioButtonClick(2)}
					value={2}
				/>
			</RadioWrapper>
			<ConditionalRender
				show={activeRadio === 2}
				onTrue={() => (
					<AddCardFormWrapper>
						<HeaderWrapper>
							<Icon name="cardAdd" fill="black38" width={40} height={40} />
							<HeaderDescriptionWrapper>
								<HeaderTitle>{translate('secure-checkout.add-card')}</HeaderTitle>
							</HeaderDescriptionWrapper>
						</HeaderWrapper>
						<ContentWrapper isSmallScreen={screenClass.includes('xs') || screenClass.includes('sm')}>
							<Row>
								<AddCardForm
									setPaymentData={setPaymentData}
									selectedPaymentData={selectedPaymentData}
									displaySaveCard={userIsLoggedIn}
								/>
							</Row>
						</ContentWrapper>
					</AddCardFormWrapper>
				)}
			/>
		</Col>
	);
};

export default SavedPaymentTokens;
